import React, { useState } from 'react';
import '../SelectRole/SelectRole.less';
import Button from 'antd/es/button';
import circle from '../../asset/images/Circle.svg';
import client from '../../asset/images/Client.svg';
import selected_client from '../../asset/images/Selected_Client.svg';
import contractor from '../../asset/images/Contractor.svg';
import selected_contractor from '../../asset/images/selected_contractor.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {setCount} from '../../redux/slices/login'
const SelectRole = () => {
  const [selectedClient, setSelectedClient] = useState(false);
  const [selectedContractor, setselectedContractor] = useState(false);
  const isLogin = useSelector(state => state.login.isLogin);
  const dispatch=useDispatch();
  const selectClient = () => {
    localStorage.setItem("role", 0)
    setSelectedClient(!selectedClient);
    setselectedContractor(false);
    dispatch(setCount(1));
  };

  const selectContractor = () => {
    localStorage.setItem("role", 1)
    setselectedContractor(!selectedContractor);
    setSelectedClient(false);
    dispatch(setCount(2));
  };

  const navigate = useNavigate();
  const navigateToSignUp = () => {
    const val = localStorage.getItem("role");
    if (isLogin) {
      if (val == 0)
        navigate(`/clientsignin`);
      else
        navigate(`/contractorsignin`);
    }
    else {
      if (val == 0)
        navigate(`/clientsignup`);
      else
        navigate(`/contractorsignup`);
    }
  };

  return (
    <div className="select-container">
      <div className="center_container">
        <div className="role_container">
          <div className="role_header">
            <h1>Select Your Role</h1>
          </div>
          <div className="role_content">
            {selectedClient ? (
              <div className="selected_client" onClick={selectClient}>
                <img src={circle} />
                <img className="selected_img_client" src={selected_client} />
                Client
              </div>
            ) : (
              <div className="client" onClick={selectClient}>
                <img src={circle} />
                <img className="img_client" src={client} />
                Client
              </div>
            )}
            {selectedContractor ? (
              <div className="selected_client" onClick={selectContractor}>
                <img src={circle} />
                <img className="selected_img_client" src={selected_contractor} />
                Contractor
              </div>
            ) : (
              <div className="client" name="Contractor" onClick={selectContractor}>
                <img src={circle} />
                <img className="img_client" src={contractor} />
                Contractor
              </div>
            )}
          </div>
          <Button
            className="next_btn_disabled"
            style={
              selectedContractor || selectedClient
                ? {
                  borderRadius: '15px',
                  background: '#0FF4BD',
                  boxShadow: '0px 12px 30px 0px rgba(18, 199, 155, 0.20)',
                }
                : { pointerEvents: 'none' }
            }
            onClick={() => {
              navigateToSignUp();
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SelectRole;
