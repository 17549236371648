import { createSlice } from '@reduxjs/toolkit';

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    isLogin: true,
    count: 1,
    profileInfo: { },
    navCount: 1,
    contractorId:{}
    
  },
  reducers: {
    setLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    setProfile: (state, action) => {
      state.profileInfo=action.payload;  
    },
    clearProfile: (state) => {
      state.profileInfo = {};
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setNavCount: (state, action) => {
      state.navCount = action.payload;
    },
    // setCreateObject: (state, action) => {
    //   state.createObj = action.payload;
    // },
    setContractorId:(state,action)=>{
      state.contractorId = action.payload;
    }
  },
});


export const { setLogin, setProfile, clearProfile, setCount, setNavCount } = loginSlice.actions;
export default loginSlice.reducer;
