import React,{ useEffect, useState }  from "react";
import moment from "moment";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";

function DatePicker({  weekly, onSelectDatePicker, appointMentList }) {
  const [allDates, setAllDates] = useState(null);
  const [showingDates, setShowingDates] = useState([]);
  const [monthName, setMonthName] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [lastDate, setLastDate] = useState(null);
  const [pDate, setPDate] = useState(moment(new Date()))
  const threeMonths = 90;
  const forteenDays = 7;

  useEffect(() => {
    onSelectDatePicker(pDate, appointMentList)
  }, [pDate])

  useEffect(() => {
    let dates = [];
    const currentYear = moment().year();
    for (let i = 0; i < threeMonths; i++) {
      dates.push(moment().add(i, "days"));
    }
    setLastDate(moment().add(89, "days"));
    setAllDates(dates);
    setMonthName(
      moment(dates[0]).set({ year: currentYear }).format("MMMM YYYY")
    );
    setShowingDates(dates.slice(0, forteenDays));
  }, []);

  const handlePreviousDates = () => {
    const datesToBeShown = allDates.indexOf(showingDates[0]) < forteenDays
      ? allDates.slice(0, forteenDays)
      : allDates.slice(
        allDates.indexOf(showingDates[0]) - forteenDays,
        allDates.indexOf(showingDates[0])
      );
    let currentMonth = null;
    if (datesToBeShown.includes(selectedDate)) {
      currentMonth = moment(selectedDate).format("MMMM YYYY");
    } else {
      currentMonth = moment(datesToBeShown[0]).format("MMMM YYYY");
    }
    setMonthName(currentMonth);
    setShowingDates(datesToBeShown);
  };
  const handleNextDates = () => {

    // debugger
    for (let i = 0; i < showingDates.length; i++) {
      if (moment(showingDates[i]).format("DD") === "01") {
        setMonthName(moment(showingDates[i]).format("MMMM YYYY"));
      }
    }
    setShowingDates(
      allDates.slice(
        allDates.indexOf(showingDates[showingDates.length - 1]) + 1,
        allDates.indexOf(showingDates[showingDates.length - 1]) +
        8
      )
    );
  };
  const handleSelectDate = (date) => {
    setMonthName(moment(date).format("MMMM YYYY"));
    setSelectedDate(date);
    setPDate(date);
  };

  const style = weekly
    ? {
      backgroundColor: "white",
      width: "109%",
      transform: "translateX(-50%)",
      marginLeft: "50%",
      height: "58px",
      marginTop: "10px",
    }
    : {};

  return (
    <>
      <div
        className="App date-picker"
        style={{
          padding: "5px",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            marginBottom: "-1rem"
          }}
        >
          <h4 className="w-20" style={{ "marginTop": "2px" }}>{monthName}</h4>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            ...style,
          }}
        >
          <Button
            onClick={handlePreviousDates}
            icon={<LeftOutlined />}
            size={"small"}
            style={{
              visibility:
                showingDates &&
                  showingDates[0] &&
                  moment(showingDates[0]).format("MM DD YYYY") ===
                  moment().format("MM DD YYYY")
                  ? "hidden" : "visible"
              ,
              //  backgroundColor: "#E7E8EA",
              color: "#64748B",
              border: "none",
            }}
          />
          <div style={{ display: "flex" }}>
            {showingDates?.map((item,index) => (
              <div key={item}>
                {moment(selectedDate).format("MM/DD/YYYY") === moment(item).format("MM/DD/YYYY") ? (
                  <div
                    style={{
                      fontSize: "14px",
                      lineHeight: "4px",
                      cursor: "pointer",
                      backgroundColor: "rgba(15, 244, 189, 1)",
                      color: "black",
                      height: "50px",
                      width: "56px",
                      borderRadius: "50%",
                      padding: "14px",
                      margin: weekly ? "3px" : "5px",
                    }}
                  >
                    <p>{moment(item).format("ddd")}</p>
                    <p>{moment(item).format("DD")}</p>
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: '15px',
                      lineHeight: '4px',
                      backgroundColor: weekly ? 'none' : '#E7E8EA',
                      color: '#64748B',
                      border: weekly ? 'none' : '1px solid #E7E8EA',
                      height: '50px',
                      width: '50px',
                      borderRadius: '50%',
                      padding: '0px',
                      paddingTop: '15px',
                      margin: weekly ? '0px' : '5px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleSelectDate(item);
                    }}
                    key={index}
                  >
                    <p>{moment(item).format('ddd')}</p>
                    <p>{moment(item).format('DD')}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
          <Button
            onClick={handleNextDates}
            icon={<RightOutlined />}
            size={"small"}
            style={{
              visibility: moment(showingDates[showingDates.length - 1]).format("MMMM DDDD YYYY") == moment(lastDate).format("MMMM DDDD YYYY")
                ? "hidden"
                : "visible",
              color: "#64748B",
              border: "none",
            }}
          />
        </div>
      </div>
      <h6 className="text-center"> Appointment on {moment(pDate).format("MMMM DD, YYYY")}</h6>
    </>
  );
}

export default DatePicker;
