import React, { useState, useEffect } from "react";
import AddLocation from "../createjob/AddLocation";
import deletesettings from '../../../asset/images/deletesettings.svg'
import building from '../../../asset/images/building.svg'
import editsquare from '../../../asset/images/editsquare.svg'
import mapsettings from '../../../asset/images/mapsettings.svg'
import { locationListApi, locationSaveApi, deleteLocationApi, updateLocationApi } from '../../../api/createjob';
import { useDispatch } from "react-redux";
import { Button ,Spin,message} from "antd";
import { showLoader } from '../../../redux/slices/common'
import ModalSingle from '../../../components/Modal';
import deletepopup from '../../../asset/images/deletepopup.svg'

const Mylocations = ({ loading,setLoading,tabChange }) => {
  const [address, setAddress] = useState({});
  const [locationList, setLocationList] = useState([])
  const [openLocation, setOpenLocation] = useState(false)
  const [isEdit, setIsEdit] = useState(false);
  const [item, setItem] = useState({});
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false)

  const callListApi = async () => {
    setLoading(true)
    const resp = await locationListApi();
    if (resp?.status == 200)
      setLocationList(resp?.data?.data);
    setLoading(false)
  }
  const saveLocationList = async (address) => {
    setLoading(true)
    dispatch(showLoader(true));
    const val=address;
    delete val.__v;
    delete val._id;
    delete val.createdBy;
    delete val.createdAt;
    const resp = await locationSaveApi(val);
    if (resp?.status == 200) {
      setAddress(resp?.data?.data);
      message.success("A new location has been added with this account. ")
    }
    dispatch(showLoader(false));
    setOpenLocation(false)
    setLoading(false)
  }

  const handleYes = () => {
    deleteLocation(item._id);
    setOpenModal(false)
  }
  const deleteLocation = async (id) => {
    setLoading(true)
    const resp = await deleteLocationApi(id);
    if (resp?.status == 200) {
      message.success("Location has been deleted successfully. Remember jobs posted with this location will be still visible to contractors.")
    }
    setOpenLocation(false)
    setLoading(false)
  }

  const breadCrumFunc = () => {
    setOpenLocation(false)
  }

  const updateLocation = async (id) => {
    dispatch(showLoader(true));
    const val = address;
    delete val.__v;
    delete val._id;
    try{
    const resp = await updateLocationApi(id, val);
    if (resp?.status == 200) {
      message.success("Location has been updated.")
    }
   else if(resp?.response?.data?.message )
    {
      message?.error(resp?.response?.data?.message)
    }
  }
  catch(err)
  {
    console.log("in error")
  }
    dispatch(showLoader(false));
    setOpenLocation(false)
  }
  useEffect(() => {
   
    if (openLocation) 
      setAddress(item)
    else 
    callListApi();
  }, [openLocation])

  useEffect(() => {
    if (tabChange) {
      setOpenLocation(false);
    }
  }, [tabChange])

  return (
    <div className="settingcard ">
      <Spin spinning={loading}>
      {openLocation && <AddLocation saveLocationList={saveLocationList} address={address} setAddress={setAddress} isEdit={isEdit} item={item} setOpenModal={setOpenModal} updateLocation={updateLocation} breadCrumFunc={breadCrumFunc} />}
      {openModal && <ModalSingle openModal={openModal} setOpenModal={setOpenModal} content="Are you sure you want to delete this location?" image={deletepopup} handleYes={handleYes} fromSetting={true}/>}
      {!openLocation && <><div className="locationlist d-flex">
        {locationList.length > 0 && locationList.map((item) => {
          return (
            <div className="singleitem m-2">
              <div className="singlerow d-flex justify-content-between my-2">
                <div className="officename d-flex">
                  <img src={building} className="mx-2" />
                  <div className="name">{item.name}</div>
                </div>
                <div className="imgicons">
                  <img src={deletesettings} className="mr-2" onClick={() => { setOpenLocation(true); setIsEdit(true); setItem(item); callListApi() }} />
                  <img src={editsquare} onClick={() => { setOpenLocation(true); setIsEdit(true); setItem(item) }} />
                </div>
              </div>
              <div className="singlerow">
                <div className="officeadd">{item.address}</div>
                <div className="imgicons"><img src={mapsettings} /></div>
              </div>
            </div>)
        })
        }
      </div>
        <Button className="addlocationbtn" onClick={() => { setOpenLocation(true); setIsEdit(false) }}>Add Location</Button></>}
        </Spin>
    </div>

  )
}

export default Mylocations;