import instance from '../config/axios';
export const joblist = async (val, page) => {
  try {
    const response = await instance.get(`job/list-for-client?status=${val}&page=${page}`);
    return response;
  } catch (error) {
    return error
  }
};
export const onGoingJoblistApi = async (val) => {
  try {
    const response = await instance.get(`job/list-ongoing-jobs-for-client`);
    return response;
  } catch (error) {
    return error
  }
};
export const addRating = async (rating, userId, jobId) => {
  try {
    const response = await instance.post(`rating/add`, {
      "rating": rating,
      "userId": userId,
      "jobId": jobId

    });
    return response;
  } catch (error) {
    return error
  }
};

export const callClientKeyApi = async (jobId) => {
  try {
    const response = await instance.post(`job/create-payment-intent`, {
      paymentMethod: "card",
      "jobId": jobId,
      "description": ""
    });
    return response;
  } catch (error) {
    return error
  }
}

export const addOrRejectExpenseApi = async (jobId, expenseId, val) => {
  try {
    const response = await instance.put(`job/update-expenses-status/${jobId}/${expenseId}`, { status: val });
    return response
  }
  catch (err) {
    return err;
  }
}

export const confrimPaymentApi = async (jobId, paymentIntent) => {
  try {
    const response = await instance.post(`job/confirm-payment`, { jobId: jobId, paymentIntent: paymentIntent });
    return response
  }
  catch (err) {
    return err;
  }
}