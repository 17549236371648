import React from 'react';
import unChecked from '../../../asset/images/unCheckBox.png';
import Checked_box from '../../../asset/images/Checkbox.png';

const CategoryItem = ({ item, selectedItems, multipleSelect, removeSelected, setChecked }) => {
  return (
    <div className="options d-flex justify-content-between w-100 align-items-center" key={item._id} style={{ cursor: "pointer" }}>
      <div className="img_profile d-flex "   >
        <img src={item.subCategoryImage.url} style={{ width: "30px", height: "30px", borderRadius: "50%" }} />
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: "10px" }}>{item.name}</div>  </div>
      <div>
        {selectedItems.includes(item._id) ? <img
          src={Checked_box}
          onClick={() => {
            removeSelected(item);
            setChecked(false)
          }}
        /> : <img
          src={unChecked}
          onClick={() => {
            multipleSelect(item);
            setChecked(true)
          }}
        />}
      </div>
    </div>
  )
}
export default CategoryItem