import instance from '../config/axios';
export const createJobApi = async (values) => {
  try {
    const response = await instance.post(`job/create`, values);
    return response;
  } catch (error) {
    return error
  }
};
export const locationListApi = async () => {
  try {
    const response = await instance.get(`user/location-list`);
    return response;
  } catch (error) {
    return error
  }
}
export const locationSaveApi = async (values) => {
  try {
    const response = await instance.post(`user/add-location`, values);
    return response;
  } catch (error) {
    return error
  }
}
export const notIntrestedApi = async (values) => {
  try {
    const response = await instance.put(`job/not-interested-in`, values);
    return response;
  } catch (error) {
    return error
  }
}
export const applyJobApi = async (values) => {
  try {
    const response = await instance.post(`job/apply`, values);
    return response;
  } catch (error) {
    return error
  }
}
export const getJobCost = async (values) => {
  try {
    const response = await instance.post(`job/total-cost`, values);
    return response;
  } catch (error) {
    return error
  }
}

export const getSingleJob = async (id) => {
  try {
    const response = await instance.get(`job/details/${id}`);
    return response;
  } catch (error) {
    return error
  }
}

export const deleteLocationApi = async (locationid) => {
  try {
    const response = await instance.delete(`user/location/${locationid}`);
    return response;
  } catch (error) {
    //throw error;
  }
}
export const updateLocationApi = async (locationid, values) => {
  try {
    const response = await instance.put(`user/update-location/${locationid}`, values);
    return response;
  } catch (error) {
    return error
    //throw error;
  }
}
export const callPendingContractorListApi = async (id) => {
  try {
    const response = await instance.get(`job/applicants/${id}`);
    return response;
  } catch (error) {
    //throw error;
  }
}

export const acceptJobApi = async (id, jobId) => {
  try {
    const response = await instance.put(`job/assign-contractor/${jobId}`, { contractorId: id });
    return response;
  } catch (error) {
    //throw error;
  }
}

export const declineJobApi = async (id, userId) => {
  try {
    const response = await instance.put(`job/cancel-request-by-client`, { jobId: id, userId: userId });
    return response;
  } catch (error) {
    //throw error;
  }
}

export const deleteJobPostApi = async (id, feedback) => {
  try {
    const response = await instance.delete(`job/${id}/?reason=${feedback}`);
    return response;
  } catch (error) {
    //throw error;
  }
}
export const updateJobApi = async (id, values) => {
  try {
    const response = await instance.put(`job/update/${id}`, values);
    return response;
  } catch (error) {
    return error
  }
};
export const cancelJobAppointMentApi = async (id, userid, feedback) => {
  try {
    const response = await instance.put(`job/cancel-request-by-client/?reason=${feedback}`, { jobId: id, userId: userid });
    return response;
  } catch (error) {
    return error
  }

}