import instance from '../config/axios';
export const getChatUsersApi = async () => {
  try {
    const response = await instance.get(`chat/chat-users`);
    return response;
  } catch (error) {
    return error
  }
}
export const sendMessagesApi = async (value) => {
  try {
    const response = await instance.post(`chat/send-message`, value);
    return response;
  } catch (error) {
    return error
  }
}
export const getMessagesApi = async (id,userId) => {
  try {
    const response = await instance.get(`chat/messages?id=${id}&userId=${userId}`);
    return response;
  } catch (error) {
    return error
  }
}
export const receiveMessageApi = async (id,userId) => {
  try {
   const resp= await instance.put(`chat/read-messages/${id}/${userId}`);
    return resp;
  } catch (error) {
    return error
  }
}