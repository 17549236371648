import React, { useEffect }  from "react";
import { useSelector } from "react-redux";
import Spin from 'antd/es/spin';
import handleRouting from '../../common/helper/handleRouting';
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const SpinWrapper = ({ children }) => {
  const loader = useSelector(state => state.common.loader);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    handleRouting(token, navigate, message);
  }, [token])
  
  return (
    <Spin spinning={loader} style={{ maxHeight: "100%", height: "100%", background: "#fff", opacity: "1" }}>
      {children}
    </Spin>
  )
}

export default SpinWrapper;