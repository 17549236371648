import React from 'react';
import { Modal, Button, Spin } from 'antd';
import editicon from '../../../asset/images/EditIcon.png';
import crossicon from '../../../asset/images/crossicon.svg';
import uploadpic from '../../../asset/images/uploadpic.svg';
import TextArea from "antd/es/input/TextArea";
import deleteicon from '../../../asset/images/Delete.svg'


const CompleteModalPopup = ({ handleFileChange, expenseInfo, setCompleteModal, callAddExpenseApi, setExpenseModal, completeModal, setExpenseInfo, setExpenses, setCountNumber, loader, handleDeleteExpense }) => {
  return (
    <>
      <Modal
        centered
        className={expenseInfo?.expenses.length > 0 ? 'expensemodal' : 'expensemodal expensemodalinfo'}
        closable={false}
        header={null}
        footer={null}
        style={{ top: '', overflow: "hidden !important" }}
        open={completeModal}
      >
        <Spin spinning={loader}>
          <img className="crossimg" src={crossicon} style={{ cursor: "pointer" }} onClick={() => { setCompleteModal(false); setExpenseInfo({ expenses: [], completeJobAttachments: [], completeJobNotes: "" }) }} />
          <div className="expenseheading">Complete Job</div>
          <div className="expensepic">
            <input
              type="file"
              id="file"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={(val) => {
                handleFileChange(val, 1);
              }}
            />
            {expenseInfo?.completeJobAttachments[0]?.url ?
              <div className="w-100 jobimg">
                <img className="jobimage" src={expenseInfo?.completeJobAttachments[0]?.url} />
                <label htmlFor="file"><img src={editicon} style={{ position: 'absolute', bottom: "1rem", right: "1rem", backgroundColor: "#fff", padding: "8px", borderRadius: "50%" }} /></label>
              </div>

              : <div className="expensedetails">
                <div className="uploadimg"><img src={uploadpic} /></div>

                <label htmlFor="file" className="uploadicon" >
                  <div className="uploadpic">Upload Picture</div>
                </label>
              </div>
            }
          </div>
          <TextArea placeholder='Additional Notes' className={expenseInfo?.completeJobNotes ? "expenseinput expenseinputfilled" : " expenseinput"} value={expenseInfo?.completeJobNotes} onChange={(e) => { setExpenseInfo({ ...expenseInfo, completeJobNotes: e.target.value }) }} />
          {expenseInfo?.expenses?.length > 0 && <div className={expenseInfo?.expenses?.length < 5 ? "expenseslist" : "expenseslist1 expenseslist"}>
            {expenseInfo?.expenses.map((item) => {
              return (
                <div key={item?.image?.url} className="expensedata d-flex justify-content-between align-content-center" onClick={() => { }}>
                  <div className="expensel d-flex ">
                    <div className="expenseimg mr-3"><img src={item?.image?.url} /></div>
                    <div className="expensename">{item?.name}</div>
                  </div>
                  <div className="expenseprice">${Math.abs(item?.price).toFixed(2)}</div>
                  <div className="editicon d-flex justify-content-between">
                    <div className="editicon mr-2" onClick={() => { setExpenses(item); setCompleteModal(false); setExpenseModal(true); setExpenses(item); setCountNumber(index); }}><img src={editicon} /></div>
                    <div className="delteicon" onClick={() => { handleDeleteExpense(index + 1) }}><img src={deleteicon} /></div></div>
                </div>
              )
            })}
          </div>}
          {expenseInfo?.expenses?.length < 5 && <Button className="addexpensebtn  d-block mx-auto my-2" onClick={() => { setCompleteModal(false); setExpenseModal(true); }}>+ Add Expense</Button>}
          <Button disabled={!(expenseInfo?.completeJobNotes != '' && expenseInfo?.completeJobAttachments[0]?.url)}
            className={expenseInfo?.completeJobNotes != '' && expenseInfo?.completeJobAttachments[0]?.url ? "completebtnenable completebtn d-block m-auto" : "completebtn d-block m-auto"}
            onClick={() => { callAddExpenseApi() }}
          >
            Complete</Button>
        </Spin>
      </Modal>
    </>
  )
}
export default CompleteModalPopup;