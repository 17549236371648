import React from 'react';

import TimeInfo from './TimeInfo';
import dummyprofileicon from '../../../asset/images/dummyicon.svg'

function SideChat({ user }) {
    return (
    <>
      <div className="profileimg">
        {user?.userId?.profileImage?.url && <img src={user?.userId?.profileImage?.url ? user?.userId?.profileImage?.url : dummyprofileicon} />}
      </div>
      <div className="profileinfo">
        <div className="profilename">{user?.userId?.firstName} {user?.userId?.lastName}</div>
        <div className="profilemsg">{user?.category[0]?.name }</div>
        <div className="profilename">{user?.latestMessage?.message?.substr(0, 30)}{user?.latestMessage?.message?.length > 30 && <span>.....</span>}</div>
      </div>
      {user?.unReadCount > 0 && <div className="readinfo">{user?.unReadCount}</div>}
      <div className="profiletime">
        <div className="time"><TimeInfo timeval={user?.createdAt} /></div>
      </div>
    </>
  )
}

export default SideChat;