import React, { useState, useEffect } from 'react'
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Col, Row, Button,Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import './cardform.less';
import { createProfileClient } from '../../../api/detailsapi'
import { saveCardApi } from '../../../api/cardinfoapi';


const CardForm = ({ setPercentInc, inJobs, setAddCardModal, inSettings, setLoading, getAllCards }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [disabled, setDisabled] = useState(true)
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  const[loader,setLoader]=useState(false)
  const [formData, setFormData] = useState({
    name: '',
    cardNumber: '',
    expiryDate: '',
    cvv: ''
  })
  const [checkfields, setCheckfields] = useState({
    cardnumber: false,
    expiry: false,
    cvv: false,
    name: false
  })

  useEffect(() => {
    if (elements) {
      elements?.getElement(CardNumberElement).clear();
      elements?.getElement(CardExpiryElement).clear();
      elements?.getElement(CardCvcElement).clear();
      form.resetFields()
    }
  }, [])

  useEffect(() => {
    if (checkfields.cardnumber == "true" && checkfields.expiry =="true"
      && checkfields.cvv == "true" && checkfields.name == "true") {
      setDisabled(false)
    } else setDisabled(true)
  }, [checkfields])

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0;

    if (checkfields.cardnumber && checkfields.expiry && checkfields.cvv && checkfields.name) {
      setDisabled(hasErrors);
    } else setDisabled(true)
  }

  const handleSkip = async () => {
    await createProfileClient({ isCardDetailsUpdated: true });
    navigate("/createjob");
  }
  const handleSubmit = async () => {
    try{
    setLoader(true)
    if (inSettings)
      setLoading(true)
    const cardElement = elements.getElement(CardNumberElement);
    const { token } = await stripe.createToken(cardElement, {
      name: formData?.name
    });
    const resp = await saveCardApi(token.id);
    if (resp?.status == 200 && !inJobs)
      navigate("/createjob");
    else if (resp?.status == 200)
      setAddCardModal(false)
    if (inSettings) {
      setLoading(false)
      getAllCards();
    }
   setLoader(false);
  }
  catch(err)
  {
    setLoader(false);
  }
  }

  const handleChange = async (event, cardinput) => {
    const e = await event;
    if (cardinput == "name") {
      setFormData({
        ...formData, [e.target.name]: e.target.value
      });
      if (e.target.value.length > 1) {
        setCheckfields({ ...checkfields, name: "true" })
      }
      else {
        setCheckfields({ ...checkfields, name: "false" })
      }
    }
    if (e?.complete === true) {
      if (cardinput == 'cardnumber')
        setCheckfields({ ...checkfields, cardnumber: "true" })
      if (cardinput == 'expiry')
        setCheckfields({ ...checkfields, expiry: "true" })
      if (cardinput == 'cvv')
        setCheckfields({ ...checkfields, cvv: "true" })
    } else if (e?.error) {
      if (cardinput == 'cardnumber')
        setCheckfields({ ...checkfields, cardnumber: "false" })
      if (cardinput == 'expiry')
        setCheckfields({ ...checkfields, expiry: "false" })
      if (cardinput == 'cvv')
        setCheckfields({ ...checkfields, cvv: "false" })
    } else if (e?.empty === false && e?.error === undefined) {
      if (cardinput == 'cardnumber')
        setCheckfields({ ...checkfields, cardnumber: "false" })
      if (cardinput == 'expiry')
        setCheckfields({ ...checkfields, expiry: "false" })
      if (cardinput == 'cvv')
        setCheckfields({ ...checkfields, cvv: "false" })
    }
  }

  return (
    <Spin spinning={loader}>
      <div className="card-element-wrapper">
        {!inSettings && <h3 className="mx-auto my-2">Enter Your Card Details</h3>}
        <Form
          onFieldsChange={handleFormChange}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <div>
            <Row className="card-element d-flex ">
              <Col xs={24} md={11} lg={11} className='mb-sm-3 mb-md-0 mr-3 ' >
                <Form.Item
                  name="name"
                  rules={[
                    {
                      type: 'name',
                      message: 'Please enter a valid card name',
                    },
                    {
                      required: true,
                      message: 'Please enter your card name',
                    },
                    {
                      pattern: new RegExp(/^[A-Za-z ]+$/),
                      message:
                        "Field does not accept numbers or special characters.",
                    },
                  ]}
                >
                  <Input
                    name="name"
                    className={!checkfields.name ? "input-text-field" : 
                    "input-text-field input-text-field1"}
                    placeholder="Cardholder's Name"
                    type="text"
                    // onChange={changeFormData}
                    onChange={(e) => handleChange(e, "name")}
                    value={formData?.name}
                    pattern='[A-Za-z]'
                    maxLength={32}
                    minLength={3}
                    style={{ borderRadius: "16px" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={11} lg={11} className='mb-sm-3 mb-md-0'>

                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your card number',
                    }
                  ]}
                >
                  <CardNumberElement
                    options={{
                      showIcon: true,
                      iconStyle: 'solid',
                      placeholder: 'Enter Credit Number',
                      style: {
                        empty: {
                          '::placeholder': {
                            color: '#6B6B6B',
                          },
                        },
                        base: {
                          iconColor: '#111111',
                          color: '#111111',
                          fontWeight: '500',
                          fontSize: '16px',
                          fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                          fontSmoothing: 'antialiased',
                          ':-webkit-autofill': {
                            color: '#111111',
                          },
                        },
                        invalid: {
                          iconColor: 'red',
                          color: 'red',
                          fontWeight: '500',
                          fontSize: '16px',
                          fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                        },
                      },
                    }}
                    className={!checkfields.cardnumber ? 'card-number' : 'card-number1'}
                    onChange={(e) => handleChange(e, "cardnumber")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div>
            <Row className="card-element d-flex ">
              <Col xs={24} md={11} lg={11} className='mb-sm-3 mb-md-0 mr-3'>

                <Form.Item>
                  <CardExpiryElement
                    className={!checkfields.expiry ? 'card-expiry' : "card-number1"}
                    options={{
                      placeholder: 'MM/YY',
                      style: {
                        empty: {
                          '::placeholder': {
                            color: '#6B6B6B',
                          },
                        },
                        base: {
                          iconColor: '#111111',
                          color: '#111111',
                          fontWeight: '500',
                          fontSize: '16px',
                          fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                          fontSmoothing: 'antialiased',
                          ':-webkit-autofill': {
                            color: '#111111',
                          },
                        },
                        invalid: {
                          iconColor: 'red',
                          color: 'red',
                          fontWeight: '500',
                          fontSize: '16px',
                          fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                        },
                      },
                    }}
                    onChange={(e) => handleChange(e, "expiry")}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={11} lg={11} className='card-cvv-wrapper'>

                <Form.Item>
                  <CardCvcElement
                    className={!checkfields.cvv ? 'card-cvv' : "card-number1"}
                    type="password"
                    options={{
                      placeholder: 'Enter CVV',
                      style: {
                        empty: {
                          '::placeholder': {
                            color: '#6B6B6B',
                          },
                        },
                        base: {
                          iconColor: '#111111',
                          color: '#111111',
                          fontWeight: '500',
                          fontSize: '16px',
                          fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                          fontSmoothing: 'antialiased',
                          ':-webkit-autofill': {
                            color: '#111111',
                          },
                        },
                        invalid: {
                          iconColor: 'red',
                          color: 'red',
                          fontWeight: '500',
                          fontSize: '16px',
                          fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                        },
                      },
                    }}
                    onChange={(e) => handleChange(e, "cvv")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
      {inJobs ? <div className="w-100">
        <Button className={disabled ? "pay-now-disabled m-auto d-flex" : "pay-now m-auto d-flex"} disabled={disabled} onClick={() => { handleSubmit(); }}>
          Save
        </Button>
      </div> :
        <>
          <div className="w-100">
            <Button className={disabled ? "pay-now-disabled m-auto d-flex" : 
            "pay-now m-auto d-flex"}
             disabled={disabled} onClick={() => { setPercentInc(100); handleSubmit(); }}>
              Get Started
            </Button>
          </div>
          <div className="subform" onClick={() => { navigate("/createjob", { state: { val: state } }) }}>
            <div className="subform1 skipcard" style={{ cursor: "pointer" }}
             onClick={() => { handleSkip() }}>Skip</div>
          </div>
        </>}
    </Spin>
  )
}

export default CardForm;