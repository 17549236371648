import React from 'react'
import crossicon from '../../../asset/images/crossicon.svg';
import Modal from 'antd/es/modal/Modal';
import VerifyContractor from '../../../pages/VerifyContractor';
const MobileVerificationModal = ({ setMobileScreen, mobileScreen, phoneNum, setData, data }) => {
  return (
    <Modal
      centered
      className='mobilemodal'
      closable={false}
      header={null}
      footer={null}
      style={{ top: '', overflow: "hidden !important" }}
      open={mobileScreen}
    >
      <div className="mobilemodalinfo">
        <img className="crossicon" src={crossicon} style={{ cursor: "pointer" }} onClick={() => { setMobileScreen(false) }} />
        <VerifyContractor fromSetting={true} setMobileScreen={setMobileScreen} phoneNum={phoneNum} setData={setData} data={data} />
      </div>
    </Modal>
  )
}

export default MobileVerificationModal