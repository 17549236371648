import React, { useEffect, useState } from "react";
import cardimg1 from '../../../asset/images/cardimg1.svg';
import cardimg2 from '../../../asset/images/cardimg2.svg';
import deletesettings from '../../../asset/images/deletesettings.svg';
import deleteicon from '../../../asset/images/deletepopup.svg'
import visacard from '../../../asset/images/visacard.svg';
import { getCardsApi, deleteCardApi,selectedCardApi } from '../../../api/cardinfoapi';
import { Spin, Modal, Button, message } from "antd";
import CardDetailsInfo from "../profilesetup/CardDetailsInfo";
import cardimgradio from '../../../asset/images/cardimgradio.svg'
import disabledradiobtn from '../../../asset/images/disabledradiobtn.svg';
import amex from '../../../asset/cardicons/amex.png';
import diners from '../../../asset/cardicons/diners.png';
import discovers from '../../../asset/cardicons/discover.png'
import jcb from '../../../asset/cardicons/jcb.png'
import unioncard from '../../../asset/cardicons/unioncard.png'
import mastercard from '../../../asset/cardicons/mastercard.png'
const PaymentInfo = ({loading,setLoading}) => {
  const [cards, setCards] = useState([]);
  const [deletePopUp, setdeletePopUp] = useState(false);
  const [seletedCardId, setSelectedCardId] = useState();
  const [addcardModal, setAddCardModal] = useState(false);
    const cardImage = {
      Discover: discovers,
      'American Express': amex,
      MasterCard: mastercard,
      UnionCard: unioncard,
      Visa: visacard,
      Diners: diners,
      Jcb: jcb,
    };

  const getAllCards = async () => {
    setLoading(true)
    const res = await getCardsApi();
    if (res.status === 200) {
      setCards(res?.data?.data);
    }
    setLoading(false)
  }
  const handleYes = async () => {
    setLoading(true)
    setdeletePopUp(false)
    const resp = await deleteCardApi(seletedCardId);
    if (resp?.status == 200) {
      getAllCards();
      message.success("Card deleted successfully");
    }
    setLoading(false)
  }
  const  changeSelectCard=async(id)=>{
   const resp=await selectedCardApi({cardId:id});
   if(resp?.status==200)
    getAllCards();
  }
  const handleNo = () => {
    setdeletePopUp(false)
  }
  useEffect(() => {
    getAllCards()
  }, [])

  return (
    <div className="settingcard">
      <Spin spinning={loading}>
   {  !addcardModal? <>
      <div className="cardlistinfo">
        {cards?.map((item) => {
          return (
            <div className="singlecardimage">
              <img className="img1" src={cardimg1} />
              <img className="img2" src={cardimg2} />
              <div className="singlecardinfo1">
                <div
                  className="delete"
                  onClick={() => {
                    setdeletePopUp(true);
                    setSelectedCardId(item?.id);
                  }}
                >
                  <img src={deletesettings} />
                </div>
                <div className="radioicon">
                  {item?.isSelected ? (
                    <img src={cardimgradio} />
                  ) : (
                    <img
                      style={{ cursor: 'pointer' }}
                      src={disabledradiobtn}
                      onClick={() => {
                        changeSelectCard(item?.id);
                      }}
                    />
                  )}
                </div>
                <div className="cardNumber">
                  <div>****</div> <div>****</div> <div>****</div> <div>{item?.last4}</div>
                </div>
              </div>

              <div className="singlecardinfo2 d-flex justify-content-between p-2">
                <div className="cardnamedate d-flex flex-column">
                  <div className="name">{item?.name}</div>
                  <div className="date">
                    Exp:{item?.exp_month}/{item?.exp_year}
                  </div>
                </div>
                <div className="img">
                  <img src={cardImage[item?.brand]} />
                </div>
              </div>
            </div>
          );
        })}

      </div>
      <Button className="addnewcardbtn" onClick={()=>{setAddCardModal(true)}}>Add New Card</Button>
      </>:<CardDetailsInfo inJobs={true} inSettings={true} setAddCardModal={setAddCardModal} setLoading={setLoading} getAllCards={getAllCards}/>}
      {deletePopUp && <Modal
        centered
        width={390}
        header={null}
        footer={null}
        open={deletePopUp}
        className="logoutmodal"
        closable={false}
      >
        <div className="crossImage">
          <img
            src={deleteicon}
            className="text-center"
          />
        </div>
        <div
          className="logoutdesc">
          Are you sure you want to delete this card ?
        </div>
        <div
          className="confirmButtons"
          style={{ justifyContent: "space-between", display: "flex", height: "50px", marginTop: "10px" }}
        ><Button
          className="yesbtn"
          onClick={handleYes}
        >
            Yes
          </Button>
          <Button
            className="nobtn"
            onClick={handleNo}
          >
            No
          </Button>
        </div>
      </Modal>}
      </Spin>
    </div>
  )
}

export default PaymentInfo;