import React from 'react';
import totalearningsvg from '../../../asset/images/totalearningsvg.svg';
import depositedsvg from '../../../asset/images/depositedsvg.svg';

function EarningDetails({items,earnings}) {
  return (
    <div className="earnings">
      <div className="earning">
       <div className="earningprice">{Math.abs(earnings?.item1).toFixed(2) || Math.abs(earnings?.pendingCounts).toFixed(2)}</div>
       <div className="earningdata">
        <div className="earningsheading">{items[0]}</div>
        <div className="earningsimg"><img src={totalearningsvg}/></div>
       </div>
      </div>
      <div className="earning">
       <div className="earningprice">{Math.abs(earnings?.item2).toFixed(2)}</div>
       <div className="earningdata">
        <div className="earningsheading">{items[1]}</div>
        <div className="earningsimg"><img src={totalearningsvg}/></div>
       </div>
      </div>
      <div className="earning">
       <div className="earningprice">{Math.abs(earnings?.item3).toFixed(2)}</div>
       <div className="earningdata">
        <div className="earningsheading">{items[2]}</div>
        <div className="earningsimg"><img src={depositedsvg}/></div>
       </div>
      </div>
    </div>
  )
}

export default EarningDetails