import React, { useState, useRef, useEffect } from 'react';
import Button from 'antd/es/button';
import Form from 'antd/es/form/Form';
import { Input } from 'antd';

const data1 = [
  "Do you have 2 Million liability Insurance or do you intend to supply it prior to approval by MyInstallr?",
  `Do you have any Recognized Certifications`,
  "How many year’s experience do you have as an installr?",
  "Do you have a criminal record or any restrictions from working?"
]
const Questions = ({ save, setInfo, data, setData, setQuestionsModal }) => {
  const [questions, setQuestions] = useState([{ "Do you have 2 Million liability Insurance or do you intend to supply it prior to approval by MyInstallr?": "" }, { "Do you have any Recognized Certifications": "" }, { "How many year’s experience do you have as an installr?": "" }, { "Do you have a criminal record or any restrictions from working?": "" }]);
  const [form] = Form.useForm();
  const [year, setYear] = useState();
  const [months, setMonths] = useState();
  const [year1, setYear1] = useState("Years");
  const [months1, setMonths1] = useState("Months");
  const [disabled, setDisabled] = useState(true);
  const [cer, setCer] = useState();
  const formRef = useRef();
  const onFinish = () => {
    const concatVal = `${year} ${year1} ${months} ${months1}`;
    const newArr = [...questions];
    newArr[2]["questionId"] = "3";
    newArr[2]["question"] = data1[2];
    newArr[2]["answer"] = concatVal;
    if (data) {
      setQuestionsModal(false);
      setData({ ...data, questions: [...newArr] });
    }
    else {
      save({ questions: newArr });
      setInfo(true);
    }
  };

  function checkInput(inputElement) {
    const maxAllowedValue = 11;
    if (Number(inputElement.target.value) > 0) {
      if (Number(inputElement.target.value) <= maxAllowedValue) {
        setMonths(inputElement.target.value);
        if (inputElement.target.value == 1)
          setMonths1("Month")
        else
          setMonths1("Months")
      }
    }
    else
      setMonths("");
  }

  function checkInput1(inputElement) {
    const maxAllowedValue = 100;
    if (Number(inputElement.target.value) > 0 && Number(inputElement.target.value) <= maxAllowedValue) {
      setYear(inputElement.target.value)
      if (inputElement.target.value == 1)
        setYear1("Year")
      else
        setYear1("Years")
    }
    else
      setYear("");
  }

  const handleChange = (val, index) => {
    const newArr = [...questions];
    const value = index + 1;
    newArr[index] = {
      question: data1[index],
      answer: val,
      questionId: `${value}`,
      //  [key]:val
    }
    if (data && !val) {
      newArr[index] = { ...newArr[index], answer: "yes" }
    }
    setQuestions(newArr);
  }

  useEffect(() => {
    if (questions[0]["answer"] && questions[1]["answer"] && months && year && questions[3]["answer"]) {
      if (questions[1]["answer"] == "Yes") {
        setDisabled(true)
      }
      else {
        setDisabled(false)
      }
    }
    else {
      setDisabled(true)
    }
  }, [questions, months, year])

  useEffect(() => {
    if (data) {
      setQuestions(data?.questions);
      const arr = data?.questions[2]["answer"].split(" ");
      setYear(arr[0]);
      setMonths(arr[2]);
      setCer(data?.questions[1]["answer"])
    }
  }, [])
  return (
    <>
      <div className="center-container d-flex align-items-center flex-column  profiledetails"  >
        <div className="heading">Question(s)
        </div>
        <Form
          name="dec-form"
          initialValues={{ remember: true }}
          autoComplete="off"
          layout="vertical"
          onFinish={onFinish}
          form={form}
          ref={formRef}
          style={{ width: "60%" }}
        >
          <div className="questionsinfo">
            <div className="question">
              <div className="question1 my-3">1. Do you have 2 Million liability Insurance or do you intend to supply it prior to approval by MyInstallr?</div>
              <div className="questionbuttons d-flex"><div className={questions[0]["answer"] == 'Yes' ? "questionbtn mr-3" : "questionbtnd mr-3"}><Button onClick={() => { handleChange("Yes", 0) }}>Yes</Button></div>
                <div className={questions[0]["answer"] == 'No' ? "questionbtn" : "questionbtnd"}><Button onClick={() => { handleChange("No", 0) }}>No</Button></div></div>
            </div>
            <div className="question">
              <div className="question1 my-3">2. Do you have any Recognized Certifications</div>
              <div className="questionbuttons d-flex"><div className={questions[1]["answer"] == 'Yes' || (questions[1]["answer"]?.length > 0 && questions[1]["answer"] != "No") ? "questionbtn mr-3" : "questionbtnd mr-3"}><Button onClick={() => { handleChange("Yes", 1); setCer('') }}>Yes</Button></div>
                <div className={questions[1]["answer"] == 'No' ? "questionbtn mr-3" : "questionbtnd mr-3"}><Button onClick={() => { handleChange("No", 1); setCer('') }}>No</Button></div></div>
            </div>
            {(questions[1]["answer"] != "No" && questions[1]["answer"] != '' && questions[1]["answer"]) && <div className={questions[1]["answer"]?.length > 0 && questions[1]["answer"] != "No" && questions[1]["answer"] != "Yes" ? "inputtag1 w-100 my-3" : "inputtag w-100 my-3"}>
              <Input placeholder='Enter Name' defaultValue={cer} style={{ height: "45px" }} onChange={(e) => { handleChange(e.target.value, 1) }} /></div>}
            <div className="question">
              <div className="question1 my-3">3. How many year’s experience do you have as an installr?</div>
              <div className="questionyears d-flex">
                <div className={year ? "inputSelected" : "inputnotselected"}><Input value={year} min={0} max={100} suffix={<span >{year1}</span>} placeholder='' minLength={1} maxLength={2} onChange={(e) => { checkInput1(e); }} />
                </div>
                <div className={months ? "inputSelected" : 'inputnotselected'} style={{ width: "48%" }}><Input value={months} min={0} max={12} suffix={<span >{months1}</span>} placeholder='' minLength={1} maxLength={2} onChange={(e) => { checkInput(e) }} /></div>
              </div>
            </div>
            <div className="question"><div className="question1 my-3">4. Do you have a criminal record or any restrictions from working?</div>
              <div className="questionbuttons d-flex"><div className={questions[3]["answer"] == 'Yes' ? "questionbtn mr-3" : "questionbtnd mr-3"}><Button onClick={() => { handleChange("Yes", 3) }}>Yes</Button></div>
                <div className={questions[3]["answer"] == 'No' ? "questionbtn mb-3" : "questionbtnd mb-3"}><Button onClick={() => { handleChange("No", 3) }} >No</Button></div></div>
            </div>
          </div>
          <div className="subform">
            <Button htmlType="submit"
              disabled={disabled}
              className={disabled ? "contractor-submitbtnd" : "contractor-submitbtn"}
            >
              Next
            </Button>
          </div>
        </Form>

      </div>
    </>
  );
};

export default Questions;