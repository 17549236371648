import React, { useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import moment from "moment";

import './schedule.less'
const orientation = {
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
};

const MonthlyCalender = ({
  active,
  appointmentList,
  onSelectDatePicker,
}) => {
  const [value, setValue] = useState(null);
  const onChange = (e) => {
    setValue(e);
    onSelectDatePicker(e,appointmentList);
  };

  const tileContent = ({ date, view }) => {
    return (
      <div className="day-appointments">
        {appointmentList?.map((item) => (
          date.getDay() === new Date(item?.jobStartDate).getDay() &&
          date.getMonth() === new Date(item?.jobStartDate).getMonth() &&
          date.getDate() === new Date(item?.jobStartDate).getDate() &&
          <div className="greenCircle">
          </div>
        ))}
      </div>
    );
  };

  const calendarStyle = () => {
    return {
      style: {
        backgroundColor: 'red !important',
        color: 'green !important'
      }
    }
  }

  const maxDate = moment()
    .add(3, "months")
    .endOf("month")
    .toDate();

  useEffect(() => {
    setValue(new Date())
  }, [])
  return (
    <div className="calender">
      {active === orientation.WEEKLY ? (
        <div>date Schedule</div>
      ) : (
        <Calendar
          className="calender"
          onChange={onChange}
          value={value}
          tileClassName={({ date, view }) => {
            if (new Date(date).getDay() === new Date().getDay()) {
              return calendarStyle
            }
          }}
          minDate={moment().toDate()}
          maxDate={maxDate}
          tileContent={(props) => tileContent(props)}
          tileDisabled={
            ({ date, view }) => {
              const checkAppointment = appointmentList?.find((listing) => {
                return (
                  date.getDay() === new Date(listing?.jobStartDate).getDay() &&
                  date.getMonth() === new Date(listing?.jobStartDate).getMonth() &&
                  date.getDate() === new Date(listing?.jobStartDate).getDate()
                );
              });
              if (checkAppointment) {
                return false;
              }
              return true;
            }
          }
          minDetail={"month"}
        />
      )}
    </div>
  );
};

export default MonthlyCalender;
