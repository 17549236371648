import React from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import acknowledgeicon from '../../../asset/images/aknowledgeicon.svg';
import crossicon from '../../../asset/images/crossicon.svg';
import walletImg from '../../../asset/images/walletimg.svg';
import { setCreateObject } from '../../../redux/slices/login';

export const PostJobPopup = ({
  postJobModal,
  setPopupModal,
  setPostJobModal,
  approxCost,
  handleCreateJob,
  setPaymentModalPopup,
  isHome,
  state,
  inEdit,
  approxCostAlertText
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = () => {
    // dispatch(setCreateObject({}));
    if (!isHome && !inEdit) setPopupModal(false);
    setPostJobModal(false);
  };
  const handleClickPost = () => {
    if (!inEdit) {
      setPopupModal(false);
      setPaymentModalPopup(true);
    } else {
      handleCreateJob();
    }
    setPostJobModal(false);
  };
  const handleAck = () => {
    setPostJobModal(true);
  };
  const handleLog = () => {
    localStorage.setItem('role', 0);
    navigate('/clientsignin', { state: { val: state } });
  };

  return !postJobModal ? (
    <div className="ackpopup d-flex flex-column justify-content-center ">
      <div className="crossicon" style={{ cursor: 'pointer' }} onClick={handleClick}>
        <img src={crossicon} />
      </div>
      <div className="acknowledgeicon m-3" onClick={handleClick}>
        <img src={acknowledgeicon} />
      </div>
      <div className="termsheading m-2">Terms for posting a job</div>
      <div className="termscontent m-1">
        Cancellation of job within <span className="impheading"> 48 hours</span> of installation time will incur a{' '}
        <span className="impheading"> 10% charge</span> of entire job plus any additional purchased supplies and
        lift charges if applicable.
      </div>
      <div className="termscontent m-1">
        Cancellation of job within <span className="impheading">24 hours</span> of installation time will incur a{' '}
        <span className="impheading">25% charge</span> of entire job plus any additional purchased supplies and lift
        charges if applicable.
      </div>
      <div className="termscontent m-1">
        Any changes/alterations to job prior to installation date greater than 15% of sq. ft. should be reposted as a
        separate request. Additions/omissions shall be treated as “additional expenses”
      </div>
      <Button className="termscontentbtn m-3" onClick={handleAck}>
        I Acknowledge
      </Button>
    </div>
  ) : (
    <div className="ackpopup  d-flex flex-column justify-content-center m-auto">
      <div className="crossicon" style={{ right: '0rem', cursor: 'pointer' }} onClick={handleClick}>
        <img src={crossicon} style={{ width: '30px', height: '30px' }} />
      </div>
      <div className="costimgcontent m-2">
        <div className="costimg">
          <img src={walletImg} />
        </div>
        <div className="approxcost">Approx Cost</div>
        <div className="cost">$ {Math.abs(approxCost).toFixed(2)}</div>
      </div>
      <div className="content m-3">
        {approxCost > 9999
          ? 'Unfortunately, this amount exceeds our online purchasing threshold. To continue please go to the contact us section and send us details via this portal so that one of our project experts can facilitate your work. Thank you':
          approxCostAlertText!=null?approxCostAlertText : 'This is an approx cost, please note any changes, additional needed supplies or scope could change price.'}
      </div>
      {  approxCost > 9999 ?
        
        <div className="approxbtns">
        <Button
        className="approxbtn1"
        >
   < a className="emailref mx-auto mt-1 " href="mailto:support@myinstallr.ca" style={{ color: "black", fontWeight: "bold !important", textAlign: "center", fontSize: "15px" ,textDecoration:"none"}}><div className="sendmailbtn">Contact Us</div></a>  
        </Button>
        <Button className="approxbtn2 mt-1" onClick={()=>{ if(isHome || inEdit)
        setPostJobModal(false)
        else
        {
          setPopupModal(false);}
      }}>Edit</Button>
        </div>: (
      
     isHome ? (
      <Button
        className="termscontentbtn m-3"
        onClick={() => {
          handleLog();
        }}
      >
        Log In to Post this Job
      </Button>
    )   :
          <Button className="termscontentbtn m-3" onClick={handleClickPost}>
            {inEdit ? 'Save' : 'Post'}
          </Button>
        
      )}
    </div>
  );
};

export default PostJobPopup;
