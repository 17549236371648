import React, { useEffect, useState,useRef } from 'react';
import DashBoardWrapper from '../../../components/wrapper/DashBoardWrapper';
import income from '../../../asset/images/income.svg'
import dropdownicon from '../../../asset/images/dropdownicon.svg';
import incomedep from '../../../asset/images/incomedep.svg';
import { Dropdown, Space, Menu } from 'antd';
import EarningDetails from './EarningDetails';
import { totalEarningsApi, transactionsApi } from '../../../api/getjob';
import dayjs from 'dayjs';
import { showLoader } from '../../../redux/slices/common'
import { useDispatch } from 'react-redux';
import './style.less';
import SpinWrapper from '../../../components/wrapper/SpinWrapper';
import handleScroll from '../../../common/helper/handleScroll';

const EarningInfo = () => {
  const currentYear = new Date().getFullYear();
  const [currentYear1, setCurrentYear1] = useState(new Date().getFullYear());
  const [transactions, setTransactions] = useState([]);
  const [earnings, setEarnings] = useState();
  const [page,setPage]=useState(1);
  const  listInnerRef=useRef();
  const itemsval = ["Total Earning", "Pending", "Deposited"]
  const dispatch = useDispatch();
  
  const handleMenu = (e) => {
    setCurrentYear1(e.key)
  }
  const menu = (
    <Menu onClick={(e) => handleMenu(e)}>
      <Menu.Item key={currentYear} >
        {currentYear}
      </Menu.Item>
      <Menu.Item key={currentYear - 1}>
        {currentYear - 1}
      </Menu.Item>
      <Menu.Item key={currentYear - 2}>
        {currentYear - 2}
      </Menu.Item>
      <Menu.Item key={currentYear - 3}>
        {currentYear - 3}
      </Menu.Item>
    </Menu>
  );


  const callTotalEarnings = async () => {
    dispatch(showLoader(true))
    const resp = await totalEarningsApi();
    setEarnings(resp?.data?.data);
    dispatch(showLoader(false))
  }

  const callAllTransactions = async () => {
    dispatch(showLoader(true))
    const resp = await transactionsApi(currentYear1 || currentYear,page);
    if(page==1)
    setTransactions([...resp?.data?.data])
    else
    setTransactions([...transactions,...resp?.data?.data])
    dispatch(showLoader(false))
  }
  const handleSetPage=()=>{
    setPage(transactions?.length/10+1);
  }

  useEffect(() => {
    callTotalEarnings();
  }, [])

  useEffect(() => {
    setPage(1);
    setTransactions([]);
  }, [currentYear1]);
  useEffect(()=>{
    callAllTransactions();
  },[page,currentYear1])

  return (
    <div className="earninginfo">
      <EarningDetails items={itemsval} earnings={{ "item1": earnings?.totalEarning, "item2": earnings?.pending, "item3": earnings?.deposited }} />
      <div className="transactions p-3">
        <div className="tinformation d-flex justify-content-between ">
          <div className="transaction">All Transactions</div>
          <div className="listyears">
            <Dropdown overlay={menu} trigger={['click']}>
              <a onClick={(e) => { e.preventDefault() }}>
                <Space>
                  {currentYear1}
                  <img src={dropdownicon} />
                </Space>
              </a>
            </Dropdown>
          </div>
        </div>
        <hr />
        <div className="transactioninfo" onScroll={()=>{handleScroll(listInnerRef,transactions?.length,handleSetPage)}}>
          {transactions?.length > 0 ? transactions?.map((item) => {
            return (
              <div key={item?.createdAt} className="singletransaction d-flex justify-content-between">
                <div className="tinfo ">
                  <div className="timg mr-2"><img src={item?.isCompensationAmount&&item?.isDeposited ? incomedep : !item?.isDeposited&&item?.isCompensationAmount?income:item?.isDeposited&&!item?.isCompensationAmount?incomedep:income}
                   /></div>
                  <div className="tdata">
                    <div className="thead">{item?.isCompensationAmount&&item?.isDeposited ? "Compensation Deposited" : item?.isDeposited&&!item?.isCompensationAmount?"Income Deposited":!item?.isDeposited&&item?.isCompensationAmount?"Compensation":"Income"}</div>
                    <div className="tdate">{dayjs(item?.createdAt).format("MMMM DD, YYYY")}</div>
                  </div>
                </div>
                <div className="tprice">${Math.abs(item?.contractorAmount).toFixed(2)}</div>
              </div>
            )
          }) : <div className="text-center" style={{ color: "black", fontWeight: "500" }}>No Transactions Available for {currentYear1}</div>
          }
        </div>
      </div>

    </div>
  )
}

const Earnings = () => {
  return (
    <DashBoardWrapper>
      <SpinWrapper>
        <EarningInfo />
      </SpinWrapper>
    </DashBoardWrapper>
  )
}
export default Earnings;