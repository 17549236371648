import React,{ useState, useEffect } from "react";
import dropdownup from '../../../asset/images/dropdownup.svg'
import dropdown from '../../../asset/images/dropdownicon.svg'
import { CategoryList } from '../../../api/detailsapi';
import { Divider, Button } from 'antd';
import CategoryItem from "../../../pages/contractor/profilesetup/CategoryItem";
import deleteIcon from '../../../asset/images/deleteIcon.svg';

const ServiceModal = ({ selectedArray, setSelectesArray, setOpenServiceModal, setSelectedItems, selectedItems, setItemsSelected }) => {
  const [isSelected, setisSelected] = useState(true);
  const [options, setOptions] = useState([]);
  const [checked, setChecked] = useState(false)
  const multipleSelect = (ind) => {
    setChecked(true);
    setSelectedItems([...selectedItems, ind._id]);
    setSelectesArray([...selectedArray, ind])
  };
  const removeSelected = (ind) => {
    const filterItem = selectedItems.filter((item) => item !== ind._id);
    const filterArray = selectedArray.filter((item) => item._id != ind._id);
    setSelectedItems([...filterItem]);
    setSelectesArray([...filterArray]);
  };
  const isClicked = () => {
    setisSelected(!isSelected);
  };
  const fetchData = async (values) => {
      const response = await CategoryList(values);
      setOptions(response.data)
  };
  useEffect(() => {
    fetchData();
  }, [])
  return (
    <>
      <div className="profileform w-100">
        <div className="contractor-subform mb-2 w-100">
          {/* <Form.Item > */}
          <div className='multi_select_field' onClick={() => {
            isClicked();
          }}>
            <div className="place_holder">Select Your Services</div>
            <div
              style={{ cursor: 'pointer' }}
            >
              {' '}
              {isSelected ? <img src={dropdownup} /> : <img src={dropdown} />}
            </div>
          </div>
          {/* </Form.Item> */}
        </div>
        {isSelected ? (
          <div className="options_board" style={{ width: "89%", height: "65%" }}>
            {options.map((items, index) => (
              <>
                <div className="option_list_title">
                  <h1>{items.name}</h1>
                  {items.subcategories.map((item, ind) => (
                    <>
                      <CategoryItem item={item} setSelectedItems={setSelectedItems} selectedItems={selectedItems} setSelectesArray={setSelectesArray} selectedArray={selectedArray} multipleSelect={multipleSelect} removeSelected={removeSelected} checked={checked} setChecked={setChecked} />
                      <Divider style={{ margin: "10px 0" }} />
                    </>
                  ))}
                </div>
              </>
            ))
            }
          </div>) : ""}
        {selectedItems.length > 0 && (
          <div className="subform">
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', marginBottom: '1.5rem', height: "85px", overflowY: "scroll" }}>
              {selectedArray.map((item) => {
                return (
                  <div key={item?._id} className="checkedItem">
                    <span className="checkedItem_img">
                      <img src={item.subCategoryImage.url} />
                    </span>
                    <>{item.name}</>
                    <span
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        border: '1px solid #ddd',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onClick={() => {
                        removeSelected(item);
                      }}
                    >
                      <img src={deleteIcon} />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <Button className={selectedArray.length > 0 ? "save" : "save savedisable"} onClick={() => { setOpenServiceModal(false); setItemsSelected(selectedArray) }} >Save</Button>

    </>
  )
}
export default ServiceModal