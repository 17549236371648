import React, { useState, useEffect } from 'react'
import disabledradiobtn from '../../../asset/images/disabledradiobtn.svg';
import enabledradiobtn from '../../../asset/images/enabledradiobtn.svg';
import paymentunsuccess from '../../../asset/images/paymentunsuccess.svg';
import CardDetailsInfo from '../profilesetup/CardDetailsInfo';
import { getCardsApi } from '../../../api/cardinfoapi';
import visacard from '../../../asset/images/visacard.svg';
import { Modal, Button } from 'antd';
import crossicon from '../../../asset/images/crossicon.svg';
import paymentsuccess from '../../../asset/images/paymentsuccess.svg';
import discovers from '../../../asset/cardicons/discover.png'
import amex from '../../../asset/cardicons/amex.png';
import mastercard from '../../../asset/cardicons/mastercard.png';
import unioncard from '../../../asset/cardicons/unioncard.png';
import diners from '../../../asset/cardicons/diners.png';
import jcb from '../../../asset/cardicons/jcb.png';



const CardsList = ({ setSelectedCard, selectedCard, paymentModal, setPaymentModal, handleAck, isPaymentSuccess }) => {
  const [addCardModal, setAddCardModal] = useState(false);
  const [cards, setCards] = useState([])
  const handleAddClick = () => {
    setAddCardModal(true)
  }
  const callApi = async () => {
    const resp = await getCardsApi();
    setCards(resp?.data?.data);
    const val = resp?.data?.data?.find((item) => item?.isSelected);
    if (val)
      setSelectedCard(val)
  }
    const cardImage = {
      Discover: discovers,
      'American Express': amex,
      MasterCard: mastercard,
      UnionCard: unioncard,
      Visa: visacard,
      Diners: diners,
      Jcb: jcb,
    };

  useEffect(() => {
    if (!addCardModal)
      callApi();
  }, [addCardModal])

  return (
    <>
      <div className="cards w-100">
        <div className="cardheading d-flex justify-content-between align-items-center w-100 ">
          <div className="cardh1">Select Card</div>
          <div className="cardh2" onClick={() => { handleAddClick() }}>+Add Card</div>
        </div>
        <div className="allcards">
          {cards?.map((item) => {
            return (
              <div
                key={item?._id}
                className="card d-flex justify-content-between w-100 flex-row mb-4 "
                style={{ borderRadius: '10px', padding: '10px' }}
                onClick={() => {
                  setSelectedCard(item);
                }}
              >
                <div className="cardnum d-flex">
                  <div className="icon mr-2">
                    <img src={cardImage[item?.brand]} />
                  </div>
                  <div className="number d-flex align-items-center">**** **** **** {item?.last4}</div>
                </div>
                <div className="img">
                  <img src={selectedCard?.id == item?.id ? enabledradiobtn : disabledradiobtn} />
                </div>
              </div>
            );
          })
          }
        </div>
      </div>
      {paymentModal && <Modal
        centered
        header={null}
        footer={null}
        open={paymentModal}
        className="paymentinfopopup"
        closable={false}
      >
        <div className="crossicon" style={{ right: "0.5rem", cursor: "pointer", position: "absolute" }}
          onClick={() => { setAddCardModal(false) }}><img src={crossicon} /></div>
        <div className="d-flex text-center justify-content-center align-items-center flex-column paymentinfo">
          <img src={isPaymentSuccess ? paymentsuccess : paymentunsuccess} />
          <h4 className="paymentheading">{isPaymentSuccess ? "Payment Successful" : "Payment UnSuccessful"}</h4>
          <div className="paymenttxt">{isPaymentSuccess ? "Thank you for using Myinstallr We look forward to working together again!" : "That didn’t Work! Would you like to try with same card or use a new card?"}</div>
          <Button className="ackbtn" onClick={() => { handleAck() }}>I Acknowledge</Button>
        </div>
      </Modal>}
      {addCardModal && <Modal
        centered
        header={null}
        footer={null}
        open={addCardModal}
        className="addcardmodal"
        closable={false}
      >
        <>
          <div className="crossicon" style={{ right: "0.5rem", cursor: "pointer", position: "absolute" }} onClick={() => { setAddCardModal(false) }}><img src={crossicon} /></div>
          <CardDetailsInfo inJobs={true} setAddCardModal={setAddCardModal} />
        </>
      </Modal>}
    </>
  )
}

export default CardsList