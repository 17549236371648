import { useState } from 'react';
import TermsandConditions from '../pages/SignUp/TermsandConditions'
import PrivacyPolicy from '../pages/SignUp/PrivacyPolicy';
import { Modal } from 'antd';
const MenuFooter = () => {
  const [privacyModal, setPrivacyModal] = useState(false);
  const [termsModal, setTermsModal] = useState(false);
  return (
    <>
      <div className="termsandconditions text-center w-100" ><span
        onClick={() => { setTermsModal(true) }} style={{ cursor: "pointer" }}>
        Terms and Conditions</span > <span style={{ color: "#9E9E9E" }}>&</span>
        <span onClick={() => { setPrivacyModal(true) }} style={{ cursor: "pointer", marginLeft: "5px" }}>Privacy Policy</span></div>
      <Modal
        centered
        open={privacyModal}
        className='privacymodal'
        closable={false}
        width="100%"
        height="100vh"
        header={null}
        footer={null}
        style={{ top: '', overflow: "hidden !important" }}
      >
        <PrivacyPolicy setPrivacyModal={setPrivacyModal} />
      </Modal>
      <Modal
        centered
        open={termsModal}
        className='privacymodal'
        closable={false}
        width="100%"
        height="100vh"
        header={null}
        footer={null}
        style={{ top: '', overflow: "hidden !important" }}
      >
        <TermsandConditions setTermsModal={setTermsModal} />
      </Modal>
    </>
  )
}
export default MenuFooter;