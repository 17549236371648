const handleScroll = (ref, pageData, handleSetPage) => {
  if (ref?.current && pageData % 10 == 0) {
    const { scrollTop, scrollHeight, clientHeight } = ref.current;
    if (
      Math.ceil(scrollTop + clientHeight) === scrollHeight ||
      Math.round(scrollTop + clientHeight) === scrollHeight ||
      Math.floor(scrollTop + clientHeight) === scrollHeight
    ) {
      handleSetPage()
    }
  }
}

export default handleScroll;