import React, { useState, useEffect } from 'react';
import unfilled from '../../../asset/images/unfilled.svg';
import fiiled from '../../../asset/images/comfilled.svg';
import Progress from 'antd/es/progress';
import page1 from '../../../asset/images/page1.png';
import page2 from '../../../asset/images/page2.png';
import page3 from '../../../asset/images/page3.png';
import './style.less'
import { useNavigate, useLocation } from 'react-router-dom';
import ProfileDetails from './ProfileDetails';
import CardDetailsInfo from './CardDetailsInfo';
import Verify from '../../Verify';

const ProfileSetUp = () => {
  const [percentInc, setPercentInc] = useState(20);
  const [count, setCount] = useState(1);
  const [isClient, setIsClient] = useState(true);
  const [details, setDetails] = useState({});
  const navigate = useNavigate();
  const { state } = useLocation();
  const handleAddCount = () => {
    setCount(prev => prev + 1);
  }
  const save = async (values) => {
    setDetails({ ...details, ...values });
  }
  const handleIncrement = () => {
    if (count === 2) {
      setPercentInc(52)
    }
    else if (count === 3) {
      setPercentInc(80)
    }
    else {
      setPercentInc(100);
      navigate("/dashboard")
    }
  }

  useEffect(() => {
    if (count !== 1) {
      handleIncrement();
    }
  }, [count])

  useEffect(() => {
    if (state?.value == 2) {
      setPercentInc(52);
      setCount(2);
    }
    if (state?.value == 3) {
      setPercentInc(80);
      setCount(3);
    }
  }, [])

  return (
    <>
      <div className="client-select-container">
        <div className="center_container">
          <div className="progress_container">
            <div className={'modal-card-content'}>
              <div className={'modal-card-content'}>
                <div className="progressbar position-relative">
                  <img src={page1} style={{ position: 'absolute', zIndex: '1', left: '19%', top: "-250%" }} />
                  <img src={percentInc > 20 ? fiiled : unfilled} style={{ position: "absolute", zIndex: "1", left: "19%" }} 
                  className="progressbarimg progressbarimg1" />
                  <img src={page2} style={{ position: 'absolute', zIndex: '1', left: '48%', top: "-250%" }} />
                  <img src={percentInc <= 50 ? unfilled : fiiled} 
                  style={{ position: "absolute", zIndex: "1", left: "48%" }} className="progressbarimg" />
                  <img src={page3} style={{ left: '76%', position: 'absolute', zIndex: '1', top: "-250%" }} />
                  <img src={percentInc <= 79 ? unfilled : fiiled} style={{ left: "76%", position: "absolute", zIndex: "1" }}
                   className="progressbarimg3" />
                  <Progress showInfo={false}
                    strokeColor={'black'}
                    percent={percentInc}
                    style={{ height: "5px", left: "12px", marginTop: "5px" }} />
                  <div className="infonames" style={{ marginTop: "10px" }}>
                    <div className="profileinfosel" style={{ position: "absolute", zIndex: "1", left: "14%", marginTop: "2%" }}> Verify Number</div>
                    <div className="profileinfosel" style={{ position: "absolute", zIndex: "1", left: "44%", marginTop: "2%" }}> Profile Details</div>
                    <div className="profileinfosel" style={{ position: "absolute", zIndex: "1", left: "72%", marginTop: "2%" }}> Card Details</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-data">
            {count === 1 && <Verify isClient={isClient} handleAddCount={handleAddCount} save={save} />}
            {count === 2 && <ProfileDetails handleAddCount={handleAddCount} save={save} />}
            {count === 3 && <CardDetailsInfo setPercentInc={setPercentInc} save={save} />}

          </div>
        </div></div>
    </>
  )
}
export default ProfileSetUp;