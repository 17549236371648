import React, { useState, useEffect } from 'react';
import dayjs from "dayjs";

const TimeInfo = ({ timeval }) => {
  const [val, setVal] = useState("");
  const CallFunc = () => {
    const currentDate = dayjs();
    const checkDate = dayjs(timeval);
    const isToday = currentDate.isSame(checkDate, 'day');
    const isYesterday = currentDate.subtract(1, 'day').isSame(checkDate, 'day');
    const startDate = dayjs(currentDate.subtract(7, 'day'));
    const endDate = dayjs(currentDate);
    const isBetween = checkDate >= startDate && checkDate <= endDate;
    if (isToday)
      setVal(dayjs(checkDate).format('HH:mm A'));
    else if (isYesterday)
      setVal("yesterday");
    else if (isBetween)
      setVal(dayjs(checkDate).format("dddd"));
    else
      setVal(dayjs(checkDate).format("DD/MM/YYYY"));
  }
  useEffect(() => {
    CallFunc();
  }, [timeval])
  return (
    <div>{val}</div>
  )
}

export default TimeInfo;