import React from 'react';

function IsLiftRequiredInfo({ singleJobInfo, liftrequiredbtn, notrequiredliftbtn }) {
  return (
    <>
      {singleJobInfo?.isLiftRequired ? (
        <div className="liftservice " style={{ background: '#27AE600D' }}>
          <img src={liftrequiredbtn} />
          <span style={{ color: 'green' }}>Required</span>
        </div>
      ) : (
        <div className="liftservice mt-3 mb-6">
          <img src={notrequiredliftbtn} />
          Not Required
        </div>
      )}
    </>
  );
}

export default IsLiftRequiredInfo;
