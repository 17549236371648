import React from 'react';
import HomePage from '../modules/Home/Home';
import SelectRole from '../pages/SelectRole/SelectRole';
import SignUp from '../pages/SignUp/SignUp';
import SignIn from '../pages/SignIn/SignIn';
import Request from '../pages/SignUp/Request';
import ResetPassword from '../pages/SignIn/ResetPassword';
import PassResetMail from '../pages/SignIn/PassResetMail';
import ProfileSetUp from '../pages/client/profilesetup'
import ContractorProfileSetUp from '../pages/contractor/profilesetup';
import Verify from '../pages/Verify';
import VerifyContractor from '../pages/VerifyContractor';
import CreateJobs from '../pages/client/createjob';
import NewJobs from '../pages/contractor/newjobs';
import Settings from '../pages/client/Settings';
import SingleJobInfo from '../pages/contractor/newjobs/singleJobInfo'
import PrivatePath from '../common/helper/PrivatePath';
import ContactUs from '../pages/ContactUs';
import Jobs from '../pages/client/Jobs';
import InfoData from '../pages/client/Jobs/InfoData'
import ContractorJobs from '../pages/contractor/Jobs'
import MessageClient from '../pages/client/messages/index'
import Ratings from '../pages/contractor/ratings';
import Earnings from '../pages/contractor/Earnings';
import DashBoard from '../pages/contractor/dashboard';
import AboutUs from '../modules/aboutus/AboutUs';
import ContactUsInfo from '../modules/contactus/ContactUs';
import ProcessPage from '../modules/process/ProcessPage';
import TermsandConditions from '../pages/SignUp/TermsandConditions';
import PrivacyPolicy from '../pages/SignUp/PrivacyPolicy';

const routes = [
  {
    path: '/',
    element: () => <HomePage />,
  },
  {
    path: '/selectrole',
    element: () => <SelectRole />,
  },
  {
    path: '/contractorsignup',
    element: () => <SignUp />,
  },
  {
    path: '/clientsignup',
    element: () => <SignUp />,
  },
  {
    path: '/contractorsignin',
    element: () => <SignIn />,
  },
  {
    path: '/clientsignin',
    element: () => <SignIn />,
  },
  {
    path: '/verify',
    element: () => (
      <PrivatePath>
        <Verify />
      </PrivatePath>
    ),
  },
  {
    path: '/verifycontractor',
    element: () => (
      <PrivatePath>
        <VerifyContractor />
      </PrivatePath>
    ),
  },
  {
    path: '/request',
    element: () => <Request />,
  },
  {
    path: '/resetemail',
    element: () => <PassResetMail />,
  },
  {
    path: '/resetpassword',
    element: () => <ResetPassword />,
  },

  {
    path: '/clientprofile',
    element: () => (
      <PrivatePath>
        <ProfileSetUp />
      </PrivatePath>
    ),
  },
  {
    path: '/contractorprofile',
    element: () => (
      <PrivatePath>
        {' '}
        <ContractorProfileSetUp />
      </PrivatePath>
    ),
  },
  {
    path: '/createjob',
    element: () => (
      <PrivatePath>
        <CreateJobs />
      </PrivatePath>
    ),
  },
  {
    path: '/newjob',
    element: () => (
      <PrivatePath>
        <NewJobs />
      </PrivatePath>
    ),
  },
  {
    path: '/settings',
    element: () => (
      <PrivatePath>
        <Settings />
      </PrivatePath>
    ),
  },
  {
    path: '/:id',
    element: () => (
      <PrivatePath>
        <SingleJobInfo />
      </PrivatePath>
    ),
  },
  {
    path: '/contactus',
    element: () => (
      <PrivatePath>
        <ContactUs />
      </PrivatePath>
    ),
  },
  {
    path: '/jobs',
    element: () => (
      <PrivatePath>
        <Jobs />
      </PrivatePath>
    ),
  },
  {
    path: '/jobsdata',
    element: () => (
      <PrivatePath>
        <InfoData />
      </PrivatePath>
    ),
  },
  {
    path: '/contjobsdata',
    element: () => (
      <PrivatePath>
        <SingleJobInfo />
      </PrivatePath>
    ),
  },
  {
    path: '/contractorjoblist',
    element: () => (
      <PrivatePath>
        <ContractorJobs />
      </PrivatePath>
    ),
  },
  {
    path: '/messages',
    element: () => (
      <PrivatePath>
        <MessageClient />
      </PrivatePath>
    ),
  },
  {
    path: '/ratings',
    element: () => (
      <PrivatePath>
        <Ratings />
      </PrivatePath>
    ),
  },
  {
    path: '/earnings',
    element: () => (
      <PrivatePath>
        <Earnings />
      </PrivatePath>
    ),
  },
  {
    path: '/dashboard',
    element: () => (
      <PrivatePath>
        <DashBoard />
      </PrivatePath>
    ),
  },
  {
    path: '/aboutus',
    element: () => <AboutUs />,
  },
  {
    path: '/contactusinfo',
    element: () => <ContactUsInfo />,
  },
  {
    path: '/process',
    element: () => <ProcessPage />,
  },
  {
    path: '/termsandconditions',
    element: () => <TermsandConditions inNavigation={true} />,
  },
  {
    path: '/privacypolicy',
    element: () => <PrivacyPolicy inNavigation={true} />,
  },
];

export default routes;
