import React, { useState } from "react";
import { Button, message } from 'antd';
import OTPInput, { ResendOTP } from "otp-input-react";
import { useDispatch } from "react-redux";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './verify.less';
import { sendOtp, reSendOtp, verifyOTP } from '../api/detailsapi';
import { setProfile } from '../redux/slices/login';

function Verify({ isClient, handleAddCount, save }) {
  const [, setResendCode] = useState(false)
  const [otp, setOTP] = useState(null);
  const [contryCode, setCountryCode] = useState('')
  const [mobile, setMobile] = useState(0)
  const [sentOTP, setSentOTP] = useState(false)
  const [resendCount, setResendCount] = useState(0)
  const [disableNumber, setDisableNumber] = useState(false)
  const [disabledBtn, setDisabledBtn] = useState(false)
  const dispatch = useDispatch();

  const handleResendCode = async () => {
    setDisabledBtn(true);
    setOTP(null);
    setResendCode(true);
    const res = await reSendOtp({
      "countryCode": `+${contryCode}`,
      "phoneNumber": mobile
    })
    if (res?.status === 200) {
      message.success(res.data.message);
      setDisabledBtn(false)
      setResendCount(resendCount + 1)
      setSentOTP(true)
    }
  }

  const renderButton = (buttonProps) => {
    return <div style={{ fontSize: "0.875rem", color: "#6B6B6B" }}>Not Received the Code? {resendCount >= 2 ? <Button disabled className='resend-otp-btn p-0 fw-bold' type="link" > Resend</Button> : <Button className='resend-otp-btn' type="link" style={{ textTransform: "capitalize", fontWeight: 600, paddingLeft: '0px' }} {...buttonProps}>Resend</Button>} </div>;
  };

  const renderTime = (remainingtime) => {
    return remainingtime ? <span style={{ textTransform: "capitalize", fontWeight: 600 }}>00:{remainingtime < 10 ? 0 : ''}{remainingtime}</span> : '';
  };

  const handleSendOTP = (e) => {
    e.preventDefault();
    const body = {
      "countryCode": `+${contryCode}`,
      "phoneNumber": mobile
    }
    sendOTP(body)
  }

  const sendOTP = async (body) => {
    setDisabledBtn(true)
    const response = await sendOtp(body);
    if (response?.status === 200) {
      message.success(response.data.message);
      setDisabledBtn(false)
      setDisableNumber(true)
      setSentOTP(true)
    } else if (response?.data?.status >= 400) {
      setDisabledBtn(true)
    }
  }

  const handleVerify = async () => {
    let values = { otp: otp };
    setDisabledBtn(true);
    const response = await verifyOTP(values);
    if (response?.status === 200) {
      message.success(response.data.message);
      const val = { phoneNumber: mobile, countryCode: contryCode }
      save(val);
      setDisabledBtn(false);
      dispatch(setProfile({ isMobileVerified: true, userType: 0 }));
      if (isClient) {
        handleAddCount()
      }
    }
  }

  return (
    <>
      {!sentOTP &&
        <div className={isClient ? "clientcont" : "welcome-container"}>
          <div className={isClient ? "clientcont1 justify-content-center" : "center-container justify-content-center"} >

            <div className="welcomephone-header mb-0">
              Enter Your Phone Number
            </div>
            <div className={mobile?.length > 0 ? "mb-3 mt-5 phoneinputdiv" : "mb-3 mt-5 phoneinputdiv1"}>
              <PhoneInput
                inputExtraProps={{
                  placeholder: 'Enter phone number',
                  searchPlaceholder: "Enter phone number"
                }}
                placeholder='Enter phone number'
                searchPlaceholder="Enter phone number"
                country="ca"
                //   onlyCountries={['ca', 'in']}
                excludeCountries={['us']}
                enableAreaCodes={false}
                copyNumbersOnly={false}
                inputClass={mobile?.length > 0 ? "ant-input ant-input-lg phoneinput1" : "ant-input ant-input-lg phoneinput"}
                containerClass="ant-input-affix-wrapper ant-input-affix-wrapper-lg"
                dropdownClass="ant-select ant-select-single"
                buttonStyle={{ background: 'none', border: 'none' }}
                inputStyle={{ width: "100%", paddingLeft: "60px", height: "50px" }}
                disabled={disableNumber}
                className="phoneinput"
                // countryCodeEditable={false}
                onChange={(value, country) => { setCountryCode(country?.dialCode); setMobile(value.slice(country?.dialCode?.length)) }}
              />
            </div>
            <Button
              onClick={(e) => { handleSendOTP(e) }}
              className={((mobile == 0 || mobile =='' || mobile?.length < 10) ? true : false) ? "btnstyle1" : "btnstyle"}
              shape="round"
              type="primary"
              size="large"
              style={{ justifyContent: "center", display: "flex", width: "100%", margin: "10px auto 10px 23%", alignItems: "center" }}
              disabled={
                ((mobile == 0 || mobile == '' || mobile?.length < 10) ? true : false)
              }>
              Next
            </Button>
          </div>
          <div>
          </div>
        </div>}
      {
        sentOTP && <>
          <div className={isClient ? "clientcont" : "center-container"}>
            <div className={isClient ? "clientcont1" : "welcome-container"}>
              <div className={isClient ? "justify-content-center" : "center-container justify-content-center"} >

                <h1 className="welcomephone-header mb-0 text-center" >
                  Enter Verification Code
                </h1>
                <p className="welcome-subheader mb-3" style={{ display: "flex", justifyContent: "center", color: "#BEC4C6" }}>Please type the verification code sent to
                </p>
                <p style={{ display: "flex", justifyContent: "center", color: "#005586" }}>
                  {" +"}{contryCode} {mobile}
                </p>
                <OTPInput
                  className={((otp === null || otp === '' || otp?.length < 4) ? "otp-input-container my-4" : "otp-input-container1 my-4")}
                  inputClassName="otp-input"
                  value={otp}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={4}
                  otpType="number"
                  disabled={false}
                  secure={false} />
                <ResendOTP
                  renderButton={renderButton}
                  renderTime={renderTime}
                  style={{ justifyContent: "center", display: "flex", width: "100%" }}
                  className="resendbtn"
                  maxTime={30}
                  onResendClick={() => handleResendCode()}
                />
                <Button
                  onClick={() => { handleVerify() }}

                  style={{ justifyContent: "center", display: "flex", width: "100%", margin: "10px auto 10px 23%", alignItems: "center" }}
                  className={((otp === null || otp === '' || otp?.length < 4) ? "btnstyle1otp" : "btnstyleotp")}
                  shape="round"
                  type="primary"
                  size="large"
                  disabled={
                    disabledBtn || sentOTP ? ((otp === null || otp === '' || otp?.length < 4) ? true : false) : ((mobile == 0 || mobile == '' || mobile?.length < 10) ? true : false)
                  }>
                  Next
                </Button>
              </div></div></div>
        </>
      }
    </>
  );
}

export default Verify;