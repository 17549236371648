import React, { useEffect, useState } from 'react'
import './schedule.less';
import MonthlyCalender from './MonthlyCalender';
import { appointmentListApi } from '../../../api/dashboard';
import moment from "moment";
import dayjs from 'dayjs';
import DatePicker from './DatePicker';
const MySchedule = () => {
 const orientation = {
    WEEKLY: "Weekly",
    MONTHLY: "Monthly",
  };
  const [active, setActive] = useState(orientation.MONTHLY);
  const [appointmentList, setAppointmentList] = useState([]);
  const [selectedDayAppo, setSelectedDayAppo] = useState([]);
  const [dayAppointMents, setDayAppointMents] = useState([]);
  const handleArrow = (value) => {
    const payload = {
      startDate:
        moment(value).startOf("month").format("yyyy-MM-DDT").toString() +
        "00:00:00.000+0000",
      endDate:
        moment(value).endOf("month").format("yyyy-MM-DDT").toString() +
        "00:00:00.000+0000",
    };
    callApi(payload);
  };

  const onSelectDatePicker = async (e,data) => {
    const dayAppointment = data?.filter((listing) => {
      if (new Date(e).getDay() === new Date(listing.jobStartDate).getDay()) {
        return (
          new Date(e).getDay() === new Date(listing.jobStartDate).getDay() &&
          new Date(e).getMonth() === new Date(listing.jobStartDate).getMonth() &&
          new Date(e).getDate() === new Date(listing.jobStartDate).getDate()
        );
      }
    });
    setDayAppointMents(dayAppointment);
  };

  const callApi = async (payload) => {
    const resp = await appointmentListApi(payload.startDate, payload.endDate);
    setAppointmentList(resp?.data?.data);
    onSelectDatePicker(new Date(),resp?.data?.data);
  }
  useEffect(() => {
    const payload = {
      startDate:
        dayjs().startOf("month")
      ,

      endDate:
        dayjs().add(3, "months").endOf("month")
    };
    callApi(payload);
  }, [])
   
  return (
    <>
      <div className="myschedule">
        <div className="mySchedule_heading">My Schedule</div>
        <div className="mySchedule_tab">
          <div
            className={active === orientation.WEEKLY ? "active" : "unused"}
            onClick={() => setActive(orientation.WEEKLY)}
          >
            {orientation.WEEKLY}
          </div>
          <div
            className={active === orientation.MONTHLY ? "active" : "unused"}
            onClick={() => setActive(orientation.MONTHLY)}
          >
            {orientation.MONTHLY}
          </div>
        </div>
        {active === orientation.MONTHLY ? (
          <>
            <MonthlyCalender
              active={active}
              handleArrow={handleArrow}
              appointmentList={appointmentList}
              onSelectDatePicker={onSelectDatePicker}
              setSelectedDayAppo={setSelectedDayAppo}
              selectedDayAppo={selectedDayAppo}
              setDayAppointMents={setDayAppointMents}
            />
          </>
        ) : <div>
          <div className="weekly_schedule_appoinments">
                        <DatePicker  appointments={selectedDayAppo}
                                    onSelectDatePicker={onSelectDatePicker}
                                    appointmentList={appointmentList}
                                    ipad={window.innerWidth}
                                    callApi={callApi}
                                    weekly={true}
                                    appointMentList={appointmentList}
                        />
                    </div>
          </div>}
      </div>
      {dayAppointMents.length > 0 ? <div className={active=="Weekly"?"dayappointmentsweek dayappointments":"dayappointments"}>
        {dayAppointMents.map((item) => {
          return (
            <div className="singleappointment" key={item?.createdBy?.firstName}>
              <div className="daytime d-flex"><div className="greencircle"><div className="whitecircle"></div></div>{item?.jobStartTime}</div>
              <div className="appointment">Appointment With <span style={{ color: "rgba(25, 183, 146, 1)" }}>{item?.createdBy?.firstName} {item?.createdBy?.lastName}</span></div>
            </div>)
        })}
      </div>:<h6 style={{textAlign:"center",margin:"5px"}}>No Appointments</h6>}
    </>
  )
}

export default MySchedule