import React , { useEffect, useState } from 'react';
import Breadcrumbs from '../../../common/BreadCrumbs';
import { pushBreadCrums } from "../../../redux/slices/breadcrum";
import { useDispatch } from 'react-redux';
import { Button, Modal,Spin } from 'antd';
import { addOrRejectExpenseApi } from '../../../api/clientjobs';
import { makeFullPaymentApi } from '../../../api/cardinfoapi';
import crossicon from '../../../asset/images/crossicon.svg';
import CardDetailsInfo from '../profilesetup/CardDetailsInfo';
import CardsList from './CardsList';
import { useNavigate } from 'react-router-dom';
const MakePayment = ({ setMakePayment, breadCrumFunc, item, setExpenseCost, setTotalAmount, expenseCost, totalAmount }) => {
  const [expense, setExpense] = useState(item?.expenses)
  const [expenseNotApp, setExpenseNotApp] = useState([]);
  const [viewExpense, setViewExpense] = useState(false);
  const [currentExp, setCurrentExp] = useState();
  const [loader, setLoader] = useState(false);
  const [addCardModal, setAddCardModal] = useState(false)
  const [selectedCard, setSelectedCard] = useState();
  const [paymentModal, setPaymentModal] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const breadCrumFunc1 = () => {
    setMakePayment(false);
  }
  const handleAck = () => {
    setPaymentModal(false);
    navigate("/jobs", { state: { activeTab: 3 } })
  }
  const makeFullPayment = async () => {
    try {
      setLoading(true)
      const res = await makeFullPaymentApi({ cardId: selectedCard?.id, jobId: item?._id });
      if (res?.status == 200) {
        setIsPaymentSuccess(true);
        setPaymentModal(true);
      }
      else {
        setIsPaymentSuccess(false);
        setPaymentModal(true);
      }
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
    }
  }

  const handleExpense = async (val, expenseId, price) => {
    setViewExpense(false);
    try {
      setLoader(true)
      const resp = await addOrRejectExpenseApi(item?._id, expenseId, val);
      if (resp?.status === 200) {
        const value = expense.map((item) => {
          const value1 = item;
          if (item._id == expenseId) {
            value1.status = val;
          }
          return value1;
        });
        setExpense(value);
        if (val == "Approved") {
          const cost = expenseCost + price;
          setExpenseCost(cost);
          const total = totalAmount + price;
          setTotalAmount(total);

        }
      }
      setLoader(false)
    }
    catch (err) {
      setLoader(false)
    }
  }

  useEffect(() => {
    const val = expense.filter((item) => item.status != "Approved" && item.status != "Rejected")
    setExpenseNotApp(val);
  }, [expense])

  useEffect(() => {
    dispatch(
      pushBreadCrums([{ title: "Jobs", route: "/jobs", breadCrumFunc: breadCrumFunc, tabname: "Jobs", activeTab: 3 }].concat({
        title: "Completed",
        breadCrumFunc: breadCrumFunc1,
        activeTab: 3,
        route: `/jobsdata?id=${item?._id}`,
      }).concat({ title: `${item?.assignedTo?.firstName} ${item?.assignedTo?.lastName}`, route: "" }))
    )
  }, []
  )
  
  return (
    <Spin spinning={loading}>
      <div className="makepaymentpage">
        <Breadcrumbs />
        <h5>Payment</h5>
        <div className="paymentinfo">
          Stripe payment integration in our mobile app provides secure and seamless transactions. Users can securely pay for products/services using various payment methods, ensuring a frictionless checkout experience.
        </div>
        <div className="expensesandcard d-flex">
          <div className="expenseinfo">
            {expense.length > 0 && <div className="my-3 expensename" >Expenses</div>}
            {expense.length > 0 && <div className="allexpenses">
              <Spin spinning={loader}>
                <div className="allexpenses">
                  {expense.map((item) => {
                    return (
                      <div className="expenses mb-2" key={item?._id}>
                        <div className="expense d-flex justify-content-between mr-2">
                          <div className="expenseimg d-flex" >
                            <div className="img mr-2" onClick={() => { if (item?.status == "Pending") { setCurrentExp(item); setViewExpense(true) } }}>
                              <img src={item?.image?.url} style={{ width: "40px", height: "45px", borderRadius: "8px", cursor: "pointer" }} />
                            </div>
                            <div className="expenseinfo d-flex flex-column justify-content-center">
                              <div className="expensename">{item?.name}</div>
                              <div className="expensename">${item?.price}</div>
                            </div>
                          </div>
                          {(item?.status != "Approved" && item?.status != "Rejected") ?
                            <div className="btns d-flex align-items-center ">
                              <Button className="rejected mr-2" onClick={() => { handleExpense("Rejected", item._id, item?.price) }}>Reject</Button>
                              <Button className="expenseapprove" onClick={() => { handleExpense("Approved", item._id, item?.price) }}>
                                Approve
                              </Button>

                            </div> : <div className={item?.status == "Approved" ? "aprrovedtxt d-flex justify-content-center align-items-center" : "rejectedtxt d-flex justify-content-center align-items-center rejectexp"}>{item?.status}</div>}

                        </div>
                      </div>
                    )
                  })}
                </div>
              </Spin>
            </div>}

            <div className="paymentinfo">
              <div className="transactioninfo">
                <div className="transactiondetails d-flex justify-content-between">
                  <div className="trasactionheading">Expenses</div>
                  <div className="trasactionheading1">${Math.abs(expenseCost).toFixed(2)}</div>
                </div>
                <div className="transactiondetails d-flex justify-content-between">
                  <div className="trasactionheading">Contractor Price</div>
                  <div className="trasactionheading1">${Math.abs(item?.contractorFee + item?.convenienceFee).toFixed(2)}</div>
                </div>
                <div className="transactiondetails d-flex justify-content-between">
                  <div className="trasactionheading">Pending Amount</div>
                  <div className="trasactionheading1">${Math.abs(item?.initialPaymentAmount).toFixed(2)}</div>
                </div>
                <div className="transactiondetails d-flex justify-content-between">
                  <div className="trasactionheading">{item?.taxDetails?.type}</div>
                  <div className="trasactionheading1">{item?.taxDetails?.totalTax}%</div>
                </div>
                <div className="totalamount d-flex  justify-content-between">
                  <div className="totalamount1">Total Amout</div>
                  <div className="price">${Math.abs(totalAmount - item?.initialPaymentAmount).toFixed(2)}</div>
                </div>
              </div>
              <Button
                className={expenseNotApp.length == 0 && selectedCard ? "purchasebtn mb-5" : "purchasebtnd mb-5"}
                onClick={() => { makeFullPayment() }}
                disabled={
                  expenseNotApp.length > 0 || !selectedCard}
              >
                Pay Now
              </Button>
            </div>
          </div>
          <div className="cardinfo">
            <CardsList setSelectedCard={setSelectedCard} selectedCard={selectedCard}
              paymentModal={paymentModal} setPaymentModal={setPaymentModal}
              isPaymentSuccess={isPaymentSuccess} handleAck={handleAck} />
          </div>
        </div>
      </div>
      {viewExpense && <Modal
        centered
        header={null}
        footer={null}
        open={viewExpense}
        className="expensemodal"
        closable={false}
      >
        <>
          <h3 className="text-center">Expense</h3>
          <img className="crossimg" src={crossicon} onClick={() => { setViewExpense(false) }} />
          <div className="expenseimg mt-3">
            <img src={currentExp?.image?.url} />
          </div>
          <div className="expensebtns d-flex my-4 justify-content-between">
            <Button className="rejectbtn" onClick={() => { handleExpense("Rejected", currentExp?._id, item?.price) }}>Reject</Button>
            <Button className="acceptbtn" onClick={() => { handleExpense("Approved", currentExp?._id, item?.price) }}>Accept</Button>
          </div>
        </>
      </Modal>}
      {addCardModal && <Modal
        centered
        header={null}
        footer={null}
        open={addCardModal}
        className="addcardmodal"
        closable={false}
      >
        <>
          <div className="crossicon" style={{ right: "0.5rem", cursor: "pointer", position: "absolute" }} onClick={() => { setAddCardModal(false) }}><img src={crossicon} /></div>
          <CardDetailsInfo inJobs={true} setAddCardModal={setAddCardModal} />
        </>
      </Modal>}
    </Spin>
  )
}

export default MakePayment