import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Notifications from '../pages/client/notifications';
import './navbar.less';
const headingContractor = { "/dashboard":"DashBoard","/newjob": "New Jobs", "/settings": "Settings","/contractorjoblist" :"My Jobs","/ratings":"Ratings","/earnings":"Total Earnings","/messages":"Messages"}
const headingClient={"/createjob":"Create","/jobs":"Jobs","/messages":"Messages","/settings":"Settings","/contactus":"Contact Us","jobsdata":"Jobs"};

const Navbar = () => {
  const profileInfo = useSelector((state) => state.login.profileInfo);
  const role = localStorage.getItem("role");
  const location = useLocation();
  
  return (
    role == 0 ?
      <div className="navbar-container d-flex justify-content-between align-items-center">
        <div className='navbar-wrapper'>
          <div className='heading'>{headingClient[location.pathname]?headingClient[location.pathname]:"Jobs"}</div>
        </div>
        <div className="navbar-data d-flex justify-content-between align-items-center ">
          <div className="infodata infodataclient">
            <div className="name text-center">{profileInfo?.firstName?.toString().slice(0, 20)} {profileInfo?.lastName?.toString().slice(0, 20)}</div>
          </div>
          <div className="notificationicon" ><Notifications/></div>
        </div>
      </div> : <div className="navbar-container d-flex justify-content-between align-items-center">
        <div className='navbar-wrapper'>
          <div className='heading'>{headingContractor[location.pathname]?headingContractor[location.pathname]:"Jobs"}</div>
        </div>
        <div className="navbar-data d-flex justify-content-between align-items-center ">
          <div className="infodata">
            <img src={profileInfo?.profileImage} style={{ width: "30px", height: "30px", borderRadius: "50%", marginRight: "5px" }} />
            <div className="name">{profileInfo?.firstName?.toString().slice(0, 15)} {profileInfo?.lastName?.toString().slice(0, 15)}</div>
          </div>
          <div className="notificationicon" > <Notifications/></div>
        </div>
      </div>
  )
}
export default Navbar;