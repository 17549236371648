import React, { useState, useRef, useEffect } from 'react';
import { Form, Input, Button, List } from 'antd';
import { useForm } from 'antd/es/form/Form';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { getAddressObject } from '../../../common/helper';
import './addlocation.less';
import { pushBreadCrums } from '../../../redux/slices/breadcrum';
import { useDispatch } from 'react-redux';
import BreadcrumbItems from '../../../common/BreadCrumbItems';

const AddLocation = ({
  setAddress,
  address,
  saveLocationList,
  isEdit,
  item,
  updateLocation,
  setOpenModal,
  breadCrumFunc,
  fromTab,
}) => {
  const [value, setValue] = useState('');
  const [showList, setShowList] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const formRef = useRef();
  const [form] = useForm();
  const dispatch = useDispatch();
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE,
    // options: {
    //   componentRestrictions: { country: "ca" },
    // },
  });

  const handleFormChange = () => {
    const hasVal = Object.keys(form.getFieldsValue()).filter(
      (key) => key != 'apartmentNumber' && !!form.getFieldValue(key)
    );
    if (hasVal.length === 5) setDisabled(false);
    else setDisabled(true);
  };

  const getDetails = (placeid) => {
    const data = placePredictions.filter((item) => item.place_id == placeid);
    placesService?.getDetails(
      {
        placeId: data[0].place_id,
      },
      (placeDetails) => {
        const addressInfo = getAddressObject(placeDetails?.address_components);
        setValue(placeDetails?.name);
        formRef.current.setFieldValue('address', placeDetails?.name);
        formRef.current.setFieldValue('city', addressInfo?.city);
        formRef.current.setFieldValue('province', addressInfo?.region);
        formRef.current.setFieldValue('postalCode', addressInfo?.postal_code);
        setAddress({
          ...address,
          address: `${placeDetails?.name},${addressInfo?.city},${addressInfo?.region},${addressInfo?.postal_code}`,
          city: addressInfo?.city,
          province: addressInfo?.region,
          postalCode: addressInfo?.postal_code,
          location: {
            lat: placeDetails.geometry.location.lat().toString(),
            long: placeDetails.geometry.location.lng().toString(),
          },
        });
        handleFormChange();
        setShowList(false);
      }
    );
  };

  useEffect(() => {
    if (!value && fromTab != 'settings') setAddress({ name: address?.name, apartmentNumber: address?.apartmentNumber });
  }, [value]);

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue(address);
      setValue(address?.address)
      if (fromTab != 'settings')
        dispatch(
          pushBreadCrums(
            BreadcrumbItems.Settings.concat({ title: 'My Locations', path: '/', breadCrumFunc: breadCrumFunc }).concat({
              title: 'Edit Locations',
              path: '/',
            })
          )
        );
    } else {
      dispatch(
        pushBreadCrums(
          BreadcrumbItems.Settings.concat({
            title: 'My Locations',
            route: '/settings',
            breadCrumFunc: breadCrumFunc,
          }).concat({ title: 'Add Location', route: '/settings' })
        )
      );
    }
  }, [address?.city]);

  const updateAddress = (key, e) => {
    setAddress({ ...address, [key]: e.target.value });
  };

  return (
    <>
      <Form
        initialValues={{ remember: true }}
        ref={formRef}
        autoComplete="off"
        layout="vertical"
        form={form}
        name="locationform"
        onFieldsChange={handleFormChange}
      >
        {fromTab != 'settings' && (
          <div className="subform">
            <div className="subform1">
              <Form.Item
                name="name"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid name',
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Name"
                  className={form.getFieldValue('name') ? 'input-green' : 'input-field'}
                  onChange={(e) => updateAddress('name', e)}
                />
              </Form.Item>
            </div>
            <div className="subform2">
              <Form.Item name="apartmentNumber" className="mb-3">
                <Input
                  size="large"
                  placeholder="Apartment Number"
                  className={form.getFieldValue('apartmentNumber') ? 'input-green' : 'input-field'}
                  onChange={(e) => updateAddress('apartmentNumber', e)}
                />
              </Form.Item>
            </div>
          </div>
        )}
        <div className="subform">
          <div className="subform1">
            <Form.Item
              name="address"
              type="text"
              className="mb-3"
              rules={[
                {
                  required: true,
                  message: 'Please enter your address!',
                },
              ]}
            >
              <Input
                size="large"
                style={{ color: 'black', height: '50px' }}
                className={value || form.getFieldValue('address') ? 'input-green' : 'input-field'}
                value={value}
                autoFocus={value?.length > 0 ? true : false}
                onChange={(evt) => {
                  setValue(evt.target.value);
                  formRef.current.setFieldValue('address', evt.target.value);
                  getPlacePredictions({ input: evt.target.value });
                  if (!isPlacePredictionsLoading) setShowList(true);
                }}
                onKeyDown={(event) => {
                  if (event?.keyCode === 8 || event?.keyCode === 46) {
                    form.setFieldValue('city', '');
                    form.setFieldValue('province', '');
                    form.setFieldValue('postalCode', '');
                  }
                }}
                loading={false}
                placeholder="Enter address"
              />
              {value !== '' && showList && placePredictions.length > 0 && (
                <div className="google-autosuggestion-list">
                  <List
                    dataSource={placePredictions}
                    renderItem={(item) => (
                      <List.Item onClick={() => getDetails(item?.place_id)}>
                        <List.Item.Meta title={item.description} />
                      </List.Item>
                    )}
                  />
                </div>
              )}
            </Form.Item>
          </div>
          <div className="subform2">
            <Form.Item
              name="city"
              className="mb-3"
              rules={[
                {
                  type: 'address',
                  message: 'Please enter a valid city',
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter city"
                className={form.getFieldValue('city') ? 'input-green' : 'input-field'}
                onChange={(e) => updateAddress('city', e)}
              />
            </Form.Item>
          </div>
        </div>
        <div className="subform">
          <div className="subform1">
            <Form.Item
              name="province"
              className="mb-3"
              rules={[
                {
                  type: 'address',
                  message: 'Please enter a valid Province',
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter province"
                className={form.getFieldValue('province') ? 'input-green' : 'input-field'}
                onChange={(e) => updateAddress('province', e)}
              />
            </Form.Item>
          </div>
          <div className="subform2">
            <Form.Item
              name="postalCode"
              className="mb-3"
              rules={[
                {
                  type: 'postal',
                  message: 'Please enter a valid postal code',
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter postal code"
                className={form.getFieldValue('postalCode') ? 'input-green' : 'input-field'}
                onChange={(e) => updateAddress('postalCode', e)}
              />
            </Form.Item>
          </div>
        </div>
        {!isEdit && fromTab != 'settings' ? (
          <Button
            // htmlType="submit"
            style={{ height: '50px', marginTop: '1%', width: '40%' }}
            className={disabled ? 'btnenabled' : 'btnenabled1'}
            disabled={disabled}
            type="primary"
            size="large"
            onClick={() => {
              saveLocationList(address);
            }}
          >
            Save
          </Button>
        ) : (
          fromTab != 'settings' && (
            <div className="d-flex mt-3">
              <Button
                className=""
                style={{
                  width: '18%',
                  height: '50px',
                  marginRight: '10px',
                  color: '#20222A',
                  fontWeight: '500',
                  background: '#F6F6FB',
                  borderRadius: '12px',
                  border: 'transparent',
                }}
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                Delete
              </Button>
              <Button
                className={disabled ? 'btnenabled' : 'btnenabled1'}
                style={{
                  width: '20%',
                  height: '50px',
                  marginRight: '10px',
                  color: '#20222A',
                  fontWeight: '500',
                  background: '#0ff4bd',
                  borderRadius: '12px',
                  border: 'transparent',
                }}
                disabled={disabled}
                onClick={() => {
                  updateLocation(item?._id);
                }}
              >
                Save
              </Button>
            </div>
          )
        )}
      </Form>
    </>
  );
};
export default AddLocation;
