import React,{ useState } from 'react';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import './settings.less'
import { message } from 'antd';
import { changePasswordApi } from '../../../api/detailsapi';

const ChangePassword = () => {
  const [disabled, setDisabled] = useState(true)
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    const body = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };
    const response = await changePasswordApi(body);
    if (response?.status === 200) {
      message.success("Your Password has been updated successfully.");
      form.resetFields();
      setDisabled(true)
    }
  };
  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabled(hasErrors);

  };
  return (
    <div className="settingcard">
      <Form
        name="changepwdform"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        // validateTrigger='onSubmit'
        onFieldsChange={handleFormChange}
        form={form}
      >
        <Form.Item
          name={"oldPassword"}
          rules={[{ required: true, message: "Please enter the Old Password" }
          ]}
        >
          <Input.Password size="large" placeholder="Enter Old Password" style={{ width: "100%", height: "50px" }}></Input.Password>
        </Form.Item>
        <Form.Item
          name={"newPassword"}
          dependencies={['oldPassword']}
          rules={[{ required: true, message: "Please enter the New Password" },
          ({ getFieldValue }) => ({
            validator(_, value) {
              const isContainsUppercase = /^(?=.*[A-Z]).*$/;
              const isContainsLowercase = /^(?=.*[a-z]).*$/;
              const isContainsNumber = /^(?=.*[0-9]).*$/;
              const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}|\\:;"'<>,.?/_₹]).*$/;

              if (value?.length < 8) {
                return Promise.reject(new Error('Must contain minimum of 8 characters.'));
              }
              if (!isContainsNumber.test(value) || !isContainsLowercase.test(value) || !isContainsSymbol.test(value) || !isContainsUppercase.test(value)) {
                return Promise.reject(new Error('Atleast one uppercase, lowercase, number(0-9), special character.'));
              }
              if (!value || getFieldValue('oldPassword') === value) {
                return Promise.reject(new Error('Please ensure the new password should not be same as old password. '));
              }
              return Promise.resolve();
            },
          })]}
        >
          <Input.Password size="large" placeholder="Enter New Password"
            style={{ width: "100%", height: "50px" }}
          >
          </Input.Password>
        </Form.Item>
        <Form.Item
          name={"confirmPassword"}
          dependencies={['newPassword']}
          rules={[{ required: true, message: "Please confirm the Password" },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Please ensure old password matches with new password. '));
            },
          }),
          ]}
        >
          <Input.Password size="large" placeholder="Confirm New Password" style={{ width: "100%", height: "50px" }}></Input.Password>
        </Form.Item>

        <Form.Item className="mb-0">
          <Button
            htmlType="submit"
            className={!disabled ? "save_btn" : "save_btn_disabled"}
            type="primary"
            size="large"
            disabled={disabled}
          >
            Save
          </Button>

        </Form.Item>
      </Form>
    </div>
  )
}
export default ChangePassword;