import React, { useState ,useEffect} from 'react';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import Button from 'antd/es/button';
import resetpassword from '../../asset/images/resetpassword.png';
import emailIcon from '../../asset/images/emailIcon.svg';
import './Reset.less';
import { forgetPass } from '../../api/detailsapi';
import { message } from 'antd';
import { useLocation,useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const [email, setEmail] = useState(false);
  const [, setEmailError] = useState(false);
  const [emailVal, setEmailVal] = useState();
  const { state } = useLocation();
  const navigate = useNavigate();
  const messageIcon = (
    <div className="signup_input_icon">
      <img src={emailIcon} />
    </div>
  );
  const emailValidation = (value) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    let isValidEmail = emailRegex.test(value);

    setEmail(isValidEmail);
    setEmailError(!isValidEmail);
    if (value.length == 0) {
      setEmailError(true);
    }
  };
  const onFinish = (values) => {
    const body = {
      email: values.email,
      userType: localStorage.getItem("role")
    };
    doResetPassword(body);
  };
  const doResetPassword = async (values) => {
      const response = await forgetPass(values);
      message.success(response.data.message);
      navigate('/resetemail', { state: { val: emailVal } });
  };
  useEffect(() => {
    if (state?.val) {
      setEmailVal(state.val);
      setEmail(true);
     // form.setFeild
    }
  }, [])
  return (
    <div className="main_container">
      <div className="center_container">
        <div className="num_container ">
          <img src={resetpassword} />
          <div className="req_header">
            <h1>Reset your Password</h1>
            <div className="para_container_reset">
              <p>Enter the email address associated with your account and we will send you the new password.</p>
            </div>
          </div>{' '}
          <div className="signup_end">
            <div className="form_data">
              <Form name="normal_login" className="login-form" initialValues={{ remember: true, email:state?.val }} onFinish={onFinish}>
                <Form.Item name="email" rules={[{ required: true, message: 'Please enter your E-mail' }]}>
                  <Input
                    prefix={messageIcon}
                    size="large"
                    placeholder="Enter Email"
                    onChange={(e) => {
                      emailValidation(e.target.value);
                      setEmailVal(e.target.value);

                    }}
                    value={emailVal}
                    style={{ height: "55px" }}
                    // className={email ? 'validated_input_field' : emailError ? 'invalid_input_field' : 'input_field'}
                    className={email ? 'validated_input_field1' : 'input_field'}
                  />
                </Form.Item>

                <Form.Item name="reset_btn">
                  <Button
                    htmlType="submit"
                    disabled={email ? false : true}
                    className={email ? 'login_form_button' : 'login_form_button_disabled'}
                    style={{ marginTop: '0.43rem' }}
                  >
                    Reset Password
                  </Button>
                </Form.Item>
              </Form>

              <div className="sign_in_text">
                Back to
                <a className="text-dark fw-bold" style={{ textDecoration: 'none' }}>
                  <span
                    style={{
                      color: '#000',
                      fontFamily: 'Poppins',
                      fontSize: '1.125rem',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: '2.5rem',
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      if (localStorage.getItem("role") == 0)
                        navigate('/clientsignin');
                      else
                        navigate('/contractorsignin')
                    }}
                  >
                    Sign In
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
