import { Breadcrumb } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { popBreadCrum } from "../redux/slices/breadcrum";
 import './styles/BreadCrumbs.less';



function Breadcrumbs() {
  const navigate = useNavigate();
  const breadCrums = useSelector((state) => state.breadCrumbs);
  const dispatch=useDispatch();
  const handleNavigetor = (data) => {
      navigate(data.route,{state:{activeTab:data?.activeTab}});
      if(data.title=="My Locations"|| data.title==="Job Details" || data.title=="Completed" )
      {
        data?.breadCrumFunc();
        dispatch(popBreadCrum())
      }
  };
  return (
    <Breadcrumb  separator=">">
          {breadCrums &&
            breadCrums?.map((item) => {
                return (
                  <Breadcrumb.Item key={item?.title} onClick={() => handleNavigetor(item)}>
                    {item?.title}
                  </Breadcrumb.Item>
                );
            })}
    </Breadcrumb>
  );
}

export default Breadcrumbs;
