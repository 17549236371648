import React, { useState, useEffect } from 'react'
import BankInformation from './BankInformation'
import ChangePassword from './ChangePassword'
import EditProfile from './EditProfile'
import EditProfileContractor from './EditProfileContractor'
import Mylocations from './Mylocations'
import PaymentInfo from './PaymentInfo'
import editsettings from '../../../asset/images/editsettings.svg'
import locationsettings from '../../../asset/images/locationsettings.svg'
import locksettings from '../../../asset/images/locksettings.svg'
import walletsettings from '../../../asset/images/walletsettings.svg'
import banksettings from '../../../asset/images/banksettings.svg'
import DashBoardWrapper from '../../../components/wrapper/DashBoardWrapper'
import deleteaccicon from '../../../asset/images/deleteaccicon.svg'
import deletepopup from '../../../asset/images/deletepopup.svg'
import Tabs from "antd/es/tabs";
import { Modal, Button, Input, message } from 'antd'
import { useDispatch } from 'react-redux'
import BreadCrumbs from '../../../common/BreadCrumbs'
import './settings.less';
import { pushBreadCrums } from '../../../redux/slices/breadcrum'
import BreadcrumbItems from '../../../common/BreadCrumbItems'
import lockIcon from '../../../asset/images/lockIcon.svg'
import { deleteAccount } from '../../../api/deleteaccountapi'


const breadcrumbItemId = {
  '1': {
    title: "Edit Profile",
    route: ""
  },
  '2': {
    title: "My Locations",
    route: ''
  },
  '3': {
    title: "Change Password",
    route: ''
  },
  '4': {
    title: "Payment Information",
    route: ''
  },

}
const breadcrumbItemIdCo = {
  '1': {
    title: "Edit Profile",
    route: ""
  },
  '2': {
    title: "Change Password",
    route: ''
  },
  '3': {
    title: "Banking Information",
    route: ''
  },

}
const passwordIcon = (
  <div className="signup_input_icon">
    <img src={lockIcon} />
  </div>
);
const Settings = () => {
  const [tabChange, setTabChange] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState();
  const dispatch = useDispatch();

  const tabClick = (key, name) => {
    if (key == "4" && role == "1" || key == "5" && role == "0")
      setDeleteModal(true)
    else {
      setTabChange(!tabChange);
      setTabValue(key.toString())
      if (localStorage.getItem == 0) {
        dispatch(
          pushBreadCrums(BreadcrumbItems.Settings.concat(breadcrumbItemId[key]))
        );
      }
      else {
        dispatch(
          pushBreadCrums(BreadcrumbItems.Settings.concat(breadcrumbItemIdCo[key]))
        );
      }
    }
  }

  const handleBreadCrum = () => {
    if (localStorage.getItem("role") == "0")
      dispatch(pushBreadCrums(BreadcrumbItems.Settings.concat(breadcrumbItemId[tabValue])))
    else
      dispatch(pushBreadCrums(BreadcrumbItems.Settings.concat(breadcrumbItemIdCo[tabValue])))
  }
  useEffect(() => {
    handleBreadCrum();

  }, [tabValue])
  const handleYes = async () => {
    setDeleteModal(false);
    const resp = await deleteAccount(password);
    if (resp?.status == 200) {
      message.success("Account Deleted Successfully")
      setTimeout(() => {
        localStorage.clear();
        window.location.reload();
      }, 1000)
    }
  }
  const handleNo = () => {
    setDeleteModal(false);
    setPassword();
  }
  const role = localStorage.getItem("role")
  return (
    <DashBoardWrapper>
      <BreadCrumbs />
      <div className="settings" >
        {role == 1 ? <Tabs activeKey={tabValue} tabPosition="left" size="large" className="settingstab" destroyInactiveTabPane={true} onChange={tabClick} >
          <Tabs.TabPane
            className="tab"
            key="1"
            tab={
              <>
                <span className="imageicons">
                  <img src={editsettings} />
                </span>
                <span className="settingnames">
                  Edit Profile
                </span>
              </>
            }
          >
            <EditProfileContractor loading={loading} setLoading={setLoading} />
          </Tabs.TabPane>
          <Tabs.TabPane
            className="tab"
            key="2"
            tab={
              <>
                <span className="imageicons">
                  <img src={locksettings} />
                </span>
                <span className="settingnames">
                  Change Password
                </span>
              </>
            }
          >
            <ChangePassword />
          </Tabs.TabPane>
          <Tabs.TabPane
            className="tab"
            key="3"
            tab={
              <>
                <span className="imageicons">
                  <img src={banksettings} />
                </span>
                <span className="settingnames">
                  Bank Information
                </span>
              </>
            }
          >
            <BankInformation />
          </Tabs.TabPane>
          <Tabs.TabPane
            className="tab"
            key="4"
            tab={
              <>
                <span className="imageicons">
                  <img src={deleteaccicon} />
                </span>
                <span className="settingnames">
                  Delete Account
                </span>
              </>
            }
          >
          </Tabs.TabPane>
        </Tabs> :
          <Tabs activeKey={tabValue} tabPosition="left" size="large" className="settingstab" destroyInactiveTabPane={true} onChange={tabClick} >
            <Tabs.TabPane
              className="tab"
              key="1"
              tab={
                <>
                  <span className="imageicons">
                    <img src={editsettings} />
                  </span>
                  <span className="settingnames">
                    Edit Profile
                  </span>
                </>
              }
            >
              <EditProfile setLoading={setLoading} loading={loading} />
            </Tabs.TabPane>
            <Tabs.TabPane
              className="tab"
              key="2"
              tab={
                <>
                  <span className="imageicons">
                    <img src={locationsettings} />
                  </span>
                  <span className="settingnames">
                    My Locations
                  </span>
                </>
              }
            >
              <Mylocations tabChange={tabChange} tabValue={tabValue} setLoading={setLoading} loading={loading} />
            </Tabs.TabPane>

            <Tabs.TabPane
              className="tab"
              key="3"
              tab={
                <>
                  <span className="imageicons">
                    <img src={locksettings} />
                  </span>
                  <span className="settingnames">
                    Change Password
                  </span>
                </>
              }
            >
              <ChangePassword tabChange={tabChange} tabValue={tabValue} />
            </Tabs.TabPane>
            <Tabs.TabPane
              className="tab"
              key="4"
              tab={
                <>
                  <span className="imageicons">
                    <img src={walletsettings} />
                  </span>
                  <span className="settingnames">
                    Payment Information
                  </span>
                </>
              }
            >
              <PaymentInfo loading={loading} setLoading={setLoading} />
            </Tabs.TabPane>
            <Tabs.TabPane
              className="tab"
              key="5"
              tab={
                <>
                  <span className="imageicons">
                    <img src={deleteaccicon} />
                  </span>
                  <span className="settingnames">
                    Delete Account
                  </span>
                </>
              }
            >
            </Tabs.TabPane>
          </Tabs>
        }
      </div>
      <Modal
        centered
        width={390}
        header={null}
        footer={null}
        open={deleteModal}
        className="logoutmodal"
        closable={false}
      >
        <img className="m-auto d-flex text-center" src={deletepopup} />
        <div
          className="logoutdesc">
          Are you sure you want to  delete your account?
        </div>
        <Input.Password
          prefix={passwordIcon}
          size="large"
          placeholder="Enter Password"
          onChange={(e) => {
            setPassword(e.target.value)
          }}
          value={password}
          style={{ height: "50px", margin: "1rem 0px" }}
          className={
            password ? 'validated_input_field' : 'input_field'
          }
        />
        <div
          className="confirmButtons"
          style={{ justifyContent: "space-between", display: "flex", height: "50px", marginTop: "10px" }}
        ><Button
          className={password?.length > 0 ? "yesbtn" : "yesbtn yesbtn1"}
          onClick={handleYes}
        >
            Yes
          </Button>
          <Button
            className="nobtn"
            onClick={handleNo}
          >
            No
          </Button>
        </div>
      </Modal>
    </DashBoardWrapper>
  )
}
export default Settings;