import { useState, useEffect } from 'react';
import { List, Radio, Modal, DatePicker, Switch, Input, Button } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import PostJobPopup from '../pages/client/createjob/PostJobPopup';
import './createjob.less';
import TimeModal from './TimeModal';
import UpdateImage from './UpdateImage';
import disabledradiobtn from '../asset/images/disabledradiobtn.svg';
import enabledradiobtn from '../asset/images/enabledradiobtn.svg';
import installimg from '../asset/images/installimg.svg';
import installimg1 from '../asset/images/installimg1.svg';
import ladder from '../asset/images/ladder.svg';
import ladder1 from '../asset/images/ladder1.svg';
import lift1 from '../asset/images/lift.svg';
import lift2 from '../asset/images/lift1.svg';
import lift3 from '../asset/images/lift2.svg';
import lift4 from '../asset/images/lift3.svg';
import locationicon from '../asset/images/Location.svg';
import pdfimage from '../asset/images/pdfimage.svg';
import plusicon from '../asset/images/plusicon.svg';
import sitecheckimg from '../asset/images/sitecheckimg.svg';
import smallladder from '../asset/images/smallladder.svg';
import timecircle from '../asset/images/Time-Circle.svg';
import tick from '../asset/images/whitegreentick.svg';
import { getAddressObject } from '../common/helper';
import { setCreateObject } from '../redux/slices/createinfo';

import { locationListApi, getJobCost } from '../api/createjob';
import { categoryList, subCategoryList, uploadImageApi } from '../api/detailsapi';

const data = [
  { title: 'Install', img: installimg1, id: 'IN' },
  { title: 'Removal', img: installimg, id: 'RM' },
  { title: 'Site Check', img: sitecheckimg, id: 'SC' },
];
const datasite = [{ name: 'Interior' }, { name: 'Exterior' }];
const CreateJob = ({
  inDashboard,
  setIsValid,
  setJobObj,
  setOpenLocationModal,
  address,
  flag,
  setFlag,
  jobObj,
  clear,
  setClear,
  singleJobInfo,
  status,
  locations,
  setLocations,
}) => {
  const [serviceId, setServiceId] = useState({});
  const [installId, setInstallId] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState([]);
  const [subCategory1, setSubCategory1] = useState([]);
  const [value, setValue] = useState('');
  const [showList, setShowList] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [timeModal, setTimeModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [time, setTime] = useState();
  const [images, setImages] = useState([]);
  const [singleImg, setSingleImg] = useState(null);
  const [locationId, setLocationId] = useState();
  const [siteRequired, setSiteRequired] = useState();
  const [createObj, setCreateObj] = useState({ isLiftRequired: 'no' });
  const [postJobModal, setPostJobModal] = useState(false);
  const [approxCost, setApproxCost] = useState(1);
  const[approxCostAlertText,setApproxCostAlertText]=useState();
  const [hours, setHours] = useState();
  const [imageView, setImageView] = useState(false);
  const [showChange, setShowChange] = useState(false);
  const objInfo = useSelector((state) => state?.createinfo?.createObj);
  const dateFormat = 'MMMM D,YYYY';
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const callCategoryApi = async (val) => {
    const resp = await categoryList(val);
    setSubCategory(resp?.data?.data);
  };
  const checkInput = (inputElement, val) => {
    if (Number(inputElement.target.value) > 0) {
      if (val == 'width') setCreateObj({ ...createObj, width: inputElement.target.value });
      if (val == 'height') setCreateObj({ ...createObj, height: inputElement.target.value });
      if (val == 'quantity') setCreateObj({ ...createObj, quantity: inputElement.target.value });
    } else {
      if (val == 'width') setCreateObj({ ...createObj, width: '' });
      else if (val == 'height') setCreateObj({ ...createObj, height: '' });
      else if (val == 'quantity') setCreateObj({ ...createObj, quantity: '' });
    }
  };
  const callSubCategoryApi = async (val) => {
    setSubCategory1([]);
    if (category != 'SC') {
      const resp = await subCategoryList(val);
      setSubCategory1(resp?.data?.data);
    }
  };
  useEffect(() => {
    if (flag) {
      setFlag(false);
      setLocationId();
    }
  }, [flag]);

  useEffect(() => {
    if (clear) {
      setCategory();
      setValue();
      handleClear();
      setClear(false);
    }
  }, [clear]);
  useEffect(() => {
    if (singleJobInfo?._id) {
      setValue(singleJobInfo?.city);
      setCreateObj({
        isLiftRequired: singleJobInfo?.isLiftRequired,
        city: singleJobInfo?.city,
        width: singleJobInfo?.width,
        height: singleJobInfo?.height,
        quantity: singleJobInfo?.quantity,
        liftSize: singleJobInfo?.liftSize,
        province: singleJobInfo?.province,
        additionalNotes: singleJobInfo?.additionalNotes,
        jobStartDate: singleJobInfo?.jobStartDate,
      });
      setCategory(singleJobInfo?.categoryId?.parentCategory[0]);
      callCategoryApi(singleJobInfo?.categoryId?.parentCategory[0]);
      setServiceId({ id: singleJobInfo?.categoryId?._id, nextCatName: singleJobInfo?.categoryId?.nextCatTitle });
      callSubCategoryApi(singleJobInfo?.categoryId?._id);
      setInstallId(singleJobInfo?.subCategoryId?._id);
      setLocationId(singleJobInfo?.locationId?._id);
      setImages(singleJobInfo?.attachedFiles);
      setTime(singleJobInfo?.jobStartTime);
      setSiteRequired(singleJobInfo?.type);
      const date1 = new Date();
      if (singleJobInfo?.jobStartDate == moment(date1).format(dateFormat)) setHours(date1.getHours());
      if (singleJobInfo?.isLiftRequired) setChecked(true);
    } else if (objInfo != null && pathname != '/') {
      setCreateObj(objInfo?.createObj?.createObj);
      setCategory(objInfo?.category);
      callCategoryApi(objInfo?.category);
      setInstallId(objInfo?.installId);
      setTime(objInfo?.time);
      setServiceId({ id: objInfo?.serviceId?.id, nextCatName: objInfo?.serviceId?.nextCatName });
      callSubCategoryApi(objInfo?.serviceId?.id);
      setSiteRequired(objInfo?.siteRequired);
      setValue(objInfo?.createObj?.createObj?.city);
      //dispatch(setCreateObject({}));
      setLocationId(objInfo?.locationId);
      if (objInfo?.images) setImages(objInfo?.images);
    }
  }, [flag]);

  useEffect(() => {
    if (showChange && !singleJobInfo?._id)
      dispatch(
        setCreateObject({
          category,
          serviceId,
          installId,
          createObj: { createObj },
          time: time,
          siteRequired,
          locationId,
          ...(images ? { images } : {}),
        })
      );
    setShowChange(true);
  }, [category, serviceId, installId, createObj, time, siteRequired, locationId, images]);

  const handleClear = (item, val) => {
    setCreateObj({
      isLiftRequired: 'no',
      jobStartDate: '',
      city: createObj?.city,
      province: createObj?.province,
      location: { lat: createObj?.location?.lat, long: createObj?.location?.long },
      type: siteRequired,
      parentCategory: item?.id,
    });
    setTime('');
    if (val != 2 && val != 3) {
      setSubCategory([]);
      setServiceId({});
    }
    if (val != 3) {
      setSubCategory1([]);
      setInstallId();
    }
    setSiteRequired();
    setLocationId();
    setChecked(false);
    setImages([]);
  };

  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE,
    // options: {
    //   componentRestrictions: { country: "ca" },
    // },
  });

  const callLocationApi = async () => {
    const resp = await locationListApi();
    const response = resp?.data?.data;
    //setLocations([...response]);
  };

  const handleChange = (date, dateString) => {
    const date1 = new Date();
    setTime();
    setHours();
    if (dateString == moment(date1).format(dateFormat)) {
      setHours(date1.getHours());
    }
    setCreateObj({ ...createObj, jobStartDate: dateString });
    setTimeModal(false);
  };

  const handleDelete = (val1) => {
    const val = images.filter((item) => item.key != val1.key);
    setImages(val);
    setImageView(false);
  };

  const handleViewImg = (item, ind) => {
    setSingleImg({ ...item, index: ind });
    setImageView(true);
  };

  const getDetails = (placeid) => {
    const data = placePredictions.filter((item) => item.place_id == placeid);
    placesService?.getDetails(
      {
        placeId: data[0].place_id,
      },
      (placeDetails) => {
        const address = getAddressObject(placeDetails?.address_components);
        setValue(`${address?.city} `);
        setCreateObj({
          ...createObj,
          city: `${address?.city}`,
          province: address?.region,
          location: {
            lat: placeDetails.geometry.location.lat().toString(),
            long: placeDetails.geometry.location.lng().toString(),
          },
        });
        setShowList(false);
      }
    );
  };

  const handleFileChange = async (e, ind) => {
    const file = e.target.files[0];
    const type1 = file.type;
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {};
      reader.readAsDataURL(file);
    }
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0]) {
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        const response = await uploadImageApi(formData);
        if (response.type === 'success') {
          if (ind == 1) setImages([...images, { url: response.data.fileUrl, key: response.data.key, type: type1 }]);
          else {
            setSingleImg({ ...singleImg, url: response.data.fileUrl, key: response.data.key, type: type1 });
            setImageView(false);
            const val = images?.map((item, index) => {
              if (index == singleImg.index) return { url: response.data.fileUrl, key: response.data.key, type: type1 };
              else return item;
            });
            setImages(val);
          }
        }
      }
    }
  };

  const handlePost = async () => {
    dispatch(
      setCreateObject({
        category: category,
        serviceId: serviceId,
        installId: installId,
        createObj: { createObj },
        time: time,
        siteRequired: siteRequired,
      })
    );
    const resp = await getJobCost({ ...createObj, categoryId: serviceId.id, subCategoryId: installId,parentCategory:category });
    if (resp?.status == 200) {
      setApproxCost(resp?.data?.data?.totalAmount);
      setApproxCostAlertText(resp?.data?.data?.approxCostAlertText);
    }
    setPostJobModal(true);
  };

  useEffect(() => {
    if (category != 'SC' && serviceId?.nextCatName != 'Type of Vehicle Wraps') {
      if (
        serviceId.id != undefined &&
        installId != undefined &&
        createObj?.jobStartDate &&
        createObj?.width &&
        createObj?.height &&
        createObj?.quantity &&
        time
      )
        setDisabled(false);
      else setDisabled(true);
    } else  if (category == 'SC') {
      if (serviceId.id != undefined && createObj?.jobStartDate && time) setDisabled(false);
      else setDisabled(true);
    }
    else
    {
     if (
        serviceId.id != undefined &&
        installId != undefined)
         setDisabled(false);
         else
         setDisabled(true);

    }
  }, [serviceId.id, installId, value, createObj, time, locationId]);

  useEffect(() => {
    if (inDashboard) {
      if (category != 'SC' && serviceId?.nextCatName != 'Type of Vehicle Wraps') {
        if (
          createObj?.jobStartDate &&
          createObj?.width &&
          createObj?.height &&
          createObj?.quantity &&
          time &&
          serviceId.id &&
          installId &&
          createObj?.city &&
          locationId &&
          ((createObj?.liftSize && checked) || !checked)
        ) {
          setIsValid(true);
          const val = createObj;
          const newval = {
            ...val,
            attachedFiles: [...images],
            categoryId: serviceId.id,
            subCategoryId: installId,
            locationId: locationId,
            jobStartTime: time,
            parentCategory:category
          };
          setJobObj(newval);
        } else {
          setIsValid(false);
        }
      } else if (category == 'SC') {
        if (
          createObj?.jobStartDate &&
          time &&
          serviceId.id &&
          createObj?.city &&
          siteRequired &&
          locationId &&
          ((createObj?.liftSize && checked) || !checked)
        ) {
          setIsValid(true);
          const val = createObj;
          const newval = {
            ...val,
            attachedFiles: [...images],
            categoryId: serviceId.id,
            locationId: locationId,
            jobStartTime: time,
            type: siteRequired,
            parentCategory:category
          };
          setJobObj(newval);
        } else {
          setIsValid(false);
        }
      }
      else{
          if (serviceId?.id && locationId && installId && images?.length > 0 && createObj?.additionalNotes) {
            const val = createObj;
            const newval = {
              ...val,
              attachedFiles: [...images],
              categoryId: serviceId.id,
              nextCatName: serviceId.nextCatName,
              locationId: locationId,
              jobStartTime: time,
              type: siteRequired,
              subCategoryId: installId,
              parentCategory:category
            };
            setJobObj(newval);
            setIsValid(true);
          } else setIsValid(false);
        
      }
    }
  }, [createObj, locationId, time, siteRequired,images]);

  useEffect(() => {
    if (inDashboard) callLocationApi();
  }, []);

  return (
    <>
      <div
        className={
          inDashboard ? 'createjob' : 'createjob d-flex  flex-column justify-content-center align-items-center'
        }
      >
        <div className={inDashboard ? 'createjobinfo w-100' : 'createjobinfo  '}>
          <div
            className={
              singleJobInfo?._id && status != 'Pending'
                ? 'locationinput locationinputdisable m-1'
                : 'locationinput m-1 '
            }
          >
            <div className="inputheading mb-2 surfaceName1">Install Location</div>
            <Input
              prefix={<img src={locationicon} />}
              size="large"
              value={value}
              className={value ? 'inputfeildcolor' : ''}
              // autoFocus
              autoFocus={value?.length > 0 ? true : false}
              disabled={locationId && inDashboard ? true : false}
              onChange={(evt) => {
                setValue(evt.target.value);
                getPlacePredictions({ input: evt.target.value });
                if (!isPlacePredictionsLoading) setShowList(true);
              }}
              onKeyDown={(event) => {
                if (event?.keyCode === 8 || event?.keyCode === 46) {
                  setValue('');
                }
              }}
              placeholder="Enter city or postal zip code"
            />
            {value !== '' && showList && placePredictions.length > 0 && (
              <div className="google-autosuggestion-list">
                <List
                  dataSource={placePredictions}
                  renderItem={(item) => (
                    <List.Item onClick={() => getDetails(item?.place_id)}>
                      <List.Item.Meta title={item.description} />
                    </List.Item>
                  )}
                />
              </div>
            )}
          </div>
          <div className={singleJobInfo?._id ? 'jobtype jobtypedisable' : 'jobtype'}>
            {data?.map((item) => {
              return (
                <div
                  key={item?.id}
                  className={item.id == category ? 'jobactive  pb-2' : 'job  pb-2'}
                  onClick={() => {
                    setCategory(item.id);
                    callCategoryApi(item.id);
                    handleClear(item, 1);
                  }}
                >
                  <div className="jobimg px-2 pb-1 pt-3">
                    <img
                      src={item.img}
                      style={{
                        height: '100%',
                        width: '93%',
                        margin: 'auto',
                        display: 'flex',
                        objectFit: 'cover',
                        borderRadius: '20px',
                      }}
                    />
                  </div>
                  <div className="jobtitle  m-auto text-center mb-3">{item.title}</div>
                </div>
              );
            })}
          </div>
          {subCategory?.length > 0 && (
            <div className={singleJobInfo?._id && status != 'Pending' ? 'services locationinputdisable' : 'services'}>
              <div className="servicesheading surfaceName1">
                {category != 'SC' ? ' Which Service do you require' : 'What can we measure for you ?'}
                <span style={{ color: 'red' }}>*</span>
              </div>
              <div className="servicearray">
                {subCategory?.map((item) => {
                  return (
                    <div
                      key={item?._id}
                      className="servicesinfo"
                      onClick={() => {
                        setServiceId({ id: item._id, nextCatName: item.nextCatTitle });
                        handleClear(item, 2);
                        callSubCategoryApi(item._id);
                      }}
                    >
                      <img className={item._id == serviceId.id ? 'tickactive' : 'tick'} src={tick} />
                      <div className={serviceId.id == item._id ? 'serviceimgactive' : 'serviceimg'}>
                        <img src={item.categoryImage.url} />
                      </div>

                      <div className="servicetitle text-center">{item.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {subCategory1?.length > 0 && (
            <div className={singleJobInfo?._id && status != 'Pending' ? 'services locationinputdisable' : 'services'}>
              <div className="servicesheading surfaceName1">
                {serviceId?.nextCatName}
                <span style={{ color: 'red' }}>*</span>
              </div>
              <div className="servicearray">
                {subCategory1?.map((item) => {
                  return (
                    <div
                      key={item?._id}
                      className="servicesinfo"
                      onClick={() => {
                        setInstallId(item._id);
                        handleClear(item, 3);
                      }}
                    >
                      <img className={item._id == installId ? 'tickactive' : 'tick'} src={tick} />
                      <div className={installId == item._id ? 'serviceimgactive' : 'serviceimg'}>
                        <img src={item.subCategoryImage.url} />
                      </div>
                      <div className="servicetitle text-center">{item.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {category == 'SC' && serviceId.id && (
            <div
              className={
                singleJobInfo?._id && status != 'Pending'
                  ? 'surfaceName locationinfo d-flex locationinputdisable'
                  : 'surfaceName locationinfo d-flex'
              }
            >
              {datasite?.map((item) => {
                return (
                  <div
                    key={item?._id}
                    className={item.name == siteRequired ? 'locationtick locations d-flex' : 'locations d-flex'}
                    onClick={() => {
                      setSiteRequired(item.name);
                    }}
                  >
                    <img className="imgselect" src={item.name == siteRequired ? enabledradiobtn : disabledradiobtn} />
                    <div className="locationwall">{item.name}</div>
                  </div>
                );
              })}
            </div>
          )}
          {((category == 'SC' && serviceId.id) || (category != 'SC' && installId)) && (
            <>
              {inDashboard && (
                <div
                  className={
                    singleJobInfo?._id && status != 'Pending'
                      ? 'radiogrp my-4 d-flex flex-column surfaceName locationinputdisable'
                      : 'radiogrp my-4 d-flex flex-column surfaceName '
                  }
                >
               {  serviceId?.nextCatName != 'Type of Vehicle Wraps' &&  <div className="d-flex justify-content-between">
                    <div className="radioheading surfaceName1">Do You Require a lift for this Work?</div>
                    <Switch
                      checked={checked}
                      onChange={(e) => {
                        setChecked(e);
                        if (e) {
                          setCreateObj({ ...createObj, isLiftRequired: 'yes' });
                        } else {
                          setCreateObj({ ...createObj, isLiftRequired: 'no' });
                          setCreateObj({ ...createObj, liftSize: '   ' });
                        }
                      }}
                      className="ml-2"
                    />
                  </div>}
                  {checked && (
                    <Radio.Group
                      className="d-flex radiogroup"
                      value={Number(createObj?.liftSize)}
                      onChange={(e) => {
                        setCreateObj({ ...createObj, liftSize: e.target.value });
                      }}
                    >
                      <div>
                        {' '}
                        <Radio value={1}>
                          <img src={smallladder} className="m-2" />
                          <span>0 to 8 ft ( Small Ladder )</span>
                        </Radio>
                      </div>
                      <div>
                        {' '}
                        <Radio value={2}>
                          <img src={ladder} className="m-2" />
                          <span>8 to 18 ft ( Ladder )</span>
                        </Radio>
                      </div>
                      <div>
                        {' '}
                        <Radio value={3}>
                          <img src={ladder1} className="m-2" />
                          <span>18 to 20 ft ( Ladder )</span>
                        </Radio>
                      </div>
                      <div>
                        {' '}
                        <Radio value={4}>
                          <img src={lift1} className="m-2" />
                          <span>20 to 45 ft ( Lift )</span>
                        </Radio>
                      </div>
                      <div>
                        {' '}
                        <Radio value={5}>
                          <img src={lift2} className="m-2" />
                          <span>45 to 60 ft ( Lift )</span>
                        </Radio>
                      </div>
                      <div>
                        {' '}
                        <Radio value={6}>
                          <img src={lift3} className="m-2" />
                          60 to 80 ft ( Lift )
                        </Radio>
                      </div>
                      <div>
                        {' '}
                        <Radio value={7}>
                          <img src={lift4} className="m-2" />
                          80 to 125 ft ( Lift )
                        </Radio>
                      </div>
                    </Radio.Group>
                  )}
                </div>
              )}
              <div className="surfaceName my-2 ">
                <div className="surfacelabel surfaceName1">
                  What Date you Require these Services?
                  {serviceId?.nextCatName != 'Type of Vehicle Wraps' && <span style={{ color: 'red' }}>*</span>}
                </div>
                <DatePicker
                  format={dateFormat}
                  value={createObj?.jobStartDate != '' ? dayjs(createObj?.jobStartDate) : ''}
                  className={createObj?.jobStartDate ? 'w-100 inputfeildcolor ' : 'w-100 input-field'}
                  style={{ height: '50px' }}
                  disabledDate={(current) => {
                    return current && current < moment().startOf('day');
                  }}
                  onChange={handleChange}
                />
              </div>
              <div className="surfaceName my-4">
                <div className="surfacelabel surfaceName1">
                  Select Service Time
                  {serviceId?.nextCatName != 'Type of Vehicle Wraps' && <span style={{ color: 'red' }}>*</span>}
                </div>
                <Input
                  prefix={<img src={timecircle} />}
                  value={time}
                  className={time ? 'inputfeildcolor ' : 'input-field'}
                  placeholder="Enter Service Time"
                  style={{ height: '50px' }}
                  onKeyUp={(e) => {
                    e.preventDefault();
                  }}
                  onClick={() => {
                    setTimeModal(true);
                  }}
                />
              </div>
              {category != 'SC' && serviceId?.nextCatName != 'Type of Vehicle Wraps' && (
                <>
                  <div
                    className={
                      singleJobInfo?._id && status != 'Pending'
                        ? 'surfaceName locationinputdisable my-4 '
                        : 'surfaceName my-4 '
                    }
                  >
                    <div className="surfacelabel surfaceName1">
                      Width (Feet)<span style={{ color: 'red' }}>*</span>
                    </div>
                    <Input
                      value={createObj?.width}
                      className={createObj?.width ? 'inputfeildcolor' : 'input-field'}
                      placeholder="Enter Width"
                      style={{ height: '50px' }}
                      onChange={(e) => {
                        e.preventDefault();
                        checkInput(e, 'width');
                      }}
                    />
                  </div>
                  <div
                    className={
                      singleJobInfo?._id && status != 'Pending'
                        ? 'surfaceName locationinputdisable my-4 '
                        : 'surfaceName my-4 '
                    }
                  >
                    <div className="surfacelabel surfaceName1">
                      Height (Feet)<span style={{ color: 'red' }}>*</span>
                    </div>
                    <Input
                      value={createObj?.height}
                      className={createObj?.height ? 'inputfeildcolor' : 'input-field'}
                      placeholder="Enter Height"
                      style={{ height: '50px' }}
                      onChange={(e) => {
                        e.preventDefault();
                        checkInput(e, 'height');
                      }}
                    />
                  </div>
                  <div
                    className={
                      singleJobInfo?._id && status != 'Pending'
                        ? 'surfaceName locationinputdisable my-4 '
                        : 'surfaceName my-4 '
                    }
                  >
                    <div className="surfacelabel surfaceName1">
                      Quantity<span style={{ color: 'red' }}>*</span>
                    </div>
                    <Input
                      value={createObj?.quantity}
                      className={createObj?.quantity ? 'inputfeildcolor' : 'input-field'}
                      placeholder="Enter Quantity"
                      style={{ height: '50px' }}
                      onChange={(e) => {
                        e.preventDefault();
                        checkInput(e, 'quantity');
                      }}
                    />
                  </div>
                </>
              )}
              {inDashboard && (
                <>
                  <div
                    className={
                      singleJobInfo?._id && status != 'Pending'
                        ? 'surfacelabel locationinputdisable ml-2 my-4 surfaceName1'
                        : 'surfacelabel ml-2 my-4 surfaceName1'
                    }
                  >
                    Upload Surface Image{' '}
                    {serviceId?.nextCatName == 'Type of Vehicle Wraps' && <span style={{ color: 'red' }}>*</span>}
                  </div>
                  <div className="surfaceName m-1 d-flex">
                    {images?.length > 0 &&
                      images?.map((item, index) => {
                        if (item?.type.includes('image')) {
                          return (
                            <div
                              key={item?.url}
                              className="image mr-2"
                              onClick={() => {
                                handleViewImg(item, index);
                              }}
                            >
                              <img
                                src={item.url}
                                alt="image"
                                style={{ width: '100px', height: '100px', borderRadius: '10px', cursor: 'pointer' }}
                              />
                            </div>
                          );
                        } else if (item?.type.includes('pdf')) {
                          return (
                            <div
                              key={item?.url}
                              className="image mr-2"
                              onClick={() => {
                                handleViewImg(item, index);
                              }}
                            >
                              <img
                                src={pdfimage}
                                alt="image"
                                style={{ width: '100px', height: '100px', borderRadius: '10px', cursor: 'pointer' }}
                              />
                            </div>
                          );
                        }
                      })}
                    {images?.length < 5 && (
                      <div
                        className={
                          singleJobInfo?._id && status != 'Pending' ? 'uploadfile locationinputdisable' : 'uploadfile'
                        }
                      >
                        <input
                          id="file1"
                          accept=".pdf, image/*"
                          type="file"
                          style={{ display: 'none' }}
                          onChange={(val) => {
                            handleFileChange(val, 1);
                          }}
                        ></input>
                        <label htmlFor="file1">
                          <img className="plusimg" src={plusicon} />
                        </label>
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      singleJobInfo?._id && status != 'Pending'
                        ? 'locationinputdisable surfaceName my-4 '
                        : 'surfaceName my-4'
                    }
                  >
                    <div className="surfacelabel surfaceName1">
                      Addtional Notes{' '}
                      {serviceId?.nextCatName == 'Type of Vehicle Wraps' && (
                        <>
                          <span style={{ color: 'red' }}>*</span>
                          <div style={{ color: '#0FF4BD' }}>
                            Include Make, Model and year of vehicle as well as any additional notes that may be
                            relevant. please be sure to add your email address.
                          </div>
                        </>
                      )}
                    </div>
                    <Input.TextArea
                      value={createObj?.additionalNotes}
                      className={createObj?.additionalNotes ? 'inputfeildcolor' : 'input-field'}
                      placeholder="Enter Notes"
                      style={{ height: '100px' }}
                      onChange={(e) => {
                        setCreateObj({ ...createObj, additionalNotes: e.target.value });
                      }}
                    />
                  </div>
                  <div
                    className={
                      singleJobInfo?._id && status != 'Pending'
                        ? 'loactiondiv d-flex justify-content-between locationinputdisable'
                        : 'loactiondiv d-flex justify-content-between'
                    }
                  >
                    <div className="locationanme surfaceName1">
                      Location<span style={{ color: 'red' }}>*</span>
                    </div>
                    <div
                      className="addlocation"
                      onClick={() => {
                        setOpenLocationModal(true);
                      }}
                    >
                      + Add Location{' '}
                      
                    </div>
                  </div>
                  <div
                    className={
                      singleJobInfo?._id && status != 'Pending'
                        ? 'surfaceName locationinfo d-flex my-4 locationinputdisable'
                        : 'surfaceName locationinfo d-flex my-4'
                    }
                  >
                    {locations?.map((item) => {
                      return (
                        <div
                          key={item?._id}
                          className={item._id == locationId ? 'locationtick locations' : 'locations'}
                          onClick={() => {
                            setLocationId(item._id);
                            setValue(item?.city);
                            setCreateObj({
                              ...createObj,
                              city: `${item?.city}`,
                              province: item?.region,
                              location: {
                                lat: item?.lat,
                                long: item?.long,
                              },
                            });
                          }}
                        >
                          <img className="locimg" src={item._id == locationId ? enabledradiobtn : disabledradiobtn} />
                          <div className="locationheading">{item?.name}</div>
                          <div className="locationcontent">
                            {item?.apartmentNumber} {item?.address} {item?.city} {item?.province} {item?.postalCode}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </>
          )}
          {!inDashboard && (
            <div className="jobpost mb-5 my-4">
              <Button
                disabled={disabled}
                className={disabled ? 'w-30  m-auto text-center jobpostbtnd' : 'w-30  m-auto text-center jobpostbtn'}
                onClick={handlePost}
              >
                Post Job
              </Button>
            </div>
          )}
        </div>
      </div>
      {imageView && (
        <UpdateImage
          handleDelete={handleDelete}
          singleImg={singleImg}
          setImageView={setImageView}
          imageView={imageView}
          handleFileChange={handleFileChange}
        />
      )}
      {timeModal && <TimeModal timeModal={timeModal} setTimeModal={setTimeModal} setTime={setTime} hours={hours} />}
      {postJobModal && (
        <Modal
          centered
          className="addlocationmodal"
          closable={false}
          header={null}
          footer={null}
          style={{ top: '', overflow: 'hidden !important' }}
          open={postJobModal}
        >
          <PostJobPopup
            popupModal={true}
            setPostJobModal={setPostJobModal}
            postJobModal={postJobModal}
            approxCost={approxCost}
            isHome={true}
            state={{
              category: category,
              serviceId: serviceId,
              installId: installId,
              createObj: { createObj },
              time: time,
              siteRequired: siteRequired,
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default CreateJob;
