import React from 'react';
import { Button } from 'antd';
import crossicon from '../../../asset/images/crossicon.svg';
import sucessfulicon from '../../../asset/images/Successful.svg'
const SucessPopUp = ({ handleYes }) => {
 
  return (
    <div className="sucesspopup  d-flex flex-column justify-content-center m-auto">
      <div className="crossicon" style={{ right: "0rem", cursor: "pointer" }} onClick={handleYes}><img src={crossicon} style={{ width: "30px", height: "30px" }} /></div>

      <div className="costimg m-3"><img src={sucessfulicon} /></div>
      <div className="sucessheading m-3">Applied Successfully!</div>
      <div className="content m-3">Lorem ipsum dolor sit amet, consectetur incididunt adipiscing elit</div>
      <Button className="termscontentbtn m-3" onClick={handleYes}>Done</Button>
    </div>
  )
}
export default SucessPopUp;