import React, { useState, useRef, useEffect } from "react";
import { List, message } from "antd";
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useDispatch } from "react-redux";

import { getAddressObject } from "../../../common/helper";
import { setProfile } from '../../../redux/slices/login';

import { createProfileClient } from '../../../api/detailsapi'

const ProfileDetails = ({ handleAddCount }) => {
  const [disabled, setDisabled] = useState(true);
  const [value, setValue] = useState("");
  const [showList, setShowList] = useState(false)
  const [form] = Form.useForm();
  const formRef = useRef();
  const [profileInfo, setProfileInfo] = useState({});
  const dispatch = useDispatch();

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE,
    options: {
      //componentRestrictions: { country: "ca" },
    },
  });
  
  const getDetails = (placeid) => {
    const data = placePredictions.filter((item) => item.place_id == placeid)
    placesService?.getDetails(
      {
        placeId: data[0].place_id,
      },
      (placeDetails) => {
        const address = getAddressObject(placeDetails?.address_components)
        setValue(placeDetails?.name)
        formRef.current.setFieldValue('address', placeDetails?.name);
        formRef.current.setFieldValue('city', address?.city);
        formRef.current.setFieldValue('province', address?.region);
        formRef.current.setFieldValue('postalCode', address?.postal_code);
        setProfileInfo({
          ...profileInfo,
          address: `${placeDetails?.name},${address?.city},${address?.region},${address?.postal_code}`,
          city: address?.city
          , province: address?.region
          , postalCode: address?.postal_code, location: {
            lat: placeDetails.geometry.location.lat().toFixed(5), long: placeDetails.geometry.location.lng().toFixed(5)
          }
        })
        setShowList(false)
      }
    );
  }

  const handleFormChange = () => {
    const hasVal = Object.keys(form.getFieldsValue()).filter(key => key!="apartmentNumber" &&  !!form.getFieldValue(key));
    if(hasVal.length==8)
    setDisabled(false)
   else
   setDisabled(true)
  }

  const saveProfileDetails = async (details) => {
    const resp = await createProfileClient({ ...details, isCardDetailsUpdated: true });
    if (resp?.status == 200) {
      message.success(resp?.data?.message);
    }
  }

  const handleSaveVal=(e,val)=>{
    form.setFieldValue(`${val}`, e.target.value); 
    setProfileInfo({ ...profileInfo, val: e.target.value })
  }

  const onFinish = () => {
    saveProfileDetails({ ...profileInfo,
       locationDetails: { name: profileInfo.name, apartmentNumber: profileInfo.apartmentNumber,
       address: profileInfo?.address, city: profileInfo?.city,
        location: profileInfo?.location, province: profileInfo?.province, postalCode: profileInfo?.postalCode } });
   handleAddCount();
    dispatch(setProfile({ firstName: profileInfo?.firstName, lastName: profileInfo?.lastName,isMobileVerified:true,userType:0 }));
  }

  return (
    <>
      <div className="center-container justify-content-center profiledetails"  >
        <div className="heading">Enter Your Profile Details</div>
        <Form
          initialValues={{ remember: true }}
          onFinish={onFinish}
          ref={formRef}
          autoComplete="off"
          layout="vertical"
          onFieldsChange={handleFormChange}
          form={form}
          className="profileform"
        >
          <div className="subform">
            <div className="subform1">
              <Form.Item
                name="firstName"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: 'Please enter first Name',
                  },
                  {
                    pattern: new RegExp(/^[A-Za-z ]+$/),
                    message:
                      "Field does not accept numbers or special characters.",
                  },
                ]}
              >
                <Input size="large" placeholder="First Name" className={form.getFieldValue('firstName') ? "input-fieldcolor" : "input-field"} onChange={(e) => { setProfileInfo({ ...profileInfo, firstName: e.target.value }) }} />
              </Form.Item>
            </div>
            <div className="subform1">
              <Form.Item
                name="lastName"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a last Name',
                  },
                  {
                    pattern: new RegExp(/^[A-Za-z ]+$/),
                    message:
                      "Field does not accept numbers or special characters.",
                  },
                ]}
              >
                <Input size="large" placeholder="Last Name" className={form.getFieldValue('lastName') ? "input-fieldcolor" : "input-field"} onChange={(e) => { setProfileInfo({ ...profileInfo, lastName: e.target.value }) }} />
              </Form.Item>
            </div>
          </div>
          <div className="subform">
            <div className="subform1">
              <Form.Item
                name="name"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: 'Please enter  Name',
                  },
                  // {
                  //   pattern: new RegExp(/^[A-Za-z ]+$/),
                  //   message:
                  //     "Field does not accept numbers or special characters.",
                  // },
                ]}
              >
                <Input size="large" placeholder="Location Nickname" className={form.getFieldValue('name') ? "input-fieldcolor" : "input-field"} onChange={(e) => { setProfileInfo({ ...profileInfo, name: e.target.value }) }} />
              </Form.Item>
            </div>
            <div className="subform1">
              <Form.Item
                name="apartmentNumber"
                className="mb-3"
                rules={[
                  // {
                  //  required:true,
                  //   message: 'Please enter apartment Number',
                  // },
                ]}
              >
                <Input size="large" placeholder="Apartment Number" className={form.getFieldValue('apartmentNumber') ? "input-fieldcolor" : "input-field"} onChange={(e) => { setProfileInfo({ ...profileInfo, apartmentNumber: e.target.value }) }} />
              </Form.Item>
            </div>
          </div>
          <div className="subform">
            <div className="subform1">
              <Form.Item
                name="address"
                type="text"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your address!',
                  }
                ]}
              >
                <Input
                  size="large"
                  value={value}
                   autoFocus={value.length>0?true:false}
                  onChange={(evt) => {
                    setValue(evt.target.value)
                    formRef.current.setFieldValue('address', evt.target.value)
                    getPlacePredictions({ input: evt.target.value });
                    if (!isPlacePredictionsLoading) setShowList(true)
                  }}
                  onKeyDown={(event) => {
                    if (event?.keyCode === 8 || event?.keyCode === 46) {
                      form.setFieldValue("city", '')
                      form.setFieldValue("province", '')
                      form.setFieldValue("postalCode", '')
                      handleFormChange()
                    }
                  }}
                  loading={false}
                  placeholder="Enter address"
                  className={form.getFieldValue('address') ? "input-fieldcolor" : "input-field"}
                />
                {value !== '' && showList && placePredictions.length > 0 && (
                  <div className="google-autosuggestion-list">
                    <List
                      dataSource={placePredictions}
                      renderItem={(item) => (
                        <List.Item onClick={() => getDetails(item?.place_id)}>
                          <List.Item.Meta title={item.description} />
                        </List.Item>
                      )}
                    />
                  </div>
                )}
              </Form.Item>
            </div>
            <div className="subform1">
              <Form.Item
                name="city"
                className="mb-3"
                rules={[
                  // {
                  //   required:true,
                  //   message: 'Please enter a valid city',
                  // }
                ]}
              >
                <Input size="large" placeholder="Enter city" className={form.getFieldValue('city') ? "input-fieldcolor" : "input-field"} onChange={(e) => { form.setFieldValue("city", e.target.value); setProfileInfo({ ...profileInfo, city: e.target.value }) }} />
              </Form.Item>
            </div>
          </div>
          <div className="subform">
            <div className="subform1">
              <Form.Item
                name="province"
                className="mb-3"
                rules={[
                  // {
                  //  rule:true,
                  //   message: 'Please enter a valid Province',
                  // }

                ]}
              >
                <Input size="large" placeholder="Enter province" className={form.getFieldValue('province') ? "input-fieldcolor" : "input-field"} 
                onChange={(e) => { handleSaveVal(e,"province") }} />
              </Form.Item>
            </div>
            <div className="subform1">
              <Form.Item
                name="postalCode"
                className="mb-3"
                rules={[
                  // {
                  //  required:true,
                  //   message: 'Please enter a valid postal code',
                  // }
                ]}
              >
                <Input size="large" placeholder="Enter postal code" className={form.getFieldValue('postalCode') ? "input-fieldcolor" : "input-field"} onChange={(e) => { handleSaveVal(e,'postalCode') }} />
              </Form.Item>
            </div>
          </div>
          <div className="subform">
            <div className="subform1 w-50">
              <Form.Item
                name="companyName"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: 'Please enter company Name',
                  },
                  // {
                  //   pattern: new RegExp(/^[A-Za-z ]+$/),
                  //   message:
                  //     "Field does not accept numbers or special characters.",
                  // },
                ]}
              >
                <Input size="large" placeholder="Company Name" className={form.getFieldValue('companyName') ? "input-fieldcolor" : "input-field"} onChange={(e) => { setProfileInfo({ ...profileInfo, companyName: e.target.value }) }} />
              </Form.Item>
            </div>
          </div>
          <div className="subform">
            <Button htmlType="submit"
              disabled={disabled}
              className={disabled ? "submitbtnd" : "submitbtn"}
            >
              Next
            </Button>
          </div>
        </Form>
      </div>
    </>
  )
}
export default ProfileDetails;