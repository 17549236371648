import React from 'react';
import { Modal, Button, Input,Spin } from 'antd';
import editicon from '../../../asset/images/EditIcon.png';
import crossicon from '../../../asset/images/crossicon.svg';
import uploadpic from '../../../asset/images/uploadpic.svg';

const AddExpense = (props) => {
  const { expenseModal, handleFileChange, setExpenseModal, expenses, setExpenses, handleAddExpense, setCompleteModal, loader } = props
  const checkInput = (inputElement) => {
    if (Number(inputElement.target.value) > 0) {
      setExpenses({ ...expenses, price: inputElement.target.value })
    }
    else {
      setExpenses({ ...expenses, price: '' })
    }
  }
  return (
    <Modal
      centered
      className='addexpensemodal expensemodal'
      closable={false}
      header={null}
      footer={null}
      style={{ top: '', overflow: "hidden !important" }}
      open={expenseModal}
    >
      <Spin spinning={loader}>
        <img className="crossimg" src={crossicon} style={{ cursor: "pointer" }} onClick={() => { setExpenseModal(false); setExpenses({}); setCompleteModal(true) }} />
        <div className="expenseheading">Add Expense</div>
        <div className="expensepic">
          <input
            type="file"
            id="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(val) => {
              handleFileChange(val, 2);
            }}
          />
          {expenses?.image?.url ?
            <div className="jobimg">
              <img className="jobimage" src={expenses?.image?.url} />
              <label htmlFor="file"><img src={editicon} style={{ position: 'absolute', bottom: "1rem", right: "1rem", borderRadius: "50%", padding: "8px", backgroundColor: "#fff" }} /></label>
            </div>

            : <div className="expensedetails">
              <div className="uploadimg"><img src={uploadpic} /></div>

              <label htmlFor="file" className="uploadicon" >
                <div className="uploadpic">Upload Picture</div>
              </label>
            </div>
          }
        </div>
        <Input className={expenses?.name ? "my-2 inputs inputvalid" : "my-2 inputs"} value={expenses?.name} placeholder='Expense Title' onChange={(e) => { setExpenses({ ...expenses, name: e.target.value }) }} />
        <Input className={expenses?.price ? "my-2 inputs p-l-1 inputvalid" : "my-2 inputs p-l-1"} prefix="$" placeholder='Amount' value={expenses?.price} onChange={(e) => { checkInput(e) }} />
        <div className="expensebtn d-flex">
          <Button className="cancelbtn" onClick={() => { setExpenseModal(false); setExpenses({}); setCompleteModal(true) }}>Cancel</Button>
          <Button disabled={!(expenses?.name && expenses?.price && expenses?.image?.url)} className={expenses?.name && expenses?.price && expenses?.image?.url ? "addexpensebtn  " : "addexpensebtn addexpensebtnd"} onClick={() => { handleAddExpense() }}> Add Expense</Button>
        </div>
      </Spin>
    </Modal>
  )
}
export default AddExpense;