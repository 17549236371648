import React, { useState, useEffect } from 'react';
import { Carousel, message } from 'antd';
import img1 from '../../asset/images/image1.png';
import logo from '../../asset/images/Logo.svg';
import emailIcon from '../../asset/images/emailIcon.svg';
import lockIcon from '../../asset/images/lockIcon.svg';
import '../SignUp/SignUp.less';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import { useNavigate,useLocation } from 'react-router-dom';
import { signinUser, getProfileApi } from '../../api/detailsapi';
import { setProfile } from '../../redux/slices/login';
import { useDispatch } from 'react-redux';

const SignIn = () => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [email, setEmail] = useState(false);
  const [password, setPassword] = useState(false);
  const [form] = Form.useForm();
  const images = [img1, img1];
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const role=localStorage.getItem('role');

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentIndex === images.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 3000);
    return () => clearInterval(intervalId);
  }, [currentIndex]);

  useEffect(()=>{
      const val = location.pathname.includes('clientsignin') ? '0' : '1';
      localStorage.setItem('role', val);
  },[role])

  const emailValidation = (val) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let isValidEmail = emailRegex.test(val);
    setEmail(isValidEmail);
  };

  const passwordValidation = (value) => {
    if (value != '')
      setPassword(true);
    else
      setPassword(false);
  };

  const onFinish = (values) => {
    const val = location.pathname.includes("clientsignin") ? "0" : "1";
    const body = {
      email: values.email,
      password: values.password,
      userType: val
    };
    doSignIn(body);
  };

  const doSignIn = async (values) => {
      const response = await signinUser(values);
      if (response?.status === 200 && response?.data?.data?.status != "Rejected") {
        localStorage.setItem("token", response?.data?.data?.token);
        const resp = await getProfileApi();
        dispatch(setProfile(resp?.data?.data));
      }
      else
        message?.error("We regret to inform you that your profile has been rejected by MyInstallr, please reach out to support@myinstallr.ca for more information.")
  };

  const messageIcon = (
    <div className="signup_input_icon">
      <img src={emailIcon} />
    </div>
  );
  const passwordIcon = (
    <div className="signup_input_icon">
      <img src={lockIcon} />
    </div>
  );
  return (
    <>
      <div className="signup_container" style={{ overflow: 'hidden' }}>
        <div className="row">
          <div className="col-md-12 col-lg-7 col-xl-6 signup-left-panel">
            <div
              className="left-content"
              style={{
                width: 'auto',
              }}
            >
              <Carousel autoplay={true} effect={'fade'} autoplaySpeed={3000} >
                {images.map((data, ind) => (
                  <div key={data} className='image-wrapper'>
                    <img className=" splashimgstyle" src={data} style={{ objectFit: "cover" }} />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
          <div className="col-md-12 col-lg-5 col-xl-6 signup_right_panel">
            <div className="rightpanelcontent" style={{ width: "100%" }}>
              <div className="signup-logo">
                <img src={logo} />
              </div>
              <div className="signup_heading" style={{ width: "100%" }}>
                <div className="signup_data" >
                  <h1 className="signup_header">Welcome Back!</h1>
                  <p className="signup_para">Sign in to your account</p>
                </div>
                <div className="signup_end">
                  <div className="form_data signindata" style={{ width: "60%" }}>
                    <Form
                      name="normal_login "
                      className="s-form"
                      initialValues={{ remember: true }}
                      onFinish={onFinish}
                      form={form}
                    >
                      <Form.Item
                        name="email"
                        rules={[
                          // {
                          //   type: 'email',
                          //   message: 'Please enter a valid email address.',
                          // },
                          { required: true, message: 'Please Enter your Email' },
                        ]}

                      >
                        <Input
                          prefix={messageIcon}
                          onChange={(e) => {
                            emailValidation(e.target.value);
                          }}
                          size="large"
                          placeholder="Enter Email"
                          style={{ width: "100%" }}
                          className={
                            email ? 'validated_input_field ' : 'input_field '
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        rules={[
                          { required: true, message: 'Please enter your Password' }
                        ]}
                      >
                        <Input.Password
                          type="password"
                          prefix={passwordIcon}
                          size="large"
                          placeholder="Enter Password"
                          onChange={(e) => {

                            passwordValidation(e.target.value);
                          }}
                          style={{ width: "100%" }}
                          className={
                            password ? 'validated_input_field ' : 'input_field '
                          }

                        />
                      </Form.Item>
                      <Form.Item>
                        <a className="text-dark fw-bold " style={{ textDecoration: 'none' }}>
                          <span
                            style={{
                              color: '#000',
                              fontFamily: 'Poppins',
                              fontSize: '1rem',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'flex-end',
                              lineHeight: '1.25rem',
                              cursor: "pointer"
                            }}
                            onClick={() => {
                              navigate('/resetpassword');
                            }}
                          >
                            Forgot your password?
                          </span>
                        </a>
                      </Form.Item>

                      <Form.Item>
                        <Button
                          htmlType="submit"
                          className={email && password ? 'login_form_button' : 'login_form_button_disabled'}
                          style={{ marginTop: '0.43rem', width: "100%" }}
                        >
                          Sign In
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                  <div className="sign_in_text">
                    Don&rsquo;t have an account?
                    <a className="text-dark fw-bold" style={{ textDecoration: 'none' }}>
                      <span
                        style={{
                          color: '#000',
                          fontFamily: 'Poppins',
                          fontSize: '1.125rem',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          lineHeight: '2.5rem',
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          if (localStorage.getItem('role') == 0)
                            navigate('/clientsignup');
                          else
                            navigate('/contractorsignup');

                        }}
                      >
                        Sign Up
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
