import instance from '../config/axios';
export const dashBoardCount=async(id)=>{
  try {
    const response = await instance.get(`user/dashboard-count`);
    return response;
  } catch (error) {
   return error
  }
}

export const appointmentListApi=async(from,to)=>{
  try {
    const response = await instance.get(`user/appointments?from=${from}&to=${to}`);
    return response;
  } catch (error) {
   return error
  }
}

export const earningStatus=async(id)=>{
  try {
    const response = await instance.get(`user/earning-stats`);
    return response;
  } catch (error) {
   return error
  }
}