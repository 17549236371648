import React,{useEffect,useState} from 'react';
import DashBoardWrapper from '../../../components/wrapper/DashBoardWrapper';
import EarningDetails from '../Earnings/EarningDetails';
import MySchedule from './MySchedule';
import Reports from './Reports';
import {  dashboardInfoApi} from "../../../api/reports";
import SpinWrapper from "../../../components/wrapper/SpinWrapper";


const DashBoardInfo = () => {
  const items=["Pending Appointments","Upcoming Appointments","Total Earnings"];
  const[earnings,setEarnings]=useState();

  useEffect(()=>{
    getReportData()
  },[])

  const getReportData=async()=>{
    const resp=await dashboardInfoApi();
    setEarnings(resp?.data?.data);  
  }
  
  return (
   <div className="dashboard">
    <div className="earninginfo">
      <EarningDetails items={items} earnings={{"item1":earnings?.pendingCounts,"item2":earnings?.upcomingCount,"item3":earnings?.earning?.totalEarnings}}/>
    </div>
    <div className="graphcal">
      <div className="graphinfo"><Reports/></div>
      <div className="calenderinfo"><MySchedule/></div>
    </div>
   </div>
  )
}

const DashBoard = () => {
  return (
    <DashBoardWrapper>
      <SpinWrapper>
      <DashBoardInfo/>
      </SpinWrapper>/
      </DashBoardWrapper>
  )
}

export default DashBoard;