import React from 'react'
import crossicon from '../asset/images/crossicon.svg';
import { Modal } from 'antd';
import RatingsComp from '../pages/contractor/ratings/RatingsComp'
const RatingModal = ({ setShowRatings, showRatings, singleContId }) => {
  return (
    <Modal
      centered
      className='ratingview'
      closable={false}
      header={null}
      footer={null}
      style={{ top: '', overflow: "hidden !important" }}
      open={showRatings}
    >
      <img src={crossicon} style={{ cursor: "pointer", position: "absolute", right: "2%", width: "25px", height: "25px" }} onClick={() => { setShowRatings(false) }} />
      <h6 className="text-center">User Ratings</h6>
      <RatingsComp userId={singleContId} />
    </Modal>
  )
}

export default RatingModal