import React, { useEffect, useState } from 'react';
import { message, Tooltip, DatePicker, Typography, Modal, Spin } from 'antd';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import checkicon from '../../../asset/images/checkIcon.svg';
import crossicon from '../../../asset/images/crossicon.svg';
import dummyicon from '../../../asset/images/danger.svg';
import sucessicon from '../../../asset/images/Successicon.png';

import { createProfileClient, addBankDetailsApi } from '../../../api/detailsapi';

dayjs.locale('en');

const BankingDetails = ({ isEdit, bankInfo }) => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [showTnc, setShowTnc] = useState(false);
  const [consent, setConsent] = useState(false);
  const [tncChecked, setTncChecked] = useState(false);
  const [tncChecked1, setTncChecked1] = useState(false);
  const [date, setDate] = useState([]);
  const [bankDetails, setBankDetails] = useState({
    accountHolderName: '',
    routingNumber: '',
    accountNumber: '',
    transitNumber: '',
    dob: '',
  });
  const [loader, setLoader] = useState(false);
  const [openBankPopup, setOpenBankPopup] = useState(true);
  const { Title, Text } = Typography;
  const navigate = useNavigate();
  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabled(hasErrors);
  };
  const onChange2 = () => {
    setTncChecked((prev) => !prev);
  };
  const onChange1 = () => {
    setTncChecked1((prev) => !prev);
  };
  const TextWrappper = ({ children }) => {
    return (
      <>
        <div className="mt-4 mb-3">
          <Text
            className="TextLevelSmallLabel"
            style={{
              fontSize: '16px',
              textAlign: 'justify',
            }}
          >
            {children}
          </Text>
        </div>
      </>
    );
  };
  const handleAddBankDetails = async () => {
    setLoader(true);
    const resp = await addBankDetailsApi({ ...bankDetails });
    if (resp?.status == 200) navigate('/newjob');
    setLoader(false);
  };

  const handleNext = async () => {
    const resp1 = await createProfileClient({ agreeToStripeConsent: true, agreeToConsent: true });
    if (resp1?.status === 200) navigate('/newjob');
  };

  const handleChange = (date, dateString) => {
    const val = { ...bankDetails, dob: dateString };
    setBankDetails(val);
  };
  const dateFormat = 'DD-MM-YYYY';

  const handleSave = async () => {
    const resp = await addBankDetailsApi({
      accountHolderName: bankDetails?.accountHolderName,
      routingNumber: bankDetails?.routingNumber,
      accountNumber: bankDetails?.accountNumber,
      transitNumber: bankDetails?.transitNumber,
      dob: bankDetails?.dob,
    });
    if (resp?.status == 200) message.success('Banking details has been updated successfully');
  };

  useEffect(() => {
    if (bankInfo) {
      form.setFieldsValue(bankInfo);
      setBankDetails(bankInfo);
      setDisabled(false);
    }
  }, [bankInfo]);

  useEffect(() => {
    const val = bankDetails?.dob?.split('-');
    setDate(val);
  }, [bankDetails?.dob]);

  return (
    <Spin spinning={loader}>
      <div className="bankdetailscontainer">
        {!isEdit && (
          <h1 className="heading ">
            Enter Your Bank Details
            <span className="ml-2 my-2">
              {' '}
              <Tooltip
                placement="bottomLeft"
                title="We capture your banking details to ensure a successful transaction into your bank account. Our application will validate your banking details before processing payment into your bank account linked with MyInstallr,  Routing number is usually of 9 digits and can consist of your institution number+transit number, if the final value is not accepting, kindly try adding a 0 in the front. Alternatively, you can reach out to your bank and ask for your Routing number "                arrow={false}
                overlayInnerStyle={{
                  color: 'black',
                  backgroundColor: '#fff',
                  width: '250px',
                  fontSize: '13px',
                  fontWeight: '400',
                }}
                overlayStyle={{
                  color: '#fff',
                }}
              >
                <span className="infocircle">
                  <img
                    style={{ width: '30px', height: '18px', marginLeft: '5px', cursor: 'pointer' }}
                    src={dummyicon}
                  />
                </span>
              </Tooltip>
            </span>
          </h1>
        )}
        {!isEdit && (
          <div
            className="skipoption"
            onClick={() => {
              handleNext();
            }}
          >
            Skip
          </div>
        )}
        <Form
          name="bank-form "
          autoComplete="off"
          layout="vertical"
          onFieldsChange={handleFormChange}
          form={form}
          style={{ marginTop: '4%' }}
        >
          <div className="subform">
            <div className="subform1">
              <Form.Item
                name="accountHolderName"
                type="BankName"
                className="mb-3"
                rules={[
                  {
                    type: 'name',
                    message: 'Please enter a valid BankName',
                  },
                  {
                    required: true,
                    message: 'Please enter your Bank Name',
                  },
                ]}
              >
                <Input
                  style={{ height: '55px' }}
                  onChange={(e) => {
                    setBankDetails({ ...bankDetails, accountHolderName: e.target.value });
                  }}
                  size="large"
                  placeholder="Account Holder Name"
                  className={bankDetails?.accountHolderName ? 'input-fieldcolor' : 'input-field1 '}
                />
              </Form.Item>
            </div>
            <div className="subform1">
              <Form.Item
                name="routingNumber"
                type="routingNumber"
                className="mb-3"
                rules={[
                  {
                    type: 'name',
                    message: 'Please enter a valid Institution Number',
                  },
                  {
                    required: true,
                    message: 'Please enter your Institution Number',
                  },
                  // {
                  //   pattern: new RegExp(/^\d+$/),
                  //   message: 'Only Numbers Required.',
                  // },
                ]}
              >
                <Input
                  style={{ height: '55px' }}
                  onChange={(e) => {
                    setBankDetails({ ...bankDetails, routingNumber: e.target.value });
                  }}
                  size="large"
                  placeholder="Routing Number"
                  className={bankDetails?.routingNumber ? 'input-fieldcolor' : 'input-field1 '}
                />
              </Form.Item>
            </div>
          </div>
          <div className="subform">
            <div className="subform1">
              <Form.Item
                name="accountNumber"
                type="accountNumber"
                className="mb-3"
                rules={[
                  {
                    type: 'name',
                    message: 'Please enter a valid Account Number',
                  },
                  {
                    required: true,
                    message: 'Please enter your Account Number',
                  },
                  // {
                  //   pattern: new RegExp(/^\d+$/),
                  //   message: 'Only Numbers Required.',
                  // },
                ]}
              >
                <Input
                  style={{ height: '55px' }}
                  onChange={(e) => {
                    setBankDetails({ ...bankDetails, accountNumber: e.target.value });
                  }}
                  size="large"
                  placeholder="Account Number"
                  className={bankDetails?.accountNumber ? 'input-fieldcolor' : 'input-field1 '}
                />
              </Form.Item>
            </div>
            <div className="subform1">
              <Form.Item
                name="transitNumber"
                type="transitNumber"
                className="mb-3"
                rules={[
                  {
                    type: 'name',
                    message: 'Please enter a valid Transit Number',
                  },
                  {
                    required: true,
                    message: 'Please enter your Transit Number',
                  },
                  // {
                  //   pattern: new RegExp(/^\d+$/),
                  //   message: 'Only Numbers Required.',
                  // },
                ]}
              >
                <Input
                  style={{ height: '55px' }}
                  onChange={(e) => {
                    setBankDetails({ ...bankDetails, transitNumber: e.target.value });
                  }}
                  size="large"
                  placeholder="Transit Number"
                  className={bankDetails?.transitNumber ? 'input-fieldcolor' : 'input-field1 '}
                />
              </Form.Item>
            </div>
          </div>

          <div className="subform bankform">
            {date?.length > 0 && date[0] && (
              <DatePicker
                className={bankDetails?.dob ? 'input-field1' : 'input-field'}
                placeholder="Select Date of Birth"
                defaultValue={dayjs(date[0] ? new Date(date[2], date[1], date[0]) : new Date())}
                size="large"
                showToday={false}
                format={dateFormat}
                onChange={handleChange}
                disabledDate={(current) => {
                  return current && current > moment().subtract(216, 'month');
                }}
              />
            )}
            {!date[0] && (
              <DatePicker
                className={bankDetails?.dob ? 'input-field1 ' : 'input-field'}
                placeholder="Select Date of Birth"
                //defaultValue={dayjs(date[0] ? new Date(date[2], date[1], date[0]) : new Date())}
                size="large"
                showToday={false}
                format={dateFormat}
                onChange={handleChange}
                disabledDate={(current) => {
                  return current && current > moment().subtract(216, 'month');
                }}
              />
            )}
          </div>
          {!isEdit && (
            <div className="subform" style={{ marginTop: '4%' }}>
              <Button
                htmlType="submit"
                className={!(!disabled && bankDetails?.dob) ? 'm-auto nextbtnd ' : 'nextbtn m-auto '}
                type="primary"
                size="large"
                disabled={!(!disabled && bankDetails?.dob)}
                onClick={() => {
                  handleAddBankDetails();
                }}
              >
                Next
              </Button>
            </div>
          )}
          {isEdit && (
            <div className="subform" style={{ marginTop: '4%' }}>
              <Button
                htmlType="submit"
                className={!(!disabled && bankDetails?.dob) ? ' nextbtnd ' : 'nextbtn  '}
                type="primary"
                size="large"
                disabled={!(!disabled && bankDetails?.dob)}
                onClick={() => {
                  handleSave();
                }}
              >
                Save
              </Button>
            </div>
          )}
        </Form>
      </div>
      <Modal
        open={showTnc}
        //onCancel={handleCancel}
        //  closeIcon={<img src={crossicon}/>}
        footer={null}
        closable={false}
        width={'750px'}
        centered
        className="stripemodal"
        style={{ borderRadius: '20px', padding: '24px 24px 0px 24px' }}
      >
        <div style={{ width: '30px', float: 'right' }}>
          <img
            src={crossicon}
            style={{ float: 'right', color: 'black', width: '30px', height: '30px', margin: '10px', cursor: 'pointer' }}
            onClick={() => {
              setShowTnc(false);
            }}
          />
        </div>
        <Title className="mt-2 text-center ModalTitle" level={4}>
          Agree to Stripe Consent
        </Title>
        <TextWrappper>
          <>
            <hr style={{ border: '4px solid black', margin: '0px' }} />
            <div className="stripecontent">
              <div className="question mt-4">What is Lorem Ipsum?</div>
              <div className="solution">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                software like Aldus PageMaker including versions of Lorem Ipsum.
              </div>
              <div className="question mt-4">What is Lorem Ipsum?</div>
              <div className="solution">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                software like Aldus PageMaker including versions of Lorem Ipsum.
              </div>
              <div className="question mt-4">What is Lorem Ipsum?</div>
              <div className="solution">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                software like Aldus PageMaker including versions of Lorem Ipsum.
              </div>
            </div>
          </>
        </TextWrappper>
        <div className="checkbox ml-3">
          {tncChecked ? (
            <img src={sucessicon} style={{ cursor: 'pointer' }} onClick={onChange2} />
          ) : (
            <img src={checkicon} style={{ cursor: 'pointer' }} onClick={onChange2} />
          )}{' '}
          Click to Agree
        </div>
        <div className="buttonnext d-flex ">
          <Button
            disabled={!tncChecked}
            className={tncChecked ? ' mt-3 mb-5 mx-auto tncchecked' : 'mt-3 mb-5 mx-auto'}
            type="primary"
            size="large"
            style={{ width: '60%', height: '45px', broderRadius: '2px' }}
            onClick={() => {
              handleNext();
            }}
          >
            Next
          </Button>
        </div>
      </Modal>
      <Modal
        open={consent}
        footer={null}
        closable={false}
        width={'750px'}
        centered
        className="stripemodal"
        style={{ borderRadius: '20px', padding: '24px 24px 0px 24px' }}
      >
        <div style={{ width: '30px', float: 'right' }}>
          <img
            src={crossicon}
            style={{ float: 'right', color: 'black', width: '30px', height: '30px', margin: '10px', cursor: 'pointer' }}
            onClick={() => {
              setConsent(false);
            }}
          />
        </div>
        <Title className="mt-2 text-center ModalTitle" level={4}>
          Agree to Consent
        </Title>
        <TextWrappper>
          <>
            <hr style={{ border: '4px solid black', margin: '0px' }} />
            <div className="stripecontent">
              <div className="question mt-4">What is Lorem Ipsum?</div>
              <div className="solution">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                software like Aldus PageMaker including versions of Lorem Ipsum.
              </div>
              <div className="question mt-4">What is Lorem Ipsum?</div>
              <div className="solution">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                software like Aldus PageMaker including versions of Lorem Ipsum.
              </div>
              <div className="question mt-4">What is Lorem Ipsum?</div>
              <div className="solution">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                software like Aldus PageMaker including versions of Lorem Ipsum.
              </div>
            </div>
          </>
        </TextWrappper>
        <div className="checkbox ml-3">
          {tncChecked1 ? (
            <img src={sucessicon} style={{ cursor: 'pointer' }} onClick={onChange2} />
          ) : (
            <img src={checkicon} style={{ cursor: 'pointer' }} onClick={onChange1} />
          )}{' '}
          Click to Agree
        </div>
        <div className="buttonnext d-flex ">
          <Button
            disabled={!tncChecked1}
            className={tncChecked1 ? ' mt-3 mb-5 mx-auto tncchecked' : 'mt-3 mb-5 mx-auto'}
            type="primary"
            size="large"
            style={{ width: '60%', height: '45px', broderRadius: '2px' }}
            onClick={() => {
              setShowTnc(true);
              setConsent(false);
            }}
          >
            Next
          </Button>
        </div>
        {/* </Spin> */}
      </Modal>
      <Modal
        open={openBankPopup}
        //onCancel={handleCancel}
        //  closeIcon={<img src={crossicon}/>}
        footer={null}
        closable={false}
        width={'750px'}
        centered
       // className="stripemodal"
        style={{ borderRadius: '20px', padding: '24px 24px 0px 24px' }}
      >
         <div style={{ width: '30px', float: 'right' }}>
          <img
            src={crossicon}
            style={{ float: 'right', color: 'black', width: '30px', height: '30px', margin: '10px', cursor: 'pointer' }}
            onClick={() => {
              setOpenBankPopup(false);
            }}
          />
          </div>
          <div className='bankinfo' style={{padding:"20px"}}>
            <p>We capture your banking details to ensure a successful transaction into your bank account. Our application will validate your banking details before processing payment into your bank account linked with MyInstallr.</p>
         <p> Routing number is usually of 9 digits and can consist of your institution number+transit number, if the final value is not accepting, kindly try adding a 0 in the front. Alternatively, you can reach out to your bank and ask for your Routing number</p>
          </div>
        </Modal>
    </Spin>
  );
};

export default BankingDetails;
