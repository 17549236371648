import React from 'react'
import crossicon from '../asset/images/crossicon.svg';
import { Modal } from 'antd';

const UpdateImage = ({ singleImg, setImageView, imageView, handleDelete, handleFileChange }) => {
  return (
    <Modal
      centered
      className='popuppdf'
      closable={false}
      header={null}
      footer={null}
      style={{ top: '', overflow: "hidden !important" }}
      open={imageView}
    >
      <>
        <img src={crossicon} style={{ cursor: "pointer" }} onClick={() => { setImageView(false) }} />
        <div className="updatedelbtns  d-flex" style={{ position: 'relative' }}>
          <div className="updatebtn" >
            <input id="file" accept=".pdf, image/*" type="file" style={{ display: "none" }} onChange={(val) => {
              handleFileChange(val, 2);
            }}></input>
            <label htmlFor="file">

              <div style={{ color: "#0FF4BD", cursor: "pointer", marginRight: "15px" }}>Update</div>

            </label>
          </div>
          <div style={{ color: "red", cursor: "pointer" }} onClick={() => { handleDelete(singleImg) }}>Delete</div>
        </div>
        {
          singleImg?.url?.includes("pdf") ?
            <iframe src={singleImg?.url} style={{ width: '100%', height: '100%' }}></iframe> :
            <img src={singleImg?.url} style={{
              width: "97%", height: "80%", objectFit: "contain",
              top: "80px"
            }} />
        }
      </>
    </Modal>
  )
}

export default UpdateImage;