import { Modal, Button } from "antd";
import TextArea from "antd/es/input/TextArea";
import infoicon from '../asset/images/danger.svg'

const ModalSingle = ({ setOpenModal, openModal, content, handleYes, image, hasInput, setFeedBack, feedBack, fromSetting }) => {
  return (
    <Modal centered width={390} header={null} footer={null} open={openModal} className="logoutmodal" closable={false}>
      <div className="crossImage">
        <img src={image} className="text-center" />
      </div>
      <div className={hasInput == 'upcoming' ? 'logoutdesc logoutdesc1' : 'logoutdesc'}>{content}</div>
      {(hasInput == 'upcoming' || hasInput == 'pending') && (
        <TextArea
          className="reasoninput my-1 mx-0"
          placeholder="Tell us the reason"
          style={{ height: '120px', padding: '10px' }}
          onChange={(e) => {
            setFeedBack(e.target.value);
          }}
        />
      )}
      {hasInput == 'upcoming' && (
        <div className="info mt-2 mb-4">
          <img
            src={infoicon}
            style={{ width: '15px', height: '15px', color: '#505150', fontWeight: '400', marginRight: '3px' }}
          />
          Cancellation within 48 hours of installation time will incur a 10% charge of entire job plus any additional
          purchased supplies and lift charges if applicable. Cancellation within 24 hours of installation time will
          incur a 25% charge of entire job plus any additional purchased supplies and lift charges if applicable.
        </div>
      )}
      <div
        className="confirmButtons"
        style={{ justifyContent: 'space-between', display: 'flex', height: '50px', marginTop: '10px' }}
      >
        <Button
          className={
            ((hasInput == 'upcoming' || hasInput == 'pending') && feedBack) || fromSetting ? 'yesbtn' : 'yesbtn yesbtn1'
          }
          onClick={handleYes}
          disabled={
            ((hasInput == 'upcoming' || hasInput == 'pending') && feedBack) || fromSetting ? false : true
          }
        >
          Yes
        </Button>
        <Button
          className="nobtn"
          onClick={() => {
            setOpenModal(false);
          }}
        >
          No
        </Button>
      </div>
    </Modal>
  );
}

export default ModalSingle;