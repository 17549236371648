import React, { useEffect, useState, useRef } from 'react';
import DashBoardWrapper from '../../../components/wrapper/DashBoardWrapper';
import { Rate, Progress } from 'antd';
import star from '../../../asset/images/Star.svg';
import './style.less';
import { ratingDetails, getRatings } from '../../../api/getjob';
import { useSelector, useDispatch } from 'react-redux';
import SpinWrapper from '../../../components/wrapper/SpinWrapper';
import { showLoader } from '../../../redux/slices/common';
import dayjs from 'dayjs';
import handleScroll from '../../../common/helper/handleScroll';
const RatingsComp = () => {
  const { id } = useSelector((state) => state.login.profileInfo);
  const dispatch = useDispatch();
  const [ratingInfo, setRatingInfo] = useState();
  const [ratingArr, setRatingArr] = useState([]);
  const [page, setPage] = useState(1);
  const listInnerRef = useRef();
  const callApis = async () => {
    dispatch(showLoader(true));
    const resp = await ratingDetails(id);

    setRatingInfo(resp?.data?.data);

    dispatch(showLoader(false));
  };
  const callRatingDetails = async () => {
    const resp1 = await getRatings(id, page);
    const val = [...ratingArr, ...resp1?.data?.data];
    setRatingArr(val);
  };
  const handleSetPage = () => {
    setPage(ratingArr?.length / 10 + 1);
  };
  useEffect(() => {
    callApis();
  }, []);
  useEffect(() => {
    callRatingDetails();
  }, [page]);
  return (
    <div className="ratingwrapper">
      <div className="ratings">
        <div className="rleft ">
          <div className="rscore">
            <span>{Math.abs(ratingInfo?.averageRating).toFixed(1)}</span>/5
          </div>
          <div className="rhead">Based on {ratingInfo?.totalRating} rating</div>
          <div className="rrating">
            {' '}
            <Rate allowHalf value={ratingInfo?.averageRating} disabled={true} style={{ color: '#E2AE2C' }} />
          </div>
        </div>
        <div className="rright">
          <div className="rightinfo">
            <div className="progressbar">
              <div className="ratingbar">
                <div className="div1">5 star</div>
                <div className="progressb">
                  <Progress
                    percent={
                      ratingInfo?.count4to5 == 0
                        ? 0
                        : Math.abs((ratingInfo?.count4to5 / ratingInfo?.totalRating) * 100).toFixed(0)
                    }
                    showInfo={false}
                  />
                </div>
              </div>
            </div>
            <div className="progressbar">
              <div className="ratingbar">
                <div className="div1">4 star</div>
                <div className="progressb">
                  <Progress
                    percent={
                      ratingInfo?.count3to4 == 0
                        ? 0
                        : Math.abs((ratingInfo?.count3to4 / ratingInfo?.totalRating) * 100).toFixed(0)
                    }
                    showInfo={false}
                  />
                </div>
              </div>
            </div>
            <div className="progressbar">
              <div className="ratingbar">
                <div className="div1">3 star</div>
                <div className="progressb">
                  <Progress
                    percent={
                      ratingInfo?.count2to3 == 0
                        ? 0
                        : Math.abs((ratingInfo?.count2to3 / ratingInfo?.totalRating) * 100).toFixed(0)
                    }
                    showInfo={false}
                  />
                </div>
              </div>
            </div>
            <div className="progressbar">
              <div className="ratingbar">
                <div className="div1">2 star</div>
                <div className="progressb">
                  <Progress
                    percent={
                      ratingInfo?.count1to2 == 0
                        ? 0
                        : Math.abs((ratingInfo?.count1to2 / ratingInfo?.totalRating) * 100).toFixed(0)
                    }
                    showInfo={false}
                  />
                </div>
              </div>
            </div>
            <div className="progressbar">
              <div className="ratingbar">
                <div className="div1">1 star</div>
                <div className="progressb">
                  <Progress
                    percent={
                      ratingInfo?.count0to1 == 0
                        ? 0
                        : Math.abs((ratingInfo?.count0to1 / ratingInfo?.totalRating) * 100).toFixed(0)
                    }
                    showInfo={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="peopleratings"
        ref={listInnerRef}
        onScroll={() => {
          handleScroll(listInnerRef, ratingArr?.length, handleSetPage);
        }}
      >
        {ratingArr?.map((item) => {
          return (
            <div className="peoplerating">
              <div className="singlerating">
                <div className="staricon">
                  <img src={star} />
                </div>
                <div className="ratinginfo">
                  <div className="ratingname">
                    {item?.givenBy?.firstName} {item?.givenBy?.lastName}
                  </div>
                  <div className="rating">
                    {item?.rating} <Rate allowHalf disabled={true} value={item?.rating} style={{ color: '#E2AE2C' }} />
                  </div>
                </div>
              </div>
              <div className="ratingtime">{dayjs(item?.createdAt).format('MMMM DD, YYYY')}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const Ratings = () => {
  return (
    <DashBoardWrapper>
      <SpinWrapper>
        <RatingsComp />
      </SpinWrapper>
    </DashBoardWrapper>
  );
};

export default Ratings;
