import React ,{useState} from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import Fade from 'react-reveal/Fade'
import { useNavigate } from 'react-router-dom';

import Footer from '../../components/Footer';
import './processpage.less'
import aboutusimg10 from '../../asset/images/aboutusbookjpg.png';
import chooseinstalerimg from '../../asset/images/chooseinstaller.png';
import installimg from '../../asset/images/installjpg.png';
import locationimg from '../../asset/images/locationjpg.png';
import logo from '../../asset/images/Logo.svg';
import processicon1 from '../../asset/images/processicon1.svg'
import processicon2 from '../../asset/images/processicon2.svg'
import processicon3 from '../../asset/images/processicon3.svg'
import processlaptop from '../../asset/images/processlaptop.png'
import processpageimage1 from '../../asset/images/processpageimage1.png'
import processpageimage2 from '../../asset/images/processpageimg2.png'
import sucessjpg from '../../asset/images/successjpg.png'
import { setLogin } from '../../redux/slices/login';
import '../Home/Home.less';

const ProcessPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count,setCount]=useState(1);
  
  return (
    <>
      <div className="main_containerhome">
        <div className="nav_container">
          <div>
            <img src={logo} />
          </div>
          <div className="nav_option">
            <ul>
              <li style={{cursor:"pointer"}} onClick={()=>{navigate("/")}} >Home</li>
              <li  style={{cursor:"pointer"}} onClick={()=>{navigate("/aboutus")}}>About Us</li>
              <li style={{ color: '#000000', fontWeight: 600 }}>How it works</li>
              <li   style={{cursor:"pointer"}} onClick={()=>{navigate("/contactusinfo")}}>Contact Us</li>
            </ul>
            <div className="nav_btn_container">
              <Button
                className="signIn_btn"
                onClick={() => {
                  dispatch(setLogin(true))
                  navigate('/selectrole');
                }}
              >
                Sign In
              </Button>
              <Button
                className="signUp_btn"
                onClick={() => {
                  dispatch(setLogin(false))
                  navigate('/selectrole');
                }}
              >
                {' '}
                Sign Up
              </Button>
            </div>
          </div>
        </div>
        <div style={{width:"100%",borderBottom:"1px solid rgba(161, 161, 170, 1)",marginBottom:"20px"}}></div>
    <div className="processpage">
    <div className="processpage3">
      <div className="processheading">
        <div className="left">
        <h1>Our Process</h1>
        <p> It's very simple, we help find you a local professional  that has all the skills your need for your particular job. </p>
           </div>
           <div className="right">
     { count<=2 && <span onClick={() =>{setCount((prev)=>prev+1)}}><img src={processicon1}/></span>}
       {count>=1 && <span onClick={() =>{setCount((prev)=>prev-1)}}><img src={processicon2}/></span>}
           </div>
      </div>
      <div className="processsliding">
        {count==1?
        <>
        <div className="singlecard">
          <div className="img"><img src={locationimg} style={{width:"100%",height:"100%",borderRadius:"20px 20px 0px 0px"}}/></div>
          <div className="singlecardinfo">
          <h5> Choose the Location of your install. </h5>
          <p> We use GPS to locate what skilled installers are in the area of your job!</p>
          </div>
        </div>
        <div className="singlecard">
          <div className="img"><img src={installimg} style={{width:"100%",height:"100%",borderRadius:"20px 20px 0px 0px"}}/></div>
          <div className="singlecardinfo">
          <h5>   Tell us the Type of install</h5>
          <p>    Give us details on the type of install you need; window graphics, wall graphics, vehicle wrap, banners or something else...we can install almost all of it - the more details you give us the better!</p>
          </div>
        </div>
        <div className="singlecard">
          <div className="img"><img src={chooseinstalerimg} style={{width:"100%",height:"100%",borderRadius:"20px 20px 0px 0px"}}/></div>
          <div className="singlecardinfo">
          <h5>   Choose an installer</h5>
          <p>  Rest assured that our installers have experience, insurance and have been approved to do your job.  You can review their skills and profile and you will have the opportunity to choose the best fit for your job.</p>
          </div>
        </div>
        </>:<>
        <div className="singlecard">
          <div className="img"><img src={aboutusimg10} style={{width:"100%",height:"100%",borderRadius:"20px 20px 0px 0px"}}/></div>
          <div className="singlecardinfo">
          <h5>    Book your installer</h5>
          <p>    Once you have selected and booked your installer, you will now be able to communicate with them through instant messaging on this platform or the mobile app.</p>
          </div>
        </div>
      
      </>}
      </div>
     </div>
     <div className="getstartedbtn " onClick={()=>{navigate("/selectrole")}}><Button>Get Started</Button></div>

      <div className="processpage2">
        <div className="left">
          <Fade left>
          <img src={processpageimage2}/>
          </Fade>
        </div>
        <div className="right">
        <h1>Download our app. After you sign up your profile will be saved and you can view all of your jobs on the go or at your computer. Like everything else on Myinstallr, the choices are yours.</h1>
        <div className="rightinfo">
          <h6>Apple IOS is available on the APP Store, simply search "Myinstallr"</h6>
            <hr style={{borderColor:"rgba(186, 186, 186, 1)"}}/>
        </div>
        <div className="rightinfo">
          <h6>Android App is available at the google Play store, simply search "Myinstallr"</h6>
        </div>
        </div>
      </div>
   
     <div className="processpage1">
        <div className="processinfoleft">
          <div className="processinfoheading">All on the choices on Myinstallr are yours.</div>
          <p>Just as you have chosen your graphics / wallpaper and or Ad campaign, now you can  choose your installr as well. Select  through applicants to your job to find the best fit for you.</p>
             <div className="singup" onClick={()=>{navigate("/selectrole")}}><Button>Sign Up</Button></div>
        </div>
        <div className="rightcontent">
        {/* <Fade top>
        <div className="processinforight">
          <Fade right>
          <div className="processcontent">
          <h4>Office ipsum you must be</h4>
          <p>The more info you give us on your job the easier it will be find the right installr for you and correctly price the job ahead of time.</p>
          </div>
         
          <div className="processimage">
          <img src={processpageimage1}/>
          </div>
          </Fade>
        </div>
        </Fade>
        <div className="processinforight">
          <Fade right>
          <div className="processcontent">
          <h4>Office ipsum you must be</h4>
          <p>Let us know about your graphics, tell us the type, whatever you know about it add it to the info. The more we know the better we can help.</p>
          </div>
         
          <div className="processimage">
          <img src={processpageimage1}/>
          </div>
          </Fade>
        </div> */}
        <Fade right style={{width:"100%"}}>
        <div className="processinforight">
          <img src={processlaptop}/>
          {/* <div className="processcontent">
          <h4>Office ipsum you must be</h4>
          <p>Once we have matched you with the installr of your choice, we are sure your install will be a success and that the process was easy and seamless.</p>
          </div>
          <div className="processimage">
          <img src={sucessjpg}/>
          </div> */}

        </div>
        </Fade>
        </div>
      </div>
     
    </div>
        <Footer />
      </div>
     
    </>

  );
};

export default ProcessPage;
