import axios from 'axios';
import message from 'antd/es/message';
import { getHeaders } from '../utils/get-headers';

let instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, 
});
instance.interceptors.request.use(function (config) {
  config.headers = getHeaders();
  return config;
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if ([401, 403].includes(error.response?.status)) {
      message.error(error?.response?.data?.message);
    }
    if ([400].includes(error.response?.data?.status)) {
      message.error(error?.response?.data?.message);
    }
    if ([500, 501, 502, 503].includes(error.response?.data?.status)) {
      message.error('Internal server error. Please try again!');
    }
    return Promise.reject(error);
  }
);

export default instance;
