import React from 'react'
import contactusimg from '../../asset/images/contactusimg.png'
import DashBoardWrapper from '../../components/wrapper/DashBoardWrapper'
import './style.less'
const ContactUs=()=>{
  return(
    <DashBoardWrapper>
    <div className="contactus">
    <div className="img"><img src={contactusimg}/></div>
    <div className="mail  text-center d-flex  mx-auto mb-3 mt-4">support@myinstallr.ca</div>
    < a className="emailref mx-auto mt-1 mb-3" href="mailto:support@myinstallr.ca" style={{ color: "black", fontWeight: "bold !important", textAlign: "center", fontSize: "15px" ,textDecoration:"none"}}><div className="sendmailbtn">Email</div></a>
    </div>
    </DashBoardWrapper>
  )
}

export default ContactUs