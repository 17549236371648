import React from 'react';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import './style.less';
import CardForm from './CardForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CardDetailsInfo = ({setPercentInc,save,inJobs,setAddCardModal,inSettings,setLoading,getAllCards}) => {
  return (
    <div>
      <Elements stripe={stripePromise}>
        <CardForm setPercentInc={setPercentInc} save={save} inJobs={inJobs} setAddCardModal={setAddCardModal} inSettings={inSettings}
         setLoading={setLoading} getAllCards={getAllCards}/>
      </Elements>
    </div>
  )
};

export default CardDetailsInfo;