import { createSlice } from '@reduxjs/toolkit';

const createInfoSlice = createSlice({
  name: 'createinfo',
  initialState: {
    createObj: {},    
  },
  reducers: {
    setCreateObject: (state, action) => {
      state.createObj = action.payload;
    },
  },
});


export const {  setCreateObject } = createInfoSlice.actions;
export default createInfoSlice.reducer;
