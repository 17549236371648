import instance from '../config/axios';
import { message } from 'antd';
export const callNotificationListApi = async (page) => {
  try {
    const response = await instance.get(`notification/list?page=${page}&limit=10`);
    return response;
  } catch (error) {
    return error
  }
}

export const callNotificationCountApi = async () => {
  try {
    const response = await instance.get(`notification/count `);
    return response;
  } catch (error) {
    if (409 == error?.response?.status) {
      localStorage.clear();
      window.location.reload();
      setTimeout(() => { message.error("you have cancelled your job 3 times so your job has been deleted") }, 1000);
    }
    return error
  }
}
export const callNotificationRead = async (id) => {
  try {
    const response = await instance.put(`notification/read`, { ids: [id] });
    return response;
  } catch (error) {
    return error
  }
}

export const callUnReadMessageApi = async () => {
  try {
    const response = await instance.get(`chat/unread-messages`);
    return response;
  } catch (error) {
    return error;
  }
};