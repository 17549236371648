import React from 'react';
import Star from '../../../asset/images/Star.svg'
import crossicon from '../../../asset/images/crossicon.svg'

const ViewAll = ({ contractorList, setViewAll, setShowPopUp, setSingleContractor }) => {
  return (
    <>
      <div className="contractorheading d-flex justify-content-between " >
        <div className="contractortitle" style={{ color: "#505150", fontWeight: "500", fontSize: "16px" }}>Contractor Applications</div>
        <div className="viewallbtn" style={{ color: "#0FF4BD", fontWeight: "500", cursor: "pointer" }} onClick={() => { setViewAll(false) }}><img src={crossicon} /></div>
      </div>
      <div className="contractorslist">
        {contractorList.map((item) => {
          return (
            <div key={item?._id} className="singlecontractor" style={{
              boxShadow: "0px 14px 34px 0px #00000008 !important", background: "#fff", border: "1px solid #849290", borderOpacity: 0.1,
            }} onClick={() => { setShowPopUp(true); setSingleContractor(item); setViewAll(false) }}>
              <div className="userimg"><img src={item?.appliedBy?.profileImage?.url} /></div>
              <div className="contractorname my-2" style={{ color: "#20222A" }}>{item?.appliedBy?.firstName} {item?.appliedBy?.lastName}</div>
              <div className="ratings d-flex justify-content-center">
                <div className="staricon mr-1"><img src={Star} /></div>
                <div className="ratingnumber" style={{ color: "gray", fontWeight: "500" }}>{Math.abs(item?.appliedBy?.averageRating).toFixed(2)}</div>
                <div className="ratingnumber1 ml-1" style={{ color: "gray" }}>{item?.appliedBy?.totalRating} Ratings</div>
              </div>
              <div className="messagebtn">Message</div>
              <div className="declinebtn" style={{ color: "#20222A", fontWeight: "500" }}>Decline</div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default ViewAll