import React from 'react'

function AddressInfo({item,locationgreen,calendergreen,timecircle}) {
  return (
    <div className="row1">
    <div className="locationinfo"><span><img src={locationgreen} />
    </span>
    {<span>{item?.locationInfo?.address} </span>}
    <span className="calenderimage"><img src={calendergreen} /></span><span>{item?.jobStartDate}</span><span className="clockimage"><img src={timecircle} /></span>
    <span>{item?.jobStartTime}</span></div>
    <div className="para2lift ">Lift Required:{item?.isLiftRequired ? <span style={{ color: "#19B792", fontWeight: "700", marginLeft: "2px" }}>Yes</span>
     : <span style={{ color: "#DB524E", fontWeight: "700", marginLeft: "2px" }}>No</span>}</div>
  </div>
  )
}

export default AddressInfo