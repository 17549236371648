import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import CreateJob from '../../../components/CreateJob';
import DashBoardWrapper from '../../../components/wrapper/DashBoardWrapper';
import SpinWrapper from '../../../components/wrapper/SpinWrapper';
import CardsList from '../../../pages/client/Jobs/CardsList';
import AddLocation from './AddLocation';
import PostJobPopup from './PostJobPopup';
import './style.less';
import crossicon from '../../../asset/images/crossicon.svg';
import sucessicon from '../../../asset/images/Successful.svg';
import { showLoader } from '../../../redux/slices/common';
import { setProfile } from '../../../redux/slices/login';

import { useOutsideAlerter } from '../../../utils/outsideClick';
import { locationSaveApi, getJobCost, createJobApi, locationListApi } from '../../../api/createjob';
import { getProfileApi } from '../../../api/detailsapi';

const Job = () => {
  const [isValid, setIsValid] = useState(false);
  const [jobObj, setJobObj] = useState({});
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [flag, setFlag] = useState(false);
  const [address, setAddress] = useState({});
  const [popupModal, setPopupModal] = useState(false);
  const [postJobModal, setPostJobModal] = useState(false);
  const [approxCost, setApproxCost] = useState();
  const [clear, setClear] = useState(false);
  const [sucessPopup, setSucessPopup] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentModalPopup, setPaymentModalPopup] = useState(false);
  const [selectedCard, setSelectedCard] = useState();
  const [halfPayment, setHalfPayment] = useState();
  const [disclaimer, setDisclaimer] = useState();
  const [locations, setLocations] = useState([]);
  const [approxCostAlertText,setApproxCostAlertText]=useState();
  const dispatch = useDispatch();
  const wrapperRef = useRef();
  const profileInfo = useSelector((state) => state.login.profileInfo);
  const handleCreateJob = async () => {
    try {
      setPaymentModalPopup(false);
      dispatch(showLoader(true));
      const val = jobObj?.jobStartDate + ' ' + jobObj?.jobStartTime;
      const utcTime = new Date(val).toISOString();
      const resp = await createJobApi({ ...jobObj, cardId: selectedCard?.id, jobStartUtcTime: utcTime });
      if (resp?.status == 200) {
        setSucessPopup(true);
      }
      dispatch(showLoader(false));
    } catch (err) {
      dispatch(showLoader(false));
    }
  };
  const handleClose = () => {
    setClear(true);
    setSucessPopup(false);
  };
  const saveLocationList = async () => {
    setOpenLocationModal(false);
    const resp = await locationSaveApi(address);
    if (resp?.status == 200) {
      setAddress(resp?.data?.data);
      setLocations([...locations, resp?.data?.data]);
    }
  };
  const callLocationApi = async () => {
    const resp = await locationListApi();
    if (resp?.status == 200) setLocations([...resp?.data?.data]);
  };

  const closeModal = () => {
    setOpenLocationModal(false);
    setPaymentModalPopup(false);
  };
  const handleVechicleWrapJob = async () => {
    dispatch(showLoader(true));
    try {
      if (jobObj?.jobStartDate) {
        let resp=null;
        if( jobObj?.jobStartTime)
        {
        const val = jobObj?.jobStartDate + ' ' + jobObj?.jobStartTime;
        const utcTime = new Date(val).toISOString();
         resp = await createJobApi({ ...jobObj, jobStartUtcTime: utcTime });
        }
        else
        {
           resp = await createJobApi({ ...jobObj});
        }
        if (resp?.status == 200) {
          setSucessPopup(true);
        }
      } else {
        const resp = await createJobApi({ ...jobObj });
        if (resp?.status == 200) {
          setSucessPopup(true);
        }
      }
      dispatch(showLoader(false));
    } catch (err) {
      dispatch(showLoader(false));
    }
  };
  const getJobCostVal = async () => {
    if (jobObj?.nextCatName == 'Type of Vehicle Wraps') {
      handleVechicleWrapJob();
    } else {
      setPopupModal(true);
      const resp = await getJobCost(jobObj);
      setApproxCost(resp?.data?.data?.totalAmount);
      setDisclaimer(resp?.data?.data?.disClaimer);
      setHalfPayment(resp?.data?.data?.initialPaymentAmount);
      setApproxCostAlertText(resp?.data?.data?.approxCostAlertText)
    }
  };

  const callGetProfile = async () => {
    const resp = await getProfileApi();
    if (resp?.status === 200) {
      dispatch(setProfile(resp?.data?.data));
    }
  };

  useEffect(() => {
    callGetProfile();
    callLocationApi();
  }, []);

  useOutsideAlerter(wrapperRef, openLocationModal, closeModal);
  return (
    <SpinWrapper>
      <div className="createjob">
        <div className="userinfo d-flex w-100">
          <div className="userinfo1">
            Hello <span style={{ color: '#0FF4BD', fontWeight: '600' }}>{profileInfo.firstName}!</span>
          </div>
          <div className="userinfo2 d-flex justify-content-between">
            <div className="userheading">Welcome! Let’s post a job according to your preference</div>
            <div
              className="userheadingbtn"
              onClick={() => {
                getJobCostVal();
              }}
            >
              <Button disabled={!isValid} className={isValid ? 'buttonbtnd' : 'buttonbtn'}>
                Post Job
              </Button>
            </div>
          </div>
        </div>
        <div className="jobinfo">
          <CreateJob
            inDashboard={true}
            clear={clear}
            setIsValid={setIsValid}
            locations={locations}
            setLocations={setLocations}
            handleCreateJob={handleCreateJob}
            setJobObj={setJobObj}
            setOpenLocationModal={setOpenLocationModal}
            setFlag={setFlag}
            flag={flag}
            jobObj={jobObj}
            address={address}
            setClear={setClear}
            setSucessPopup={setSucessPopup}
          />
          {openLocationModal && (
            <Modal
              centered
              className="addlocationmodal"
              closable={false}
              header={null}
              footer={null}
              style={{ top: '', overflow: 'hidden !important' }}
              open={openLocationModal}
            >
              <div ref={wrapperRef}>
                <AddLocation
                  setOpenLocationModal={setOpenLocationModal}
                  setAddress={setAddress}
                  address={address}
                  saveLocationList={saveLocationList}
                />
              </div>
            </Modal>
          )}
        </div>
      </div>
      {popupModal && (
        <Modal
          centered
          className="addlocationmodal"
          closable={false}
          header={null}
          footer={null}
          style={{ top: '', overflow: 'hidden !important' }}
          open={popupModal}
        >
          <div ref={wrapperRef}>
            <PostJobPopup
              setPopupModal={setPopupModal}
              setPostJobModal={setPostJobModal}
              postJobModal={postJobModal}
              approxCost={approxCost}
              setPaymentModalPopup={setPaymentModalPopup}
              handleCreateJob={handleCreateJob}
              approxCostAlertText={approxCostAlertText}
            />
          </div>
        </Modal>
      )}
      {sucessPopup && (
        <Modal
          centered
          className="sucesspopup"
          closable={false}
          header={null}
          footer={null}
          style={{ top: '', overflow: 'hidden !important' }}
          open={sucessPopup}
        >
          <div className="sucesspopup  d-flex flex-column justify-content-center m-auto">
            <div
              className="crossicon"
              style={{ right: '0rem', cursor: 'pointer' }}
              onClick={() => {
                handleClose();
              }}
            >
              <img src={crossicon} style={{ width: '30px', height: '30px' }} />
            </div>
            <div className="costimg m-1">
              <img src={sucessicon} />
            </div>
            <div className="sucessheading m-1" style={{ fontSize: '20px' }}>
              Job Posted Successfully!
            </div>
            <div className="content m-1">
              {jobObj?.nextCatName == 'Type of Vehicle Wraps'
                ? 'Thank you for reaching out, because vehicle wraps are unique, one of our wrap experts will email you within 24 hours if they need more info and to finalize a cost'
                : 'Please allow some time for a few local installrs to review your posting, and then you will be able to select your installr.'}
            </div>
            <Button
              className="termscontentbtn m-3"
              onClick={() => {
                handleClose();
              }}
            >
              Done
            </Button>
          </div>
        </Modal>
      )}
      {paymentModalPopup && (
        <Modal
          centered
          className="paymentmodal"
          closable={false}
          header={null}
          footer={null}
          style={{ top: '', overflow: 'hidden !important' }}
          open={paymentModalPopup}
        >
          <>
            <img
              src={crossicon}
              style={{
                position: 'absolute',
                right: '1rem',
                top: '3px',
                /* top: -1rem; */
                width: '30px',
                height: '30px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setPaymentModalPopup(false);
              }}
            />
            <div ref={wrapperRef} className="h-100">
              <h5 className="text-center">Make Payment</h5>
              <div className="paymentinfodiv ">
                <div className="paymentinfodiv1">
                  <div className="paymentinfo">
                    <div className="transactioninfo">
                      <div className="transactiondetails d-flex justify-content-between">
                        <div className="trasactionheading">Contractor Price</div>
                        <div className="trasactionheading1">{Math.abs(approxCost).toFixed(2)}</div>
                      </div>
                      <div className="transactiondetails d-flex justify-content-between">
                        <div className="trasactionheading">Advance Payment</div>
                        <div className="trasactionheading1">{Math.abs(halfPayment).toFixed(2)}</div>
                      </div>
                      <div className="totalamount d-flex  justify-content-between">
                        <div className="totalamount1">Total Amout</div>
                        <div className="price">{Math.abs(halfPayment).toFixed(2)}</div>
                      </div>
                    </div>
                    <div clasName="paynow w-100">
                      <Button
                        className={selectedCard ? 'button2' : 'button1'}
                        onClick={() => {
                          handleCreateJob();
                        }}
                      >
                        Pay Now
                      </Button>
                    </div>
                    <div className="disclaimer" style={{ fontSize: '12px' }}>
                      <span style={{ fontSize: '16px', fontWeight: '500', color: 'black' }}>Disclaimer: </span>
                      {disclaimer}
                    </div>
                  </div>
                </div>
                <div className="paymentinfodiv2">
                  <CardsList
                    setSelectedCard={setSelectedCard}
                    selectedCard={selectedCard}
                    paymentModal={paymentModal}
                    setPaymentModal={setPaymentModal}
                  />
                </div>
              </div>
            </div>
          </>
        </Modal>
      )}
    </SpinWrapper>
  );
};

const CreateJobs = () => {
  return (
    <DashBoardWrapper>
      <Job />
    </DashBoardWrapper>
  );
};
export default CreateJobs;
