import React from 'react'
import mobilesettings from '../../asset/images/mobileiconsettings.svg'

function EditProfileWrapper({children,setMobileScreen,isClient}) {
  return (
    <div className="subformcontent" onClick={()=>{setMobileScreen(true)}} >
    <div style={{ color: "#6B6B6B", fontSize: "16px" }} className="mb-2">Phone number </div>
     <div className={isClient?"phonenumber":" phonenumber phonenumber1"}>
      <img src={mobilesettings}/>
      {children}
      </div>
      </div>
      
  )
}

export default EditProfileWrapper