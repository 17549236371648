const handleRouting = (navigate, profileInfo, message) => {
  if (!profileInfo?.isMobileVerified && profileInfo?.userType == 1) navigate('/verifycontractor');
  else if (profileInfo?.status == 'Rejected' && profileInfo?.isMobileVerified && profileInfo?.userType == 1)
    message?.error(
      'We regret to inform you that your profile has been rejected by MyInstallr, please reach out to support@myinstallr.ca for more information.'
    );
  else if (
    !profileInfo?.firstName &&
    !profileInfo?.lastName &&
    !profileInfo?.locationDetails?.address &&
    profileInfo?.userType == 1
  )
    navigate('/contractorprofile');
  else if (profileInfo?.status == 'Pending' && profileInfo?.isMobileVerified && profileInfo?.userType == 1)
    navigate('/request');
  else if (profileInfo?.status == 'Approved' && profileInfo?.userType == 1 && profileInfo?.agreeToStripeConsent)
    navigate('/newjob');
  else if (profileInfo?.status == 'Approved' && profileInfo?.userType == 1 && profileInfo?.bankingDetails != {})
    navigate('/contractorprofile', { state: { val: 3 } });
  else if (profileInfo?.status == 'Approved' && profileInfo?.userType == 1) navigate('/newjob');
  else if (!profileInfo?.isMobileVerified && profileInfo?.userType == 0) navigate('/clientprofile');
  else if (profileInfo?.isMobileVerified && profileInfo?.userType == 0 && !profileInfo?.firstName)
    navigate('/clientprofile', { state: { value: 2 } });
  else if (profileInfo?.isMobileVerified && profileInfo?.userType == 0 && !profileInfo?.isCardDetailsUpdated)
    navigate('/clientprofile', { state: { value: 3 } });
  else if (
    profileInfo?.isMobileVerified &&
    profileInfo?.userType == 0 &&
    profileInfo?.firstName &&
    profileInfo?.isCardDetailsUpdated
  )
    navigate('/createjob');
};
export default handleRouting;
