import { createSlice } from '@reduxjs/toolkit';

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    toaster: null,
    modal: null,
    profileAddress:null,
    loader:false,
    unReadCount:0
  },
  reducers: {
    showToast: (state, param) => {
      const { payload } = param;
      state.toaster = payload;
    },
    showModal: (state, param) => {
      const { payload } = param;
      state.modal = payload;
    },
    showUserMap: (state, param) => {
      //console.log("param>>",param)
      const { payload } = param;
      state.profileAddress = payload;
    },
    showLoader:(state,param)=>{
      const { payload } = param;
      state.loader=payload;
    },
    setUnReadCount:(state,param)=>{
      const { payload } = param;
      state.unReadCount=payload;
    }
  },
});

const { actions, reducer } = commonSlice;

export const { showToast, showModal,showUserMap,showLoader,setUnReadCount } = actions;
export default reducer;
