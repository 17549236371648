import React, {useEffect,useState}from 'react';
import { Rate,Progress } from 'antd';
import star from '../../../asset/images/Star.svg'
import './style.less'
import {ratingDetails,getRatings} from '../../../api/getjob'
import { useSelector,useDispatch } from 'react-redux';
import SpinWrapper from '../../../components/wrapper/SpinWrapper'
import {showLoader} from '../../../redux/slices/common'
import dayjs from 'dayjs';
export const RatingsComp=({userId})=>{
  
  const {id}=useSelector(state=>state.login.profileInfo);
  const dispatch=useDispatch();
  const [ratingInfo,setRatingInfo]=useState();
  const [ratingArr,setRatingArr]=useState();
  const callApis=async()=>{
    dispatch(showLoader(true))
    const resp=await ratingDetails(id||userId);
    const resp1=await getRatings(id|| userId);
    setRatingInfo(resp.data.data);
    setRatingArr(resp1.data.data);
    dispatch(showLoader(false))
  }

  useEffect(()=>{
  callApis();
  },[])

  return(
    <SpinWrapper>
   <div className="ratingwrapper">
   <div className="ratings">
    <div className="rleft ">
      <div className="rscore"><span>{ratingInfo?.averageRating}</span>/5</div>
      <div className="rhead">Based on {ratingInfo?.totalRating} {ratingInfo?.totalRating>1?"Ratings":"Rating"}</div>
      <div className="rrating"> <Rate allowHalf value={ratingInfo?.averageRating} style={{color:"#E2AE2C"}} disabled={true}  /></div>
    </div>
    <div className="rright">
      <div className="rightinfo">
     <div className="progressbar">
       <div className="ratingbar">
        <div className="div1">5 star</div>
         <div className="progressb"><Progress percent={ratingInfo?.count4to5==0?0:Math.abs(ratingInfo?.count4to5/ratingInfo?.totalRating*100).toFixed(0)} showInfo={false}/>
         </div>
         </div>
         </div>
        <div className="progressbar">
       <div className="ratingbar">
        <div className="div1">4 star</div>
         <div className="progressb"><Progress percent={ratingInfo?.count3to4==0?0:Math.abs(ratingInfo?.count3to4/ratingInfo?.totalRating*100).toFixed(0)} showInfo={false}/>
         </div>
         </div>
         </div>
         <div className="progressbar">
       <div className="ratingbar">
        <div className="div1">3 star</div>
         <div className="progressb"><Progress percent={ratingInfo?.count2to3==0?0:Math.abs(ratingInfo?.count2to3/ratingInfo?.totalRating*100).toFixed(0)} showInfo={false}/>
         </div>
         </div>
         </div>
         <div className="progressbar">
       <div className="ratingbar">
        <div className="div1">2 star</div>
         <div className="progressb"><Progress percent={ratingInfo?.count1to2==0?0:Math.abs(ratingInfo?.count1to2/ratingInfo?.totalRating*100).toFixed(0)} showInfo={false}/>
         </div>
         </div>
         </div>
         <div className="progressbar">
       <div className="ratingbar">
        <div className="div1">1 star</div>
         <div className="progressb"><Progress percent={ratingInfo?.count0to1==0?0:Math.abs(ratingInfo?.count0to1/ratingInfo?.totalRating*100).toFixed(0)} showInfo={false}/>
         </div>
         </div>
         </div>
         </div>
    </div>
   </div>
   <div className="peopleratings">
    {ratingArr?.map((item)=>{
      return(
        <div className="peoplerating" key={item?._id}>
    <div className="singlerating">
     <div className="staricon"><img src={star}/></div>
     <div className="ratinginfo">
      <div className="ratingname">{item?.givenBy?.firstName} {item?.givenBy?.lastName}</div>
      <div className="rating">{Math.abs(item?.rating).toFixed(1)} <Rate value={item?.rating} style={{color:"#E2AE2C"}} disabled={true} allowHalf/></div>
     </div>
    </div>
    <div className="ratingtime">{dayjs(item?.createdAt).format("MMMM DD, YYYY")}</div>
   </div>
      )
    })}
   </div>
   </div>
   </SpinWrapper>
  )
}


export default RatingsComp;