import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { Tabs, Spin } from 'antd';
import DashBoardWrapper from '../../../components/wrapper/DashBoardWrapper';
import locationgreen from '../../../asset/images/locationgreen.svg';
import calendergreen from '../../../asset/images/calendargreen.svg';
import timecircle from '../../../asset/images/timecircle.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.less';
import { joblist, onGoingJoblistApi } from '../../../api/clientjobs'
import InfoData from './InfoData';
import { useDispatch,useSelector } from 'react-redux';
import { showLoader } from '../../../redux/slices/common';
import AddressInfo from '../../../pages/contractor/Jobs/AddressInfo';
import handleScroll from '../../../common/helper/handleScroll';

const JobDetails = () => {
  const location = useLocation();
  const loader = useSelector(state => state?.common?.loader);
  const [activeTab, setActiveTab] = useState(location?.state?.tab ? "3" : location?.state?.activeTab ? `${location?.state?.activeTab}` : "1");
  const [status, setStatus] = useState(location?.state?.tab || location?.state?.activeTab == 3 ? "Past" : location?.state?.activeTab == 2 ? "UpComing" : "Pending");
  const [showSingleItem, setShowSingleItem] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [onGoingList, setOnGoingList] = useState([]);
  const [currentSlider, setCurrentSlider] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [makePayment, setMakePayment] = useState(false);
  const [page, setPage] = useState(1);
  const listInnerRef = useRef();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const tabsArr = [
    {
      key: "1",
      tabName: "Pending",
      value: "Pending",
    },
    {
      key: "2",
      tabName: "UpComing",
      value: "Upcoming",
    },
    {
      key: "3",
      tabName: "Past",
      value: "Past",
    },
  ];
  
  const handleTabChange = (e) => {
    setJobList([]);
    setActiveTab(e);
    const res = tabsArr.find((data) => data.key === e);
    setStatus(res?.tabName);
  };
  const handleNextClick = (item) => {
    navigate(`/jobsdata?id=${item?._id}`)
    if (activeTab == 2)
      setStatus("UpComing")
  }
  const handleNextClickOngoing = (item) => {
    navigate(`/jobsdata?id=${item?._id}`)
  }

  const handleBack = () => {
    setShowSingleItem(false);
    callJobsApi();

  }
  const handleSetPage = () => {
    setPage(jobList.length / 10 + 1);
  }
  const callJobsApi = async () => {
    setLoading(true)
    if (status == "UpComing" && page == 1) {
      const resp = await onGoingJoblistApi();
      setOnGoingList(resp?.data?.data)
    }
    if (status != "OnGoing") {
      const resp = await joblist(status, page);
      if (page == 1)
        setJobList([...resp?.data?.data]);
      else
        setJobList([...jobList, ...resp?.data?.data]);
      dispatch(showLoader(false));
    }
    else {
      const resp = await joblist("UpComing", page);
      if (page == 1)
        setJobList([...resp?.data?.data]);
      else
        setJobList([...jobList, ...resp?.data?.data]);
      dispatch(showLoader(false))
    }
    setLoading(false)
  }
  useEffect(() => {
    callJobsApi();
  }, [page])
  useEffect(() => {
    setPage(1);
    setJobList([]);
    callJobsApi();
  }, [status])
  useEffect(() => {
    if (location?.state?.tab) {
      setStatus("Past");
      setActiveTab("3");
    }
    if (location?.state?.activeTab) {
      setActiveTab(`${location?.state?.activeTab}`)
    }
  }, [])
  return (
    <>
      {!showSingleItem ? <div className="jobsdashboard">
        {(activeTab == 2 && onGoingList.length > 0) && <UpComingInfo onGoingList={onGoingList} handleNextClick={handleNextClickOngoing} setStatus={setStatus} setCurrentSlider={setCurrentSlider} currentSlider={currentSlider} />}
        <div className="" >
          <Tabs
            activeKey={activeTab}
            onChange={handleTabChange}
            className="jobs-tab"
            defaultActiveKey="1"
            
          >
            {tabsArr.map((tab) => {
              return (
                <>
                  <Tabs.TabPane tab={tab.value} style={{ textTransform: 'capitalize' }} key={tab.key} >
                    <JobInfoDetails handleNextClick={handleNextClick} activeTab={activeTab} jobList={jobList}
                      length={onGoingList.length} status={status} loader={loader} ref={listInnerRef}
                      loading={loading} handleSetPage={handleSetPage} />
                  </Tabs.TabPane>
                </>
              );
            })}
          </Tabs>
        </div>
      </div> : <div className="infodata">
        <InfoData item={ location?.state?.id} handleBack={handleBack} status={status} setIsEdit={setIsEdit} isEdit={isEdit} makePayment={makePayment} setMakePayment={setMakePayment} />
      </div>}
    </>

  )
}

const UpComingInfo = ({ onGoingList, handleNextClick, setStatus, currentSlider, setCurrentSlider }) => {
  const sliderRef = useRef(null);
  return (
    <div className={"slider1"}>
      <Slider ref={sliderRef}>
        {onGoingList.map((item, index) => {
          return (
            <div key={index} className="upcomingjobs " onClick={() => {
              setStatus("Ongoing"); handleNextClick(item);
            }}>
              <div className="row1 d-flex justify-content-between" >
                <div className="info d-flex mb-2">
                  <div className="mr-2 mb-3"><img src={item?.assignedToUser?.profileImage?.url} style={{ width: "50px", height: "50px", borderRadius: "10px" }} /></div>
                  <div className="para1">
                    <div className="rowfirst">Assigned to</div>
                    <div className="rowsecond1">{item?.assignedToUser?.firstName} {item?.assignedToUser?.lastName}</div>
                  </div>
                </div>
                <div className="para2">
                  <div className="rowfirst" style={{ textAlign: "center" }}>{item?.createdByUser?.locationDetails?.name}</div>
                  <div className="rowsecond">Ongoing</div>
                </div>
              </div>
              <div className="row1 ">
                <div className="locationinfo d-flex"><span><img src={locationgreen} /></span><span>{item?.assignedToUser?.locationDetails?.address}</span><span className="calenderimage mx-2" style={{
                  borderLeft: " 2px solid grey",
                  marginLeft: "5px",
                  marginRight: "5px",
                  padding: "0px 5px"
                }}><img src={calendergreen} /></span><span>{item?.jobStartDate}</span><span className="clockimage mx-2" ><img src={timecircle} /></span><span>{item?.jobStartTime}</span></div>
              </div>
            </div>
          )
        })
        }
      </Slider>
    </div>
  )
}

const JobInfoDetails = forwardRef(({ handleNextClick, activeTab, jobList, length, status, loading, handleSetPage }, ref) => {
  return (
    <>
      {jobList?.length > 0 ?
        <div ref={ref} onScroll={() => handleScroll(ref, jobList?.length, handleSetPage)} className={length > 0 && (status == "UpComing" || status == "Ongoing") ? ' joblistongoing' : 'joblists'}>
          {jobList.map((item) => {
            return (
              <div className="singlejobs my-2" onClick={() => { handleNextClick(item) }}>
                <div className="row1 d-flex" >
                  <div className="info d-flex ">
                    {activeTab != 1 && <div className="mr-2"><img src={item?.assignedToUser?.profileImage?.url} style={{ width: "65px", height: "65px", borderRadius: "50%" }} /></div>}
                    <div className="para1">
                      <div className="rowfirst">Assigned to</div>
                      {activeTab == "1" ? <div className="rowsecond">Pending</div> : <div className="rowsecondname">{item?.assignedToUser?.firstName} {item?.assignedToUser?.lastName}</div>}
                    </div>
                  </div>
                  <div className="para2">
                    <div className="rowfirst">{item?.locationInfo?.name}</div>
                    <div className={item?.jobStatus == "Pending" ? "rowsecond" : item?.jobStatus == "UpComing" ? "rowsecond2 rowsecond" : item?.jobStatus == "Completed" ? "rowsecond1 rowsecond" : "rowsecond rowpayment"}>{item?.jobStatus == "Pending" ? "Pending" : item?.jobStatus == "UpComing" ? "Upcoming" : item?.jobStatus == "Completed" ? "Completed" : item?.jobStatus == "Cancelled" ? "Cancelled" : "Payment Pending"}</div>
                  </div>
                </div>
                <div className="row1 d-flex">
                  <div className="para1">
                    <div className="services">Service</div>
                    <div className="images">
                      <img src={item?.category?.categoryImage?.url} style={{ width: "40px", height: "40px", borderRadius: "4px", margin: "5px" }} />
                    </div>
                  </div>
                </div>
                <AddressInfo item={item} locationgreen={locationgreen} calendergreen={calendergreen} timecircle={timecircle} />
              </div>
            )
          })
          }
        </div> : ""}
      <Spin spinning={loading} style={{ width: "100%" }}>
      </Spin>
    </>

  )
})

const Jobs = () => {
  return (
    <DashBoardWrapper>
      <JobDetails />
    </DashBoardWrapper>)
}
export default Jobs;