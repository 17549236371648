import instance from '../config/axios';
export const joblistContractor = async (val, page) => {
  try {
    const response = await instance.get(`job/list-for-contractor?status=${val}&page=${page}`);
    return response;
  } catch (error) {
    return error
  }
};

export const onGoingJoblistApi = async (val) => {
  try {
    const response = await instance.get(`job/list-ongoing-job-for-contractor`);
    return response;
  } catch (error) {
    return error
  }
};

export const cancelJobAppointMentApiContractor = async (id, feedback) => {
  try {
    const response = await instance.put(`job/cancel-request`, { jobId: id, reason: feedback });
    return response;
  } catch (error) {
    return error
  }
}

export const jobStartApi = async (id) => {
  try {
    const response = await instance.put(`job/start/${id}`);
    return response;
  } catch (error) {
    return error
  }
}

export const jobCompleteApi = async (id, obj) => {
  try {
    const response = await instance.put(`job/complete-job/${id}`, obj);
    return response;
  } catch (error) {
    return error
  }
}