import React from "react";
const JobIcon = ({ val }) => {
  return (
      <>
      { !val?
     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
     <g id="Work">
     <g id="Iconly/Bold/Work">
     <g id="Work_2">
     <path id="Vector" d="M21.8099 12.8152C21.8819 12.8557 21.8963 12.9416 21.8992 12.9743L21.9 12.9874L21.8199 18.1519C21.8199 20.212 20.2237 21.892 18.2138 21.995L18.018 22H5.97199C3.93659 22 2.27678 20.3844 2.17503 18.3501L2.17009 18.1519L2.10005 12.9874C2.10005 12.9874 2.10005 12.8659 2.1901 12.8152C2.27014 12.7646 2.37019 12.8254 2.38019 12.8355C4.4012 14.1924 6.83242 15.0937 9.38369 15.4684C9.74387 15.519 10.1041 15.2861 10.2041 14.9216C10.4105 14.1431 11.0477 13.6138 11.8273 13.5507L11.985 13.5443H12.015C12.8654 13.5443 13.5758 14.0912 13.7959 14.9216C13.8959 15.2861 14.2561 15.519 14.6163 15.4684C17.1676 15.0937 19.5988 14.1924 21.6198 12.8355C21.6198 12.8355 21.6398 12.8254 21.6598 12.8152C21.6999 12.795 21.7599 12.7849 21.8099 12.8152ZM11.995 14.9924C11.619 14.9924 11.3018 15.2818 11.2516 15.6498L11.2446 15.7519V17.0583C11.2446 17.4836 11.5848 17.8177 11.995 17.8177C12.3802 17.8177 12.6897 17.5369 12.7386 17.1625L12.7454 17.0583V15.7519C12.7454 15.3367 12.4152 14.9924 11.995 14.9924ZM13.2856 2C14.7864 2 16.017 3.1443 16.2071 4.61264H18.1881C20.2891 4.61264 22 6.34428 22 8.47085C22 8.47085 21.94 9.3711 21.92 10.6248C21.918 10.724 21.8699 10.8212 21.7909 10.88C21.3097 11.2354 20.8694 11.5291 20.8294 11.5493C19.1686 12.6632 17.2386 13.447 15.1826 13.8369C15.0485 13.8632 14.9165 13.7934 14.8484 13.6739C14.2721 12.6754 13.1956 12.0253 11.995 12.0253C10.8024 12.0253 9.71586 12.6683 9.12256 13.6678C9.05353 13.7853 8.92346 13.8531 8.7904 13.8278C6.75138 13.4369 4.82141 12.6541 3.17059 11.5594L2.21011 10.8911C2.13007 10.8405 2.08004 10.7493 2.08004 10.6481C2.05003 10.1316 2 8.47085 2 8.47085C2 6.34428 3.71086 4.61264 5.81191 4.61264H7.78289C7.97299 3.1443 9.2036 2 10.7044 2H13.2856ZM13.2856 3.51898H10.7044C10.034 3.51898 9.46373 3.9848 9.30365 4.61264H14.6863C14.5263 3.9848 13.956 3.51898 13.2856 3.51898Z" fill="white" fillOpacity="0.6"/>
     </g>
     </g>
     </g>
     </svg>
     :
    <svg width="24" height="24" viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg">
    <g id="Work">
    <g id="Iconly/Bold/Work">
    <g id="Work_2">
    <path id="Vector" d="M21.8099 12.8152C21.8819 12.8557 21.8963 12.9416 21.8992 12.9743L21.9 12.9874L21.8199 18.1519C21.8199 20.212 20.2237 21.892 18.2138 21.995L18.018 22H5.97199C3.93659 22 2.27678 20.3844 2.17503 18.3501L2.17009 18.1519L2.10005 12.9874C2.10005 12.9874 2.10005 12.8659 2.1901 12.8152C2.27014 12.7646 2.37019 12.8254 2.38019 12.8355C4.4012 14.1924 6.83242 15.0937 9.38369 15.4684C9.74387 15.519 10.1041 15.2861 10.2041 14.9216C10.4105 14.1431 11.0477 13.6138 11.8273 13.5507L11.985 13.5443H12.015C12.8654 13.5443 13.5758 14.0912 13.7959 14.9216C13.8959 15.2861 14.2561 15.519 14.6163 15.4684C17.1676 15.0937 19.5988 14.1924 21.6198 12.8355C21.6198 12.8355 21.6398 12.8254 21.6598 12.8152C21.6999 12.795 21.7599 12.7849 21.8099 12.8152ZM11.995 14.9924C11.619 14.9924 11.3018 15.2818 11.2516 15.6498L11.2446 15.7519V17.0583C11.2446 17.4836 11.5848 17.8177 11.995 17.8177C12.3802 17.8177 12.6897 17.5369 12.7386 17.1625L12.7454 17.0583V15.7519C12.7454 15.3367 12.4152 14.9924 11.995 14.9924ZM13.2856 2C14.7864 2 16.017 3.1443 16.2071 4.61264H18.1881C20.2891 4.61264 22 6.34428 22 8.47085C22 8.47085 21.94 9.3711 21.92 10.6248C21.918 10.724 21.8699 10.8212 21.7909 10.88C21.3097 11.2354 20.8694 11.5291 20.8294 11.5493C19.1686 12.6632 17.2386 13.447 15.1826 13.8369C15.0485 13.8632 14.9165 13.7934 14.8484 13.6739C14.2721 12.6754 13.1956 12.0253 11.995 12.0253C10.8024 12.0253 9.71586 12.6683 9.12256 13.6678C9.05353 13.7853 8.92346 13.8531 8.7904 13.8278C6.75138 13.4369 4.82141 12.6541 3.17059 11.5594L2.21011 10.8911C2.13007 10.8405 2.08004 10.7493 2.08004 10.6481C2.05003 10.1316 2 8.47085 2 8.47085C2 6.34428 3.71086 4.61264 5.81191 4.61264H7.78289C7.97299 3.1443 9.2036 2 10.7044 2H13.2856ZM13.2856 3.51898H10.7044C10.034 3.51898 9.46373 3.9848 9.30365 4.61264H14.6863C14.5263 3.9848 13.956 3.51898 13.2856 3.51898Z" fill="#20222A"/>
    </g>
    </g>
    </g>
    </svg>  
}
      </>
  )
}
export default JobIcon;