import React ,{ useState, useEffect }from "react";
import BankingDetails from "../../../pages/contractor/profilesetup/BankingDetails";
import { getProfileApi } from '../../../api/detailsapi';
import { Spin } from "antd";
const BankInformation = () => {
  const [bankInfo, setBankInfo] = useState(null);
  const [loader, setLoader] = useState(false);
  const callEditProfileApi = async () => {
    setLoader(true)
    const resp = await getProfileApi();
    setBankInfo(resp?.data?.data?.bankingDetails);
    setLoader(false);
  }
  useEffect(() => {
    callEditProfileApi();
  }, [])
  return (

    <div className="settingcard">
      <Spin spinning={loader} style={{ backgroundColor: "#fff" }}>
        <div className="bankinfo">
          <BankingDetails isEdit={true} bankInfo={bankInfo} />
        </div>
      </Spin>
    </div>
  )
}

export default BankInformation;