import instance from '../config/axios';

export const getBusinessGraphDataApi = async (year) => {
  try {
    const response = await instance.get(`user/earning-stats?targetYear=${year}`);
    return response;
  } catch (error) {
    return error
  }
}
export const dashboardInfoApi = async () => {
  try {
    const response = await instance.get(`user/dashboard-count`);
    return response;
  } catch (error) {
    return error
  }
}
