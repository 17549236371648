import React from 'react'
import crossicon from '../../asset/images/crossicon.svg'

const TermsandConditions = ({setTermsModal,inNavigation}) => {
  return (
    <div className="privacypolicy p-2">
      <div className="heading text-center">
        Terms and Conditions
        <div
          className="crossimg"
          onClick={() => {
            setTermsModal(false);
          }}
        >
         {!inNavigation && <img src={crossicon} />}
        </div>
      </div>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '14pt' }}>
        <strong>
          <span style={{ color: 'black' }}>Terms of Usage</span>
        </strong>
      </p>
      <p style={{ marginTop: 0, marginBottom: 0 }}>
        <strong>
          <span style={{ color: 'black' }}>December 2023</span>
        </strong>
      </p>
      <p style={{ marginTop: 0, marginBottom: 0, fontSize: '11pt' }}>&nbsp;</p>
      <p style={{ marginTop: '0pt', marginBottom: '15pt', fontSize: '11pt' }}>
        <span style={{ color: '#808080' }}>
          These Terms of Usage govern your access and use of this Site. By accessing or using this Site you agree to be
          bound by these Terms of Usage and to any additional guidelines, restrictions, or rules that may be posted in
          connection with specific sections or services of this Site. All such additional posted guidelines,
          restrictions, or rules are hereby incorporated by reference into these Terms of Use.
        </span>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '15pt', fontSize: '11pt' }}>
        <span style={{ color: '#808080' }}>
          Myinstallr and other sister companies, reserves the right to make changes to this Site and to these Terms of
          Usage at any time without prior notice. You should review these Terms of Usage each time you access this Site.
        </span>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '15pt', fontSize: '11pt' }}>
        <span style={{ color: '#808080' }}>
          You also agree that we may provide all legal communications and notices to you electronically by posting them
          on our website or, at our request, by sending an e-mail to the e-mail address you provided to us when you
          registered at our website. You may withdraw your consent to receive communications from us electronically by
          contacting customer care. However, if you withdraw your consent to receive our communications electronically,
          you must discontinue your use of your account.
        </span>
      </p>
      <p style={{ marginTop: 0, marginBottom: '15pt', fontSize: '14pt' }}>
        <strong>
          <span style={{ color: 'black' }}>General</span>
        </strong>
      </p>
      {/* href="https://myinstallr.com" */}
      <p style={{ marginTop: '14pt', marginBottom: '14pt' }}>
        <span style={{ fontSize: '11pt', color: '#808080' }}>The website operated at&nbsp;</span>
        <a style={{ textDecoration: 'none' }}>
          <u>
            <span style={{ fontSize: '11pt', color: '#808080' }}>https://myinstallr.com</span>
          </u>
        </a>
        <span style={{ fontSize: '11pt', color: '#808080' }}>
          &nbsp;and such other locations as made available from time to time (collectively, the
        </span>
        <strong>
          <span style={{ fontSize: '11pt', color: '#808080' }}>&nbsp;"Website"</span>
        </strong>
        <span style={{ fontSize: '11pt', color: '#808080' }}>) and the Myinstallr Mobile Applications (the</span>
        <strong>
          <span style={{ fontSize: '11pt', color: '#808080' }}>&nbsp;"App"</span>
        </strong>
        <span style={{ fontSize: '11pt', color: '#808080' }}>
          ) and the services offered therefrom (collectively, the
        </span>
        <strong>
          <span style={{ fontSize: '11pt', color: '#808080' }}>&nbsp;"Service"</span>
        </strong>
        <span style={{ fontSize: '11pt', color: '#808080' }}>
          ) are operated by Myinstallr INC. and its corporate affiliates (collectively,
        </span>
        <strong>
          <span style={{ fontSize: '11pt', color: '#808080' }}>&nbsp;"us"</span>
        </strong>
        <span style={{ fontSize: '11pt', color: '#808080' }}>,</span>
        <strong>
          <span style={{ fontSize: '11pt', color: '#808080' }}>&nbsp;"we"</span>
        </strong>
        <span style={{ fontSize: '11pt', color: '#808080' }}>&nbsp;or the</span>
        <strong>
          <span style={{ fontSize: '11pt', color: '#808080' }}>&nbsp;"Myinstallr Companies"</span>
        </strong>
        <span style={{ fontSize: '11pt', color: '#808080' }}>.</span>
      </p>
      <p style={{ marginTop: '14pt', marginBottom: '14pt' }}>
        <strong>
          <span style={{ fontSize: '11pt', color: '#808080' }}>
            These terms of service set forth the general terms and conditions of your use of the Website, the App and
            our Services and are in addition to (not in lieu of) any other terms of use posted on
          </span>
        </strong>
        <strong>
          <span style={{ fontSize: '11pt', color: '#808080' }}>&nbsp;</span>
        </strong>
        <span style={{ fontSize: '11pt', color: '#808080' }}>at&nbsp;</span>
        <a  style={{ textDecoration: 'none' }}>
          <u>
            <span style={{ fontSize: '11pt', color: '#808080' }}>https://myinstallr.com</span>
          </u>
        </a>
        <span style={{ fontSize: '11pt', color: '#808080' }}>,</span>
        <strong>
          <span style={{ fontSize: '11pt', color: '#808080' }}>
            &nbsp;in connection with the App, and linked from our Terms and Conditions page at
          </span>
        </strong>
        <span style={{ fontSize: '11pt', color: '#808080' }}>&nbsp;</span>
        <a style={{ textDecoration: 'none' }}>
          <u>
            <span style={{ fontSize: '11pt', color: '#808080' }}>https://myinstallr.com/termsandconditions</span>
          </u>
        </a>
        <strong>
          <span style={{ fontSize: '11pt', color: '#808080' }}>
            , which together constitute the entire Agreement (the "Agreement") between us and you ("you"). By accessing
            or using the Service, you (together with all persons accessing or using the Service, collectively, the
            "Users") signify that you have read, understand, and agree to be bound by this Agreement in all respects
            with respect to the Website, the App, our provision of the Service, and your use of them.
          </span>
        </strong>
      </p>
      <p style={{ marginTop: '14pt', marginBottom: '14pt', fontSize: '11pt' }}>
        <strong>
          <span style={{ color: '#808080' }}>
            YOU MAY NOT USE THE SERVICES IF YOU ARE UNDER THE AGE OF MAJORITY IN THE JURISDICTION IN WHICH YOU ARE
            RESIDENT (I.E., IF YOU ARE A MINOR).
          </span>
        </strong>
      </p>
      <p style={{ marginTop: '14pt', marginBottom: '14pt', fontSize: '11pt' }}>
        <strong>
          <span style={{ color: '#808080' }}>
            PLEASE READ THIS AGREEMENT CAREFULLY AS IT CONTAINS IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS,
            REMEDIES AND OBLIGATIONS. THESE INCLUDE, BUT ARE NOT LIMITED TO, VARIOUS LIMITATIONS AND EXCLUSIONS, AND
            INDEMNITIES.
          </span>
        </strong>
      </p>
      <ol type="1" style={{ margin: 0, paddingLeft: '0pt' }}>
        <li
          style={{
            marginTop: '14pt',
            marginLeft: '31.35pt',
            padding: '0pt 4.65pt 0pt 4.65pt',
            fontSize: '11pt',
            color: '#808080',
          }}
        >
          <strong>About the Service</strong>. The Service enables Users who are seeking help (
          <strong>&quot;Clients&quot;</strong>) with various graphic installation services, including home and business
          to connect through the Website or App with third party sub-contractor installrs (the&nbsp;
          <strong>&quot;Installrs&quot;</strong>) who would like to complete the task (the&nbsp;
          <strong>&quot;Install&quot;</strong>) for the Client. The Service provides a platform that connects Clients
          and Installrs only. We do not provide or perform Services. You must become a registered User by creating a
          User Account (as defined below) to request assistance and connect with an installr. You may access the Service
          from a computer or mobile device.
          <ol type="a" style={{ marginRight: '0pt', marginLeft: '0pt', paddingLeft: '0pt' }}>
            <li style={{ marginLeft: '31.05pt', marginBottom: '14pt', padding: '0pt 4.95pt 0pt 4.95pt' }}>
              Requesting Installation Services. A User may use the Service to post a request to have a particular
              Graphics Installation provided (a&nbsp;&quot;Request&quot;). Such Requests must be clearly worded,
              correctly provide for the relevant category of service, and be a fair and accurate description of the work
              to be done. When making a request, the Requesting User is solely responsible for confirming the accuracy
              of all information provided, including, the work requested to be done and the location at which the
              Requesting User will require such Installation Services (the&nbsp;&quot;Location&quot;). Each Request is
              limited to one Service to be provided by one or more Installrs. If a User wishes to obtain help with more
              than Install, the User must make a separate Request for each Graphics Install. As a Requesting User, by
              confirming your Request, you understand and agree that you have made an offer, for which a Professional
              Installr has the power to accept or reject in his or her sole discretion. Once you have confirmed your
              Request, you may cancel the job (&quot;Job&quot;) without incurring a fee only if additional expenses have
              not been purchased or ordered (such as lifts, supplies etc.) – fees occur within 48 hours and 24 hours of
              install.
            </li>
          </ol>
        </li>
      </ol>
      <p style={{ marginTop: '14pt', marginLeft: '72pt', marginBottom: '14pt', fontSize: '11pt' }}>&nbsp;</p>
      <ol start="2" type="a" style={{ margin: 0, paddingLeft: '0pt' }}>
        <li
          style={{
            marginTop: '14pt',
            marginLeft: '67.56pt',
            padding: '0pt 4.44pt 0pt 4.44pt',
            fontSize: '11pt',
            color: '#808080',
          }}
        >
          <strong>Your Responsibilities</strong>. By making a Request, you represent and warrant that you have the right
          to grant the Installr access to the Premises to perform the Graphics Install, will be present at the Premises
          or will have a representative who is 18 years old or older present at the Premises at all times while the
          Install is being performed. You agree that you shall not assist the Installr or otherwise participate in the
          Job in any way and that Myinstallr shall have no liability to you for any damages or losses, including any
          personal injury, death or property damage, that may arise from your participation in the Job.
        </li>
        <li
          style={{
            marginLeft: '66.43pt',
            marginBottom: '14pt',
            padding: '0pt 5.57pt 0pt 5.57pt',
            fontSize: '11pt',
            color: '#808080',
          }}
        >
          <strong>Timing and The Graphics Installr</strong>. There are two timelines available to Requesting Users:
          (i)&nbsp;<strong>&quot;On-Demand&quot;</strong>, and (ii)&nbsp;<strong>&quot;Later&quot;</strong>. We will
          take reasonable efforts, but are under no obligation, to connect you with a Professional Graphics Installr who
          is available to provide the Services provided in your Request, subject to your location, the Graphics Install
          requested, the availability of Installr willing to accept the Graphics Install Requested by you, and other
          conditions beyond our control. We do not guarantee that your Request will be accepted within the timeline
          prescribed for the respective service category, or at all. Any references within the Service to a Graphics
          Installr being rated, verified, vetted, licensed (where applicable), qualified, or similar language only
          indicates that the Graphics Installr has completed the relevant registration process and does not endorse,
          certify, or guarantee any User or their identity, trustworthiness, suitability, or ability to provide the
          Graphics Install. When interacting with other Clients, including Graphics Installrs, you should exercise due
          diligence, caution and common sense to protect your personal safety and property. NEITHER THE MYINSTALLR
          COMPANIES NOR OUR AFFILIATES OR LICENSORS ARE RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY
          USER OF THE SERVICE AND YOU HEREBY RELEASE THE MYINSTALLR COMPANIES AND OUR AFFILIATES OR LICENSORS FROM ANY
          LIABILITY RELATED THERETO, WHETHER DIRECT OR INDIRECT. THE MYINSTALLR COMPANIES AND OUR AFFILIATES AND
          LICENSORS WILL NOT BE LIABLE FOR ANY CLAIM, INJURY, OR DAMAGE ARISING IN CONNECTION WITH YOUR USE OF THE
          SERVICE.
        </li>
      </ol>
      <p style={{ marginTop: 0, marginBottom: 15, fontSize: '11pt' }}>&nbsp;</p>
      <p style={{ marginTop: 0, marginBottom: 15 }}>
        <span style={{ fontSize: '11pt', color: '#808080' }}>
          All the terms and conditions and/or any transactions occurring online at&nbsp;
        </span>
        <a  style={{ textDecoration: 'none' }}>
          <u>
            <span style={{ fontSize: '11pt', color: '#808080' }}>https://www.myinstallr.com</span>
          </u>
        </a>
        <span style={{ fontSize: '11pt', color: '#808080' }}>
          on the myinstallr APP or by phone are subject to the laws Canada and the United States of America, as well as
          the province or state they are being performed in. Myinstallr Inc. and other sister companies are not
          responsible for the delay and/or damages resulting from Machinery Breakdown, Acts of God, and from other
          actions, both governmental and otherwise, including but not limited to war, riot, seizure, and embargo. The
          tools available on&nbsp;
        </span>
        <a style={{ textDecoration: 'none' }}>
          <u>
            <span style={{ fontSize: '11pt', color: '#808080' }}>www.myinstallr.com</span>
          </u>
        </a>
        <span style={{ fontSize: '11pt', color: '#808080' }}>
          . to create documents are the property of Myinstallr Inc., and its affiliated companies. The Buyer&rsquo;s
          order shall be deemed an acceptance of the Seller&apos;s terms. The Terms and conditions shall in all respects
          be governed by local law.
        </span>
        <span style={{ fontSize: '11pt', color: '#808080' }}>&nbsp;</span>
      </p>
      <p style={{ marginTop: 0, marginBottom: 15, fontSize: '14pt' }}>
        <strong>
          <span style={{ color: 'black' }}>Reservations</span>
        </strong>
      </p>
      <p style={{ marginTop: 0, marginBottom: 15, fontSize: '11pt' }}>
        <span style={{ color: '#808080' }}>
          All orders are subject to acceptance by Myinstallr Inc., and other sister companies. We reserve the right to
          reject any order at any time without recourse. We also reserve the right to change the price at any time even
          after we receive the order if additional information has been obtained. For additional service on the location
          - we will charge you as a new project and the amount will be agreed upon payment. All rental equipment needs
          to be paid in full prior to the installation date, a receipt of services will be provided. Minimum Visit
          Charge - $435.00 CAD Waiting time - fee $125.00 CAD per hour
        </span>
      </p>
      <p style={{ marginTop: 0, marginBottom: 15, fontSize: '14pt' }}>
        <strong>
          <span style={{ color: 'black' }}>Payment</span>
        </strong>
      </p>
      <p style={{ marginTop: 0, marginBottom: 0, fontSize: '11pt' }}>
        <span style={{ color: '#808080' }}>
          Myinstallr Inc. and other sister companies accept Visa and MasterCard credit and debit card payments, Apple
          Pay, Google Pay.
        </span>
      </p>
      <p style={{ marginTop: 0, marginBottom: 0, fontSize: '11pt' }}>
        <span style={{ color: '#808080' }}>
          If the Clients credit card is declined, Myinstallr Inc and other sister companies will not process the Clients
          order. In the event that Myinstallr and other sister companies must commence legal action to enforce any terms
          of this agreement, the Buyer agrees to pay reasonable legal fees and costs.
        </span>
        <span style={{ color: '#808080' }}>&nbsp;</span>
      </p>
      <p style={{ marginTop: 0, marginBottom: 15, fontSize: '15pt' }}>
        <strong>
          <span style={{ fontFamily: 'Poppins', color: '#808080' }}>&nbsp;</span>
        </strong>
      </p>
      <p style={{ marginTop: 0, marginBottom: 15, fontSize: '14pt' }}>
        <strong>
          <span style={{ color: 'black' }}>Warranty</span>
        </strong>
      </p>
      <p style={{ marginTop: 0, marginBottom: 0, fontSize: '11pt', color: '#808080' }}>
        Myinstallr Inc and other sister companies neither make any explicit warranty nor imply or accept any
        responsibility other than possible replacement of the products and services that Buyer is buying. Easy Way
        Install NYC Inc and other sister companies may but does not guarantee the replacement of a supplied defective
        product, or credit the amount of the purchase price.
      </p>
      <p style={{ marginTop: 0, marginBottom: '15pt', fontSize: '15pt', fontFamily: 'Poppins', color: '#808080' }}>
        &nbsp;
      </p>
      <span style={{ fontSize: '15pt' }}>
        <strong>
          <span style={{ fontFamily: 'Poppins', color: '#808080' }}>&nbsp;</span>
        </strong>
      </span>
      <p style={{ marginTop: 0, marginBottom: 15, fontSize: '14pt' }}>
        <strong>
          <span style={{ color: 'black' }}>Limitation of Liability</span>
        </strong>
      </p>
      <p style={{ marginTop: 0, marginBottom: 0, fontSize: '11pt' }}>
        <span style={{ color: '#808080' }}>
          Myinstallr Inc., and other sister companies will not be liable in contract or in tort (including negligence)
          to the customer for incidental or consequential damages, arising out of or resulting from anybody&rsquo;s
          performance or nonperformance of our obligations. Myinstallr Inc., and other sister companies shall not be
          liable to anyone for any kind of financial losses, cost, expenses, damages and/or other economic damages. The
          Client agrees that the Client will not hold Myinstallr Inc., and other sister companies responsible for any
          and all loss, cost, expense, and damages (including legal costs) on account of any and all manner of claims,
          demands, actions, and proceedings that may be instituted against Myinstallr Inc., and other sister companies,
          and its affiliated company(S) on grounds alleging that the said work violates any copyrights, trademarks,
          service marks, or is scandalous, or invades any person's right to privacy or other personal rights.
        </span>
      </p>
      <p style={{ marginTop: 0, marginBottom: 15, fontSize: '15pt' }}>
        <strong>
          <span style={{ fontFamily: 'Poppins', color: '#808080' }}>&nbsp;</span>
        </strong>
      </p>
      <p style={{ marginTop: 0, marginBottom: 15, fontSize: '14pt' }}>
        <strong>
          <span style={{ color: 'black' }}>Cancellations and Refunds</span>
        </strong>
      </p>
      <p style={{ marginTop: 0, marginBottom: 0, fontSize: '11pt' }}>
        <span style={{ color: '#808080' }}>
          Myinstallr Inc., and other sister companies and its affiliated company will not refund any money for the
          orders that were cancelled under the following scenarios:
        </span>
      </p>
      <p style={{ marginTop: 0, marginBottom: 0, fontSize: '11pt' }}>
        <span style={{ color: '#808080' }}>
          Cancellation within 48 hours of installation time will incur a 10% charge of entire job plus any additional
          purchased supplies and lift charges if applicable.
        </span>
      </p>
      <p style={{ marginTop: 0, marginBottom: 0, fontSize: '11pt' }}>
        <span style={{ color: '#808080' }}>
          Cancellation within 24 hours of installation time will incur a 25% charge of the entire job plus any
          additional purchased supplies and lift charges if applicable.&nbsp;
        </span>
      </p>
      <p style={{ marginTop: 0, marginBottom: 0, fontSize: '11pt' }}>
        <span style={{ color: '#808080' }}>&nbsp;</span>
      </p>
      <p style={{ marginTop: 0, marginBottom: 0, fontSize: '11pt' }}>
        <span style={{ color: '#808080' }}>&nbsp;</span>
      </p>
    </div>
  );
}

export default TermsandConditions;