import React, { useState, useEffect } from 'react';
import unfilled from '../../../asset/images/unfilled.svg';
import fiiled from '../../../asset/images/comfilled.svg';
import Progress from 'antd/es/progress';
import page1 from '../../../asset/images/page1.png';
import page2 from '../../../asset/images/page2.png';
import page3 from '../../../asset/images/page3.png';
import { message } from 'antd'
import './styles.less'
import { useNavigate, useLocation } from 'react-router-dom';
import ContractorProfile from './ContractorProfile';
import TravelRadius from './TravelRadius';
import Contractorprofilepic from './Contractorprofilepic';
import Questions from './Questions';
import BankingDetails from './BankingDetails';
import { createProfileClient } from '../../../api/detailsapi';
import { setProfile } from '../../../redux/slices/login';
import { useDispatch } from 'react-redux';

const ContractorProfileSetUp = () => {
  const [percentInc, setPercentInc] = useState(20);
  const [count, setCount] = useState(0);
  const [details, setDetails] = useState({});
  const [info, setInfo] = useState(false)
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const progressBarPercent = () => {
    if (count == 3) {
      setPercentInc(50)
    } else if (count == 4) {
      setPercentInc(81);
    } else {
      if (state == null)
        setPercentInc(20)
    }
  }

  const save = async (values) => {
    setDetails({ ...details, ...values });
  }

  const callSaveApi = async () => {
    let val = details;
    val = { ...val, locationDetails: { name: '', apartmentNumber: '', address: details?.address + ',' + details?.city + ',' + details?.province + ',' + details?.postalCode, city: details?.city, province: details?.province, postalCode: details?.postalCode } }
    const resp = await createProfileClient(val);
    if (resp?.status === 200) {
      message.success(resp?.data?.message);
      dispatch(setProfile(resp?.data?.data));
      navigate("/request");
    }
  }

  useEffect(() => {
    progressBarPercent()
  }, [count]);

  useEffect(() => {
    if (state != null) {
      setCount(5);
      setPercentInc(81);
    }
  }, [])

  useEffect(() => {
    if (info) {
      callSaveApi()
    }
  }, [info])

  const handleAddCount = (e) => {
    setCount(e);
  }

  return (
    <>
      <div className="client-select-container">
        <div className="contractor_center_container" style={count === 1 ? { gap: "3.75rem" } : null}>
          <div className="progress_container">
            <div className={'modal-card-content'}>
              <div className="position-relative">
                <img src={page1} style={{ position: 'absolute', zIndex: '1', left: '20%', top: '-250%', height: "30px", width: '30px' }} />
                <img
                  src={fiiled}
                  style={{ position: 'absolute', zIndex: '1', left: '20%', top: '-20%', height: "30px", width: '30px' }}
                  className="progressbarimg progressbarimg1"
                />

                <img src={page2} style={{ position: 'absolute', zIndex: '1', left: '50%', top: '-250%', height: "30px", width: '30px' }} />
                <img
                  src={percentInc >= 50 ? fiiled : unfilled}
                  style={{ position: 'absolute', zIndex: '1', left: '50%', top: '-20%', height: "30px", width: '30px' }}
                  className="progressbarimg"
                />

                <img src={page3} style={{ left: '80%', position: 'absolute', zIndex: '1', top: '-250%', height: "30px", width: '30px' }} />
                <img
                  src={percentInc <= 89 ? unfilled : percentInc >= 91 ? fiiled : unfilled}
                  style={{ left: '80%', position: 'absolute', zIndex: '1', top: '-20%', height: "30px", width: '30px' }}
                  className="progressbarimg3"
                />
                <Progress
                  showInfo={false}
                  strokeColor={'black'}
                  percent={percentInc}
                  style={{ height: '5px', left: '12px' }}
                />
                <div style={{ marginTop: '19px' }}>
                  <div className="role_content" style={{ position: 'absolute', zIndex: '1', left: '15%', }}>
                    Profile Details
                  </div>
                  <div
                    className={'role_content'}
                    style={{ position: 'absolute', zIndex: '1', left: '45%' }}
                  >
                    Question(s)
                  </div>
                  <div
                    className={'role_content'}
                    style={{ position: 'absolute', zIndex: '1', left: '75%' }}
                  >
                    Bank Details
                  </div>
                </div>
              </div>
            </div>
          </div>
          {count === 0 &&
            <div className="contractor-content-data">
              <ContractorProfile handleAddCount={handleAddCount} save={save} />

            </div>
          }
          {count === 1 &&
            <div className="map-content-data">
              <TravelRadius handleAddCount={handleAddCount} save={save} details={details} />
            </div>
          }
          {count === 2 &&
            <div className="contractor-content-data">
              <Contractorprofilepic handleAddCount={handleAddCount} save={save} />

            </div>}
          {count === 3 &&
            <div className="contractor-content-data">
              <Questions handleAddCount={handleAddCount} save={save} setInfo={setInfo} />

            </div>}
          {count === 5 &&
            <div className="contractor-content-data">
              <BankingDetails handleAddCount={handleAddCount} save={save} />

            </div>}
        </div></div>
    </>
  )
}
export default ContractorProfileSetUp;