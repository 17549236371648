import React from 'react';
import Button from 'antd/es/button';
import request from '../../asset/images/request.png';
import './Verify.less';
import { useNavigate } from 'react-router-dom';
const Request = () => {
  const navigate = useNavigate();
  return (
    <div className="main_container">
      <div className="center_container text-center" style={{ height: "89vh" }}
      >
        <div className="num_container ">
          <img src={request} />
          <div className="req_header">
            <h1>Your request has been generated.</h1>
            <div className="para_container">
              <p>We will notify you after a successful background check. Look for  Myinstallr</p>
            </div>
          </div>
          <Button
            htmlType="submit"
            onClick={() => {
              localStorage.clear();
              localStorage.setItem("role", "1");
              navigate('/contractorsignin');
            }}
            className="login_form_button"
            style={{ marginTop: '0.43rem' }}
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Request;
