import React from 'react';
import Questions from '../../../pages/contractor/profilesetup/Questions'
const QuestionsModal = ({ data, setData, setQuestionsModal }) => {
  return (
    <div className="client-select-container d-block">
      <Questions data={data} setData={setData} setQuestionsModal={setQuestionsModal} />
    </div>
  )

}
export default QuestionsModal;