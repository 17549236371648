import React from 'react';
import smallladder from '../../../asset/images/smallladder.svg';
import ladder from '../../..//asset/images/ladder.svg';
import ladder1 from '../../../asset/images/ladder1.svg';
import lift1 from '../../../asset/images/lift.svg';
import lift2 from '../../../asset/images/lift1.svg';
import lift3 from '../../../asset/images/lift2.svg';
import lift4 from '../../../asset/images/lift3.svg';

const getLift = (liftSize) => {
  switch (liftSize) {
    case 1:
      return (
        <>
          <img src={smallladder} className="m-2" />
          <span>0 to 8 ft ( Small Ladder )</span>
        </>
      );
    case 2:
      return (
        <>
          <img src={ladder} className="m-2" />
          <span>8 to 18 ft ( Ladder )</span>
        </>
      );
    case 3:
      return (
        <>
          {' '}
          <img src={ladder1} className="m-2" />
          <span>18 to 20 ft ( Ladder )</span>
        </>
      );
    case 4:
      return (
        <>
          {' '}
          <img src={lift1} className="m-2" />
          <span>20 to 45 ft ( Lift )</span>
        </>
      );
    case 5:
      return (
        <>
          {' '}
          <img src={lift2} className="m-2" />
          <span>45 to 60 ft ( Lift )</span>
        </>
      );
    case 6:
      return (
        <>
          {' '}
          <img src={lift3} className="m-2" />
          <span>60 to 80 ft ( Lift )</span>
        </>
      );
    case 7:
      return (
        <>
          {' '}
          <img src={lift4} className="m-2" />
          <span> 80 to 125 ft ( Lift )</span>
        </>
      );
  }
};
const LiftInfo = ({ liftSize }) => {
  console.log(liftSize, '....liftsixe');
  return getLift(Number(liftSize));
};

export default LiftInfo;
