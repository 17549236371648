import React, { useRef,useEffect, useState  } from 'react';
import DashBoardWrapper from '../../../components/wrapper/DashBoardWrapper';
import { getJobsApi } from '../../../api/getjob';
import calendergreen from '../../../asset/images/calendargreen.svg';
import timecircle from '../../../asset/images/timecirclegreen.svg';
import locationgreen from '../../../asset/images/locationgreen.svg';
import { Button, Modal } from 'antd';
import SingleJobInfo from './singleJobInfo';
import SpinWrapper from '../../../components/wrapper/SpinWrapper'
import SucessPopUp from './SucessPopUp';
import { showLoader } from '../../../redux/slices/common'
import { useDispatch } from 'react-redux';
import './style.less';
import { setProfile } from '../../../redux/slices/login';
import { getProfileApi } from '../../../api/detailsapi';
import handleScroll from '../../../common/helper/handleScroll';
import { useNavigate } from 'react-router-dom';

const NewJob = () => {
  const [jobList, setJobList] = useState([]);
  const [showSingleItem, setShowSingleItem] = useState(false);
  const [popupModal, setPopupModal] = useState(false);
  const [userLocation, setUserLocation] = useState();
  const [radius, setRadius] = useState();
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const listInnerRef = useRef();
  const navigate = useNavigate();
  const callApi = async (userloc, radius) => {
    dispatch(showLoader(true))
    const resp = await getJobsApi(userloc || userLocation, radius, page);
    const val = [...jobList, ...resp?.data?.data];
    setJobList(val);
    dispatch(showLoader(false))
  }

  const callGetProfile = async () => {
    const resp = await getProfileApi();
    if (resp?.status === 200) {
      setUserLocation(resp?.data?.data?.userLocation);
      dispatch(setProfile({ firstName: resp?.data?.data?.firstName, lastName: resp?.data?.data?.lastName, profileImage: resp?.data?.data?.profileImage?.url, userLocation: resp?.data?.data?.userLocation, currentRadius: resp?.data?.data?.currentRadius, id: resp?.data?.data?._id ,bankDetails:resp?.data?.data?.bankingDetails,agreeToStripeConsent:resp?.data?.data?.agreeToStripeConsent,isMobileVerified:resp?.data?.data?.isMobileVerified,locationDetails:resp?.data?.data?.locationDetails,status:resp?.data?.data?.status,userType:"1"}));
      callApi(resp?.data?.data?.userLocation, resp?.data?.data?.currentRadius);
      setRadius(resp?.data?.data?.currentRadius)
    }
  }

 


  
  const handleNextClick = (item) => {
    navigate(`/contjobsdata?id=${item._id}&screen=1`)
  }

  const handleYes = () => {
    setPopupModal(false)
    setShowSingleItem(false)
  }
  const handleSetPage = () => {
    setPage(jobList.length / 10 + 1);
  }

  useEffect(() => { callGetProfile(); }, []);
  useEffect(() => { if (page != 1) callApi(userLocation, radius); }, [page]);
  return (
    <SpinWrapper>
      {(!showSingleItem  && jobList?.length > 0) && <div ref={listInnerRef} className="newjobslist" onScroll={() => handleScroll(listInnerRef, jobList?.length, handleSetPage)} >
        {jobList.map((item) => {
          return (
            <div className="singlejob" onClick={() => { handleNextClick(item) }} key={item?._id}>
              <div className="row1 d-flex" >
                <div className="para1">
                  <div className="rowfirst">{item?.createdByUser?.firstName} {item?.createdByUser?.lastName}</div>
                  <div className="rowsecond">{item?.locationInfo?.name}</div>
                </div>
                <div className="para2">
                  <div className="ammount">${Math.abs(item.contractorApproxCost).toFixed(2)}</div>
                  <div className="approxcost">Approx Cost</div>
                </div>
              </div>
              <div className="row1 d-flex">
                <div className="para1">
                  <div className="services">Service</div>
                  <div className="images">
                    <img src={item?.category?.categoryImage?.url} style={{ width: "40px", height: "40px", borderRadius: "4px", margin: "5px" }} />
                  </div>
                </div>
                <div className="para2lift ">Lift Required:{item.isLiftRequired ? <span style={{ color: "#19B792", fontWeight: "700", marginLeft: "2px" }}>Yes</span> : <span style={{ color: "#DB524E", fontWeight: "700", marginLeft: "2px" }}>No</span>}</div>
              </div>
              <div className="row1">
                <div className="locationinfo"><span><img src={locationgreen} /></span><span>{item?.locationInfo?.address}</span><span className="calenderimage"><img src={calendergreen} /></span><span>{item?.jobStartDate}</span><span className="clockimage"><img src={timecircle} /></span><span>{item?.jobStartTime}</span></div>
                <div className="locationbtns">
                  <Button className="notbtn" onClick={() => { handleNextClick(item) }}>Not interested</Button>
                  <Button className="applybtn" onClick={() => { handleNextClick(item) }} >Apply</Button>
                </div>
              </div>
            </div>
          )
        })}
      </div>}
      {showSingleItem && <div className="singlecontractorinfo"> <SingleJobInfo /> </div>}
      {popupModal && <Modal
        centered
        className='addlocationmodal'
        closable={false}
        header={null}
        footer={null}
        style={{ top: '', overflow: "hidden !important" }}
        open={popupModal}
      >
        <SucessPopUp setPopupModal={setPopupModal} handleYes={handleYes} />
      </Modal>}
    </SpinWrapper>
  )
}

const NewJobs = () => {
  return (
    <DashBoardWrapper>
      <NewJob />
    </DashBoardWrapper>
  )
}
export default NewJobs;