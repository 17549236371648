import React from 'react';
import Button from 'antd/es/button';
import email from '../../asset/images/Email@3x.svg';
import './Reset.less';
import { useNavigate ,useLocation} from 'react-router-dom';
const PassResetMail = () => {
  const navigate = useNavigate();
  const {state}=useLocation();
  return (
    <div className="main_container">
      <div className="center_container">
        <div className="num_container ">
          <img src={email} width={313} height={267} />
          <div className="req_header">
            <h1>Email has been sent</h1>
            <div className="para_container">
              <p>Please check your inbox. We have sent a new password to your email address.</p>
            </div>
          </div>
          <Button
            htmlType="submit"
            onClick={() => {
              if(localStorage.getItem("role")==0)
              navigate('/clientsignin');
            else
            navigate('/contractorsignin')
            }}
            className="login_form_button"
            style={{ marginTop: '0.43rem' }}
          >
           Sign In
          </Button>
          <div className="sign_in_text">
            Didn&rsquo;t receive password?
            <a className="text-dark fw-bold" style={{ textDecoration: 'none' }}>
              <span
                style={{
                  color: '#000',
                  fontFamily: 'Poppins',
                  fontSize: '1.125rem',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '2.5rem',
                  cursor:"pointer"
                }}
                onClick={() => {
                  if(localStorage.getItem("role")==0)
                  navigate('/resetpassword',{state:{val:state.val}});
                else
                navigate('/resetpassword',{state:{val:state.val}})
                }}
              >
                Resend
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PassResetMail;