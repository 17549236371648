import React from 'react';
import '../Home/Home.less';
import logo from '../../asset/images/Logo.svg';
import Button from 'antd/es/button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLogin } from '../../redux/slices/login';
import Footer from '../../components/Footer';
import aboutusimg1 from '../../asset/images/aboutusimg1.png';
import aboutusimg2 from '../../asset/images/aboutusimg2.png';
import aboutusimg3 from '../../asset/images/aboutusimg3.png';
import aboutusimg9 from '../../asset/images/aboutusimage8.svg';
import aboutusimg10 from '../../asset/images/aboutusbookjpg.png';
import installimg from '../../asset/images/installjpg.png';
import locationimg from '../../asset/images/locationjpg.png';
import chooseinstalerimg from '../../asset/images/chooseinstallerjpg.png';
import qualityjpg from '../../asset/images/Quality.png';
import { Card } from 'antd';
import Fade from 'react-reveal/Fade';

import './AboutUs.less';
const AboutUs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <div className="main_containerhome">
        <div className="nav_container">
          <div>
            <img src={logo} />
          </div>
          <div className="nav_option">
            <ul>
              <li
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/');
                }}
              >
                Home
              </li>
              <li style={{ color: '#000000', fontWeight: 600 }}>About Us</li>
              <li
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/process');
                }}
              >
                How it works
              </li>
              <li
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/contactusinfo');
                }}
              >
                Contact Us
              </li>
            </ul>
            <div className="nav_btn_container">
              <Button
                className="signIn_btn"
                onClick={() => {
                  dispatch(setLogin(true));
                  navigate('/selectrole');
                }}
              >
                Sign In
              </Button>
              <Button
                className="signUp_btn"
                onClick={() => {
                  dispatch(setLogin(false));
                  navigate('/selectrole');
                }}
              >
                {' '}
                Sign Up
              </Button>
            </div>
          </div>
        </div>
        <div style={{ width: '100%', borderBottom: '1px solid rgba(161, 161, 170, 1)' }}></div>
        <div className="aboutusinfo">
          <div className="aboutusinfo1">
            <div className="aboutusinfo1left">
              <Fade left>
                <img src={aboutusimg1} />
              </Fade>
            </div>
            <div className="aboutusinfo1right">
              <div className="aboutusheading">We are visual graphics installrs…That’s it! </div>
              <div className="aboutusheadinginfo">
                That means we install all forms of visual graphics onto whatever surface you need: Window Graphics, Wall
                Graphics, Vinyl decals, Vehicle Wraps, Wallpaper: You name it, we can do it.
              </div>
              <div className="aboutusheadinginfo">
                You have graphics and signage needs, so our platform is designed to help you easily estimate an install
                cost, Schedule the install, Choose a skilled local installr, and get your job done correctly.
              </div>
              <div className="explorebtn d-block">
                <Button
                  onClick={() => {
                    navigate('/selectrole');
                  }}
                >
                  Explore More
                </Button>
                <div className="aboutusimg2">
                  <Fade right>
                    <img src={aboutusimg2} />
                  </Fade>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutusinfo2">
            <div className="aboutusper">
              <div className="aboutuspercount">
                30<span>+</span>
              </div>
              <div className="aboutusperheading">Years Experience</div>
            </div>
            <div className="aboutusper">
              <div className="aboutuspercount">
                1000<span>+</span>
              </div>
              <div className="aboutusperheading">Projects Completed</div>
            </div>
          </div>
          <div className="aboutusinfo3">
            <div className="leftcontent">All the choices on Myinstallr are yours.</div>
            <div className="rightcontent">
              You made the right choice by choosing Myinstallr, with over 30 years experience in the graphics
              installation industry, we allow YOU the opportunity to select from our team of skilled technicians to
              successfully complete your projects - from start to finish.
            </div>
          </div>
          <div className="aboutusinfo4">
            <Fade left>
              <div className="aboutinfocard">
                <div className="aboutinfoimg mb-3">
                  <img src={aboutusimg3} />
                </div>
                <h3 className="aboutinfoheading m-1 mb-3">Installers in Every City</h3>
                <div className="aboutusinfocontent m-1">
                  We have installers everywhere. EVERYWHERE! Our skilled professionals have years of experience in sign
                  and graphics installation. We literally perform thousands of installations per year. All crews are
                  fully insured and meet local safety standards.
                </div>
              </div>
            </Fade>
            <Fade left>
              <div className="aboutinfocard">
                <div className="aboutinfoimg mb-3">
                  <img src={aboutusimg3} />
                </div>
                <h3 className="aboutinfoheading m-1 mb-3">We service Home and Retail needs</h3>
                <div className="aboutusinfocontent m-1">
                  {' '}
                  Whether you have a small install of wallpaper in your home or dozens of graphic installs across the
                  country, our team of skilled installers will ensure everything is executed to plan and to your
                  satisfaction.
                </div>
              </div>
            </Fade>

            <Fade top>
              <div className="aboutinfocard">
                <div className="aboutinfoimg mb-3">
                  <img src={aboutusimg9} />
                </div>
                <h3 className="aboutinfoheading m-1 mb-3">No job is too small or too big </h3>
                <div className="aboutusinfocontent">
                  {' '}
                  Using Myinstallr will allow you to book whatever size job you have, quickly and easily. We believe all
                  jobs, whether a small decal on a door or a full building wrap are equally important.
                </div>
              </div>
            </Fade>
            <Fade right>
              <div className="aboutinfocard">
                <div className="aboutinfoimg mb-3">
                  <img src={aboutusimg9} />
                </div>
                <h3 className="aboutinfoheading m-1 mb-3">Myinstalr Vision</h3>
                <div className="aboutusinfocontent">
                  {' '}
                  Our vision is to connect you directly with installers in your area that are skilled, experienced,
                  honest and personable. Where you can advise the installer how and when you’d like your job done,
                  without any “broken telephone” or missed information.
                </div>
              </div>
            </Fade>
          </div>
          <div className="aboutusinfo7 d-flex">
            <Fade left>
              <div className="img1">
                <img src={locationimg} />
              </div>
            </Fade>
            <Fade top>
              <div className="img2">
                <img src={installimg} />
              </div>
            </Fade>
            <Fade right>
              <div className="img3">
                <img src={chooseinstalerimg} />
              </div>
            </Fade>
            <Fade right>
              <div className="img3">
                <img src={aboutusimg10} />
              </div>
            </Fade>
          </div>
          <div className="aboutusinfo6">
            <div className="image-container">
              <img src={qualityjpg} alt="img1" className="main-image" />
              <div className="top-left-card">
                <Fade left>
                  <Card className="card-left">
                    <h2> Your satisfaction is our ultimate goal.</h2>
                    <p>
                      We will always make sure you receive the highest quality of customer service and installation
                      services. We want your visual presentation and graphic install needs met and exceeded. We want you
                      happy!
                    </p>
                    <Button
                      className="joinbtn"
                      onClick={() => {
                        navigate('/selectrole');
                      }}
                    >
                      Join us now
                    </Button>
                  </Card>
                </Fade>
              </div>
              <div className="bottom-right-card ">
                <Fade right>
                  <Card>
                    <p>
                      {' '}
                      Graphics are a visual medium,so we want to make sure your vision comes through for you and looks
                      great. If your install looks great then we have helped you achieve that and that makes us look
                      good too.
                    </p>
                  </Card>
                </Fade>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
