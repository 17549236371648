import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { useDispatch } from 'react-redux';
import Fade from 'react-reveal/Fade'
import { useNavigate } from 'react-router-dom';

import Footer from '../../components/Footer';
import './ContactUs.less'
import contactusmail from '../../asset/images/contactusinfomail.svg'
import contactusphone from '../../asset/images/contactusinfophone.svg'
import facebookimg from '../../asset/images/facebookimg.svg'
import instagramimg from '../../asset/images/instagramimg.svg'
import logo from '../../asset/images/Logo.svg';
import tiwterimg from '../../asset/images/twitterimg.svg'
import { setLogin } from '../../redux/slices/login';
import '../Home/Home.less';

import { contactUsApi } from '../../api/detailsapi'

const ContactUs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [data, setData] = useState({});
  const [disabled, setDisabled] = useState(true);
  const { TextArea } = Input;
  const sendMessage = async () => {
    const resp = await contactUsApi(data);
    if (resp?.status == 200)
      message.success("message sent successfully")
    setData({});
    form.resetFields();
  }
  const handleFormChange = () => {
    const areTruthy = Object.values(form.getFieldsValue()).every(
      value => value
    );
    const hasErrors = !areTruthy ||
      form.getFieldsError().filter(({ errors }) => errors.length)
        .length > 0;
    setDisabled(hasErrors);
  }
  return (
    <>
      <div className="main_containerhome">
        <div className="nav_container">
          <div>
            <img src={logo} />
          </div>
          <div className="nav_option">
            <ul>
              <li
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/');
                }}
              >
                Home
              </li>
              <li
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/aboutus');
                }}
              >
                About Us
              </li>
              <li
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate('/process');
                }}
              >
                How it works
              </li>
              <li style={{ color: '#000000', fontWeight: 600 }}>Contact Us</li>
            </ul>
            <div className="nav_btn_container">
              <Button
                className="signIn_btn"
                onClick={() => {
                  dispatch(setLogin(true));
                  navigate('/selectrole');
                }}
              >
                Sign In
              </Button>
              <Button
                className="signUp_btn"
                onClick={() => {
                  dispatch(setLogin(false));
                  navigate('/selectrole');
                }}
              >
                {' '}
                Sign Up
              </Button>
            </div>
          </div>
        </div>
        <div style={{ width: '100%', borderBottom: '1px solid rgba(161, 161, 170, 1)', marginBottom: '20px' }}></div>
        <div className="containercontact">
          <div className="contactusinfo">
            <h2> If you need to contact us, here's how.</h2>
            <div className="contactcontent"> If you have any questions please fell free to reach out!</div>
          </div>
          <div className="contactusmails">
            <Fade left>
              <div className="content1">
                <img src={contactusmail} />
                <div style={{ color: 'black', fontWeight: '600', fontSize: '22px', margin: '10px' }}>
                  Send us an email
                </div>
                <p>
                  {' '}
                  We love emails, someone will reply within 24 hours! Do You Prefer to reach out to us from here? We’ll
                  get your message and someone will reply within 24 hours!
                </p>
                <a
                  className="emailref mx-auto mt-1 mb-3"
                  href="mailto:support@myinstallr.com"
                  style={{
                    color: 'black',
                    fontWeight: 'bold !important',
                    textAlign: 'center',
                    fontSize: '15px',
                    textDecoration: 'none',
                  }}
                >
                  <div
                    className="button2"
                    style={{
                      backgroundColor: 'black',
                      color: '#fff',
                      fontWeight: '500',
                      borderRadius: '14px',
                      height: '50px',
                      padding: '14px',
                    }}
                  >
                   support@myinstallr.com
                  </div>
                </a>
              </div>
            </Fade>
            <Fade right>
              <div className="content1 content2">
                <img src={contactusphone} />
                <div style={{ color: 'black', fontWeight: '600', fontSize: '22px', margin: '10px' }}>
                  Give us a call
                </div>
                <p>We are happy to address you concerns, please call Myinstallr support at the below contact. </p>
                {/* <a
                  className="emailref mx-auto mt-1 mb-3"
                  href="mailto:questions@myinstaller.ca"
                  style={{
                    color: 'black',
                    fontWeight: 'bold !important',
                    textAlign: 'center',
                    fontSize: '15px',
                    textDecoration: 'none',
                  }}
                > */}
                  <div
                    className="button2"
                    style={{
                      backgroundColor: 'rgba(15, 244, 189, 1)',
                      color: 'black',
                      fontWeight: '500',
                      borderRadius: '14px',
                      height: '50px',
                      padding: '14px',
                    }}
                  >
                    {' '}
                    +1-800-481-0247
                  </div>
                {/* </a> */}
              </div>
            </Fade>
          </div>
          <div className="main">
            <div className="main1" style={{ textAlign: 'center' }}>
              <div className="contactheading">
                Do You prefer to reach out
                <br />
                to us directly?
              </div>
              {/* <p className="mb-4">Ante in posuere lacus mauris tincidunt nullam nisl pharetra sollicitudin
                felis tellus nunc, lectus interdum ut volutpat pretium eu mi diam quis
              </p> */}
            </div>
            <Form form={form} layout="vertical" onFieldsChange={handleFormChange}>
              <div className="subform">
                <div className="subform1">
                  <Form.Item
                    name="Full Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter username!',
                      },
                      {
                        pattern: new RegExp(/^[A-Za-z ]+$/),
                        message: 'Please Enter valid UserName',
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Full Name"
                      size="large"
                      onChange={(e) => {
                        setData({ ...data, fullName: e.target.value });
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="subform1">
                  <Form.Item
                    name="Email Address"
                    rules={[
                      { required: true, message: 'Please enter email!' },
                      {
                        type: 'email',
                        message: 'Please enter a valid email address.',
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      placeholder="Email Address"
                      size="large"
                      onChange={(e) => {
                        setData({ ...data, email: e.target.value });
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="subform">
                <div className="subform1">
                  <Form.Item
                    name="Phone Number"
                    rules={[
                      { required: true, message: 'Please enter phone number!' },
                      {
                        pattern: new RegExp(`[1][ ][0-9]{10}$`),
                        message: 'Please Enter valid phone number',
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Phone Number"
                      size="large"
                      onChange={(e) => {
                        setData({ ...data, phoneNumber: e.target.value });
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="subform1">
                  <Form.Item name="Service" rules={[{ required: true, message: 'Please enter your service!' }]}>
                    <Input
                      type="text"
                      placeholder="Service"
                      size="large"
                      onChange={(e) => {
                        setData({ ...data, service: e.target.value });
                      }}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="subform">
                <Form.Item name="Message" rules={[{ required: true, message: 'Please enter message' }]}>
                  <TextArea
                    rows={4}
                    placeholder="Enter Your Message"
                    onChange={(e) => {
                      setData({ ...data, message: e.target.value });
                    }}
                  />
                </Form.Item>
              </div>
              <div className="subform subformmsg">
                <Button
                  htmlType="submit"
                  size="large"
                  className={disabled ? 'smdisabled' : ''}
                  onClick={() => {
                    if (!disabled) sendMessage();
                  }}
                >
                  Send Message
                </Button>
                <div className="socialicons d-flex">
                  <div className="icon1 ml-4 mr-4">
                    <img src={facebookimg} />
                  </div>
                  <div className="icon2 mr-4">
                    <img src={instagramimg} />
                  </div>
                  <div className="icon3 mr-4">
                    <img src={tiwterimg} />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default ContactUs;
