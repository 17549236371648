import React, { useState, useRef, useEffect } from "react";
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import deleteIcon from '../../../asset/images/deleteIcon.svg';
import { CategoryList } from '../../../api/detailsapi';
import { Divider,List } from 'antd';
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { getAddressObject } from "../../../common/helper";
import dropdown from '../../../asset/images/dropdownicon.svg';
import dropdownup from '../../../asset/images/dropdownup.svg';
import CategoryItem from "./CategoryItem";
const ContractorProfile = ({ handleAddCount, save }) => {
  const [disabled, setDisabled] = useState(true);
  const [address, setAddress] = useState({})
  const [value, setValue] = useState("");
  const [showList, setShowList] = useState(false)
  const [isSelected, setisSelected] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [profileInfo, setProfileInfo] = useState({
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    province: '',
    postalCode: ''

  });
  const [selectedArray, setSelectesArray] = useState([]);
  const [checked, setChecked] = useState(false);
  const formRef = useRef();
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE,
    options: {
      // componentRestrictions: { country: "ca" },
    },
  });
  const multipleSelect = (ind) => {
    setChecked(true);
    setSelectedItems([...selectedItems, ind._id]);
    setSelectesArray([...selectedArray, ind])
  };
  const removeSelected = (ind) => {
    const filterItem = selectedItems.filter((item) => item !== ind._id);
    const filterArray = selectedArray.filter((item) => item._id != ind._id);
    setSelectedItems([...filterItem]);
    setSelectesArray([...filterArray]);
  };
  const handleFormChange = () => {
    const areTruthy = Object.values(form.getFieldsValue()).every(
      value => value
    );
    const hasErrors = !areTruthy ||
      form.getFieldsError().filter(({ errors }) => errors.length)
        .length > 0;
    setDisabled(hasErrors);
  }
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (values) => {
      const response = await CategoryList(values);
      setOptions(response?.data)
  };
  const getDetails = (placeid) => {
    const data = placePredictions.filter((item) => item.place_id == placeid)
    placesService?.getDetails(
      {
        placeId: data[0].place_id,
      },
      (placeDetails) => {
        const address = getAddressObject(placeDetails?.address_components)
        setValue(placeDetails?.name)
        formRef.current.setFieldValue('address', placeDetails?.name);
        formRef.current.setFieldValue('city', address?.city);
        formRef.current.setFieldValue('province', address?.region);
        formRef.current.setFieldValue('postalCode', address?.postal_code);
        setProfileInfo({
          ...profileInfo,
          address: placeDetails?.name,
          city: address?.city
          , province: address?.region
          , postalCode: address?.postal_code, location: { lat: placeDetails.geometry.location.lat().toString(), long: placeDetails.geometry.location.lng().toString() }
        })
        handleFormChange();
        setShowList(false)
      }
    );
  }

  const onFinish = () => {
    save({ ...profileInfo, subCategories: [...selectedItems] });
    handleAddCount(1);
  }
  const [form] = Form.useForm();
  const isClicked = () => {
    setisSelected(!isSelected);
  };
  const savePostalCode=(e)=>{
    form.setFieldValue("postalCode", e.target.value);
     setAddress({ ...address, postalCode: e.target.value });
  }
  return (
    <>
      <div className="center-container justify-content-center profiledetails"  >
        <div className="heading">Enter Your Profile Details
          <img src="" />
        </div>
        <Form
          initialValues={{ remember: true }}
          onFinish={onFinish}
          ref={formRef}
          autoComplete="off"
          layout="vertical"
          onFieldsChange={handleFormChange}
          form={form}
          className="profileform"
        >
          <div className="subform">
            <div className="subform1">
              <Form.Item
                name="firstName"
                className="mb-3"
                rules={[
                  {
                    type: 'name',
                    message: 'Please enter a valid Name',
                  },
                  {
                    required: true,
                    message: "please enter first name"
                  }, {
                    pattern: new RegExp(/^[A-Za-z ]+$/),
                    message:
                      "Field does not accept numbers or special characters.",
                  },
                ]}
              >
                <Input size="large" placeholder="First Name" className={profileInfo.firstName != '' ? "input-fieldcolor" : "input-field"} onChange={(e) => { formRef.current.setFieldValue('firstName', e.target.value); setProfileInfo({ ...profileInfo, firstName: e.target.value }) }} />
              </Form.Item>
            </div>
            <div className="subform1">
              <Form.Item
                name="lastName"
                className="mb-3"
                rules={[
                  {
                    type: 'name',
                    message: 'Please enter a valid Name',
                  },
                  {
                    required: true,
                    message: "please enter last name"
                  },
                  {
                    pattern: new RegExp(/^[A-Za-z ]+$/),
                    message:
                      "Field does not accept numbers or special characters.",
                  },
                ]}
              >
                <Input size="large" placeholder="Last Name" className={profileInfo.lastName != '' ? "input-fieldcolor" : "input-field"} onChange={(e) => { formRef.current.setFieldValue('lastName', e.target.value); setProfileInfo({ ...profileInfo, lastName: e.target.value }) }} />
              </Form.Item>
            </div>
          </div>
          <div className="contractor-subform mb-2">
            <div className='multi_select_field' onClick={() => {
              isClicked();
            }}>
              <div className="place_holder">Select Your Services</div>
              <div
                style={{ cursor: 'pointer' }}
              >
                {' '}
                {isSelected ? <img src={dropdownup} /> : <img src={dropdown} />}
              </div>
            </div>
          </div>
          {isSelected ? (
            <div className="options_board">
              {options.map((items) => (
                <>
                  <div className="option_list_title" >
                    <h1>{items.name}</h1>
                    {items.subcategories.map((item) => (
                      <>
                        <CategoryItem item={item} setSelectedItems={setSelectedItems} selectedItems={selectedItems} setSelectesArray={setSelectesArray} selectedArray={selectedArray} multipleSelect={multipleSelect} removeSelected={removeSelected} checked={checked} setChecked={setChecked} />
                        <Divider style={{ margin: "10px 0" }} />
                      </>
                    ))}
                  </div>
                </>
              ))
              }
            </div>) : ""}
          {selectedItems.length > 0 && (
            <div className="subform">
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', marginBottom: '1.5rem', height: "85px", overflowY: "scroll" }}>
                {selectedArray.map((item) => {
                  return (
                    <div key={item.subCategoryImage.url} className="checkedItem">
                      <span className="checkedItem_img">
                        <img src={item.subCategoryImage.url} />
                      </span>
                      <>{item.name}</>
                      <span
                        style={{
                          width: '1.5rem',
                          height: '1.5rem',
                          border: '1px solid #ddd',
                          borderRadius: '50%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        onClick={() => {
                          removeSelected(item);
                        }}
                      >
                        <img src={deleteIcon} />
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="subform">
            <div className="subform1">
              <Form.Item
                name="address"
                type="text"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your address!',
                  }
                ]}
              >
                <Input
                  size="large"
                  value={value}
                  // autoFocus
                  autoFocus={value?.length > 0 ? true : false}
                  onChange={(evt) => {
                    setValue(evt.target.value)
                    formRef.current.setFieldValue('address', evt.target.value)
                    getPlacePredictions({ input: evt.target.value });
                    if (!isPlacePredictionsLoading) setShowList(true)
                  }}
                  onKeyDown={(event) => {
                    if (event?.keyCode === 8 || event?.keyCode === 46) {
                      form.setFieldValue("city", '')
                      form.setFieldValue("province", '')
                      form.setFieldValue("postalCode", '')
                      // handleFormChange()
                    }
                  }}
                  // loading={false}
                  placeholder="Address"
                  className={profileInfo.address != '' ? "input-fieldcolor" : "input-field"}
                />
                {value !== '' && showList && placePredictions.length > 0 && (
                  <div className="google-autosuggestion-list">
                    <List
                      dataSource={placePredictions}
                      renderItem={(item) => (
                        <List.Item onClick={() => getDetails(item?.place_id)}>
                          <List.Item.Meta title={item.description} />
                        </List.Item>
                      )}
                    />
                  </div>
                )}
              </Form.Item>
            </div>
            <div className="subform1">
              <Form.Item
                name="city"
                className="mb-3"
                rules={[
                  {
                    type: 'address',
                    message: 'Please enter a valid city',
                  },
                  {
                    required: true,
                    message: 'Please enter your City!',
                  }
                ]}
              >
                <Input size="large" placeholder="City" className={profileInfo.city ? "input-fieldcolor" : "input-field"}
                 onChange={(e) => { form.setFieldValue("city", e.target.value); setAddress({ ...address, city: e.target.value }) }} />
              </Form.Item>
            </div>
          </div>
          <div className="subform">
            <div className="subform1">
              <Form.Item
                name="province"
                className="mb-3"
                rules={[
                  {
                    type: 'address',
                    message: 'Please enter a valid Province',
                  },
                  {
                    required: true,
                    message: 'Please enter your province!',
                  }

                ]}
              >
                <Input size="large" placeholder="Province" className={profileInfo.province != "" ? "input-fieldcolor" : "input-field"} 
                onChange={(e) => { form.setFieldValue("province", e.target.value); setAddress({ ...address, province: e.target.value }) }} />
              </Form.Item>
            </div>
            <div className="subform1">
              <Form.Item
                name="postalCode"
                className="mb-3"
                rules={[
                  {
                    type: 'postal',
                    message: 'Please enter a valid postal code',
                  },
                  {
                    required: true,
                    message: 'Please enter your province!',
                  }
                ]}
              >
                <Input size="large" placeholder="Postal Code" className={profileInfo.postalCode != '' ? "input-fieldcolor" : "input-field"} 
                onChange={(e) => {savePostalCode(e) }} />
              </Form.Item>
            </div>
          </div>
          <div className="subform">
            <Button htmlType="submit"
              className={disabled || selectedItems.length <= 0 ? "contractor-submitbtnd" : "contractor-submitbtn"}
            >
              Next
            </Button>
          </div>
        </Form>
      </div>
    </>
  )
}
export default ContractorProfile;