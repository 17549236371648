import React from 'react'
import { useNavigate } from 'react-router-dom'

import crossicon from '../../asset/images/crossicon.svg'

const PrivacyPolicy = ({ setPrivacyModal,inNavigation }) => {
const navigate=useNavigate();
let token=localStorage.getItem('token');
  return (
    <div className="privacypolicy h-100 p-4">
      <div className="heading text-center">Privacy Policy
     <div className="crossimg" onClick={() => { setPrivacyModal(false) }}>{!inNavigation &&<img src={crossicon} />}</div>
      </div>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '14pt' }}>
        <strong><span style={{ color: 'black' }}>Privacy Policy December 2023</span></strong>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp;</p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
        <strong><span style={{ color: '#808080' }}>Information Disclaimer</span></strong>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '5pt' }}>
        <strong><span style={{ color: '#808080' }}>&nbsp;</span></strong>
      </p>
      <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "11pt" }}>Myinstallr values your privacy as much as we value our own.
        We will never trade, sell, rent or share ANY of your personal information, images,
        pricing or income with anyone. Your image and selected information will be shared in a closed peer to peer
        setting with other users and clients as required by the APP and Web usage as part of the regular usage.</p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp;</p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
        Myinstallr is strictly in the business of finding installrs for clients and not in the business of gathering data that can be used in other ways. We believe that your personal information is your own and should remain your own, and we value you allowing us to share what is necessary on our platform.
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>&nbsp;</p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
        <strong><span style={{ color: 'black' }}>WHICH&nbsp;</span></strong>
        <strong><span style={{ color: '#808080' }}>information we need.&nbsp;</span></strong>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '5pt' }}><strong><span style={{ color: '#808080' }}>&nbsp;</span></strong></p>
      <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "11pt" }}>Myinstallr must ask for specific user information from both clients and
        contractors in order to create their personal accounts. Their information is shared
        with no one other than Myinstallr employees as required by regular transactional use.
        Personal information is not shared other than the information required by the app usage.</p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
        <strong><span style={{ color: 'black' }}>&nbsp;</span></strong>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
        Specifically,<span style={{ color: 'black' }}>&nbsp;</span>we collect the following info in order to complete transactions between Clients and Contractors:
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
        <strong><span style={{ color: '#808080' }}>&nbsp;</span></strong>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
        <strong><span style={{ color: '#808080' }}>Client:</span></strong> First and Last Name, Company Name (if applicable), Phone #, e-mail address, Address, Website Address (if applicable), Credit Card Information (We DO NOT store credit card information).
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
        <strong><span style={{ color: '#808080' }}>Contractor:</span></strong> First and Last Name, Company Name (if applicable), Phone #, e-mail address, Address, Website Address (if applicable), Credit Card Information <span style={{ color: 'black', fontWeight: "600" }}>(We DO NOT store credit card information),</span> Bank info for payment, Stripe Credit Card Payment info (if applicable).
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>&nbsp;</p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', color: 'black', fontWeight: "600" }}>
        Your private information will be encrypted and saved using Secure Socket Layer (SSL) technology.
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
        <strong><span style={{ color: 'black', fontWeight: "600" }}>This to be confirmed or a recommendation by APPstudio.</span></strong>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '15pt', fontSize: '15pt' }}>
        <strong><span style={{ fontFamily: 'Times New Roman', color: '#eb0396' }}>&nbsp;</span></strong>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt' }}>
        <strong><span style={{ color: 'black' }}>HOW</span></strong>
        <strong><span style={{ color: '#808080' }}>&nbsp;will Myinstallr use your information.</span></strong>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '5pt' }}>
        <strong><span style={{ color: '#808080' }}>&nbsp;</span></strong>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
        All of your information is strictly used to make your order operate easily. Your information is required to complete a service transaction or in order to give you full app or web access to post jobs, communicate with contractors (installrs) and review costs and work as it progresses.
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
        &nbsp;
      </p>
      <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "11pt" }}>If you choose to opt-in to email notifications from Myinstallr, we will strictly only let you know of any promotions, discounts or general Installation related information. We will not sell or share your email address to third parties, and you will only be opting in to emails from Myinstallr specifically and no other affiliates of Myinstallr. You will have the option to decline emails at any time.</p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
        &nbsp;
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
        &nbsp;
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
        Additionally, MyInstallr uses &ldquo;cookies&rdquo; to make your Web access smoother and collects and identifies your account and provides user analytics data. You have the option to not allow this at any time.
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
        &nbsp;
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '15pt', fontSize: '15pt' }}>
        <strong><span style={{ fontFamily: 'Times New Roman', color: '#eb0396' }}>&nbsp;</span></strong>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '15pt' }}>
        <strong><span style={{ color: 'black' }}>WHAT&nbsp;</span></strong><strong><span style={{ color: '#808080' }}>are your options and rights?</span></strong>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
        At any time, you can delete your account and all your personal information is immediately deleted and not stored on Myinstallr. The only information retained is tax-related information as required by the federal government for us to hold for up to 7 years.
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '15pt', fontSize: '11pt' }}>
        &nbsp;
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '15pt', fontSize: '11pt' }}>
        Your username and password and encrypted and secure and only accessible by you. Your location and geo-locating is only active while using the APP and as required for usage per order.
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '15pt', fontSize: '11pt' }}>
        You can opt out of all services at any time, with the understanding that this may limit APP and Web functionality.&nbsp;
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
        If at any time you wish to be removed from any of the customer lists, email blasts, or other internet contacts, you may remove yourself from getting further email by clicking on the &ldquo;unsubscribe&rdquo; link at the bottom of any email from Myinstallr.
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
        You can also request in writing or email to be completely removed from all correspondence by contacting us directly at&nbsp;<a  style={{ textDecoration: 'none', color: 'black', fontWeight: "600" }}>privacy@myinstallr.com</a>&nbsp;or writing us with your specific request at; Myinstallr, 3208 Lenworth Drive, Mississauga, Ontario, L4X-2G1
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontFamily: 'Times New Roman' }}>
        &nbsp;
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '15pt' }}>
        <strong><span style={{ color: 'black' }}>WHEN&nbsp;</span></strong><strong><span style={{ color: '#808080' }}>is your information shared?</span></strong>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
        Myinstallr must share some or all of your information within its own company or with select third parties, companies, or individuals in order to process payments or complete orders. This information is strictly confidential and encrypted and is only done on a transactional basis and not stored by any parties except as required by law when completing payments and orders.
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
        &nbsp;
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>This is required in order to complete credit card payments as required by our credit card processing companies that we must use to process payment. Some data must also be shared with email service providers as required to hold customer account information. Each of these providers is responsible to maintain and uphold their own privacy policy that Myinstallr has reviewed and has agreed is in line with our own privacy policies. Myinstallr reserves the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to satisfy any applicable law, regulation, legal process or governmental or legal request, enforce this Privacy Policy and our Terms of Service, including investigation of potential violations hereof, detect, prevent, or otherwise address fraud, security or technical issues, or protect our rights, property or safety, our users and the public. This includes exchanging information with other companies and organizations for fraud protection and spam/malware prevention.&nbsp;</p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>Myinstallr shares information if we believe that disclosure is reasonably necessary to protect the safety, rights, or property of the public. We may also share with our wholly owned subsidiaries and affiliates. If Myinstallr were to engage in a merger, acquisition, bankruptcy, dissolution, re-organization, or similar transaction or proceeding that involves the transfer
        of the information described in this Policy, Myinstallr would share your information with a party involved in such a process
        (for example, a potential purchaser).&nbsp;</p>
      <p style={{ marginTop: '0pt', marginBottom: '15pt', fontFamily: 'Times New Roman' }}>
        &nbsp;
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
        DISCLAIMER: WE ARE NOT RESPONSIBLE FOR WHAT HAPPENS WHEN YOU LEAVE Myinstallr OR ANY Myinstallr OWNED SITE.
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
        &nbsp;
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '15pt' }}>
        <strong><span style={{ color: 'black' }}>&nbsp;</span></strong>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '15pt' }}>
        <strong><span style={{ color: 'black' }}>Can&nbsp;</span></strong><strong><span style={{ color: '#808080' }}>this privacy policy be changed?</span></strong>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '15pt', fontSize: '11pt' }}>
        Myinstallr reserves the right to change We may update this Privacy Policy from time to time. When we do, we will post an updated version on this page unless another type of notice is required as applicable law.
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '15pt' }}>
        <strong><span style={{ color: 'black' }}>Lastly,</span></strong>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
        Myinstallr agrees to the laws of the Province of Ontario, Canada, without regard to principles of conflict of laws, will govern this Agreement and any dispute of any sort that might arise between you and us. You hereby irrevocably attorn to the exclusive jurisdiction of the Courts of the Province of Ontario with respect to any dispute hereunder.
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '15pt', fontSize: '11pt' }}>
        &nbsp;
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '15pt' }}>
        <strong><span style={{ color: 'black' }}>How&nbsp;</span></strong><strong><span style={{ fontSize: '11pt', color: '#808080' }}>to Contact us.</span></strong>
      </p>
      <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>
        Please&nbsp;<a target="_blank" style={{ textDecoration: 'none' }} onClick={()=>{
          if(!token)navigate("/contactusinfo");
          else navigate("/contactus");
          setPrivacyModal(false);window.scrollTo(0, 0)}}><u><span style={{ color: 'black' }}>Contact us</span></u></a> if you have any questions or concerns not already addressed in this Privacy&nbsp;Policy.
      </p>
    </div>
  )
}
//href="mailto:privacy@myinstallr.com"
export default PrivacyPolicy;