import instance from '../config/axios';
export const saveCardApi = async (val) => {
  try {
    const response = await instance.post(`job/add-card`, { token: val });
    return response;
  } catch (error) {
    return error
  }
};

export const getCardsApi = async () => {
  try {
    const response = await instance.get(`job/card-list`);
    return response;
  } catch (error) {
    return error
  }
};
export const makeInitialPaymentApi = async (body) => {
  try {
    const response = await instance.post(`job/make-initial-payment`, body);
    return response;
  } catch (error) {
    return error
  }
}
export const makeFullPaymentApi = async (body) => {
  try {
    const response = await instance.post(`job/make-full-payment`, body);
    return response;
  } catch (error) {
    return error
  }
}
export const deleteCardApi = async (id) => {
  try {
    const response = await instance.delete(`job/delete-card?cardId=${id}`);
    return response;
  } catch (error) {
    return error
  }
}
export const selectedCardApi = async (body) => {
  try {
    const response = await instance.post(`job/select-card`,body);
    return response;
  } catch (error) {
    return error
  }
}
