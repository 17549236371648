import Modal from "antd/es/modal/Modal";
import './timemodal.less';
import crossicon from '../asset/images/crossicon.svg'

const TimeModal = ({ timeModal, setTimeModal, setTime, hours }) => {
  const data = ["09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00"];

  return (
    <Modal
      centered
      open={timeModal}
      className='timemodal'
      closable={false}
      header={null}
      footer={null}
      style={{ top: '', overflow: "hidden !important" }}
    >
      <div className="modal-body">
        <img src={crossicon} onClick={() => { setTimeModal(false) }} style={{ cursor: "pointer" }} />
        <div className="serviceheading text-center" >Select Service Timing</div>
        <div className="timmings">
          {data.includes(`${hours}:00`) ?
            <>
              {data.slice(0, data.indexOf(`${hours}:00`) + 1)?.map((item) => {
                return (
                  <>
                    <div className="timming" style={{ backgroundColor: "gray", cursor: "not-allowed", opacity: "0.2" }}  >{item}</div>
                  </>
                )
              })}
              {data.slice(data.indexOf(`${hours}:00`) + 1, data.length).map((item) => {
                return (
                  <>
                    <div className="timming" onClick={() => { setTime(item); setTimeModal(false); }}>{item}</div>
                  </>
                )
              })}
            </> :

            data.map((item) => {
              return (
                <>
                  <div className="timming" onClick={() => { setTime(item); setTimeModal(false); }}>{item}</div>
                </>
              )
            })}
        </div>
      </div>
    </Modal>
  )
}
export default TimeModal;