import React  from 'react';
import Button from 'antd/es/button';
import { useDispatch } from 'react-redux';
import Fade from 'react-reveal/Fade'
import { useNavigate } from 'react-router-dom';

import CreateJob from '../../components/CreateJob'
import Footer from '../../components/Footer';
import bgImg from '../../asset/images/Content04.png';
import grp1 from '../../asset/images/Group1.png';
import logo from '../../asset/images/Logo.svg';
import { setLogin } from '../../redux/slices/login';
import '../Home/Home.less';

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <div className="main_containerhome">
        <div className="nav_container">
          <div>
            <img src={logo} />
          </div>
          <div className="nav_option">
            <ul>
              <li style={{ color: '#000000', fontWeight: 600 }}>Home</li>
              <li  style={{cursor:"pointer"}} onClick={()=>{navigate("/aboutus")}}>About Us</li>
              <li  style={{cursor:"pointer"}} onClick={()=>{navigate("/process")}}>How it works</li>
              <li  style={{cursor:"pointer"}}  onClick={()=>{navigate("/contactusinfo")}}>Contact Us</li>
            </ul>
            <div className="nav_btn_container">
              <Button
                className="signIn_btn"
                onClick={() => {
                  dispatch(setLogin(true))
                  navigate('/selectrole');
                }}
              >
                Sign In
              </Button>
              <Button
                className="signUp_btn"
                onClick={() => {
                  dispatch(setLogin(false))
                  navigate('/selectrole');
                }}
              >
                {' '}
                Sign Up
              </Button>
            </div>
          </div>
        </div>
        <div className="home_container mb-3">
          <div className="left_container">
            <div className="hdg_container">
              <Fade top>
              <h1 style={{color:"#0ff4bd"}}>Myinstallr is Yourinstallr</h1>
              </Fade>
            </div>

            <div className="crt_container">
              <p>
              Whatever your graphic/visual install needs are, we have a dedicated professional to help you and 
               your project be completed to your satisfaction, on time and on budget.
              </p>

            </div>
          </div>
         
          <div className="right_container">
          
            <img src={bgImg} className="bgImg" />
            <Fade right>
            <img src={grp1} className="grp1" />
            </Fade>
          </div>
          
        </div>
        <CreateJob />
        <Footer />
      </div>
    </>

  );
};

export default HomePage;
