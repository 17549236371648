import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import io from 'socket.io-client';

import DashBoardWrapper from '../../../components/wrapper/DashBoardWrapper';
import SpinWrapper from '../../../components/wrapper/SpinWrapper';
import ChatInfo from './ChatInfo';
import './message.less';
import SideChat from './SideChat';
import { showLoader } from '../../../redux/slices/common';
import { setUnReadCount } from '../../../redux/slices/common';

import { uploadImageApi } from '../../../api/detailsapi';
import { getChatUsersApi, getMessagesApi, sendMessagesApi, receiveMessageApi } from '../../../api/messages';
import { callUnReadMessageApi } from '../../../api/notifications';

const ClientMessages = () => {
  const token = localStorage.getItem('token');
  const socket = io(`${process.env.REACT_SOCKET_URL}?accessToken=${token}`);
  const [chatUsers, setChatUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [newChatUser, setNewChatUser] = useState();
  const [newMessage, setNewMessage] = useState();
  const [image, setImage] = useState(null);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const {
    _id: userId,
    firstName,
    lastName,
    profileImage,
    id: userIdCont,
  } = useSelector((state) => state.login.profileInfo);
  const location = useLocation();
  const val = location?.state?.val;
  const callChatUserApi = async () => {
    dispatch(showLoader(true));
    const resp = await getChatUsersApi();
    setChatUsers(resp?.data?.data);
    if (val) {
      const value = resp?.data?.data;
      const val2 = value.find((item) => item?.job[0]?._id == val?.job[0]?._id);
      if (val2) setNewChatUser(val2);
      else {
        const value = {
          userId: {
            profileImage: val?.profileImage,
            firstName: val?.firstName,
            lastName: val?.lastName,
            _id: val?._id,
          },
          job:val?.job,
          category:val?.category,
          latestMessage: '',
          createdAt: new Date().toISOString(),
          location:val?.location
        };
        setNewChatUser(value);
        if (resp?.data?.data) setChatUsers([value, ...resp?.data?.data]);
      }
    }
    dispatch(showLoader(false));
  };

  const callGetMessagesApi = async () => {
    setLoader(true);
    const resp = await getMessagesApi(newChatUser?.job[0]?._id,newChatUser?.userId?._id);
    setMessages(resp?.data?.data.reverse());
    setLoader(false);
  };

  const callRecieveMessage = async (id,userId) => {
    const resp = await receiveMessageApi(id,userId);

    if (resp?.data?.type == 'success') {
      const val = chatUsers.map((item) => {
        if (item?.job[0]?._id == id) return { ...item, unReadCount: 0 };
        else return item;
      });
      setChatUsers(val);
    }
    const resp1=await callUnReadMessageApi();
    dispatch(setUnReadCount(resp1?.data?.data?.totalunReadCount));
    

  };
  const handleSendMsg = async () => {
    let value = {
      messageId: Math.random().toString(36).substr(2, 9),
      message: newMessage,
      status: '1',
      to: newChatUser?.userId?._id,
      from: userId,
      userId: {
        _id: userId || userIdCont,
        firstName: firstName,
        lastName: lastName,
        profileImage: { url: profileImage },
      },
      jobId:newChatUser?.job[0]?._id,
      locationId:newChatUser?.location[0]?._id,
      categoryId:newChatUser?.category[0]?._id,
      createdAt: new Date(),
    };
    if (
      image != null &&
      (newMessage?.includes('pdf') ||
        newMessage?.includes('png') ||
        newMessage?.includes('jpg') ||
        newMessage?.includes('jpeg'))
    ) {
      value = { ...value, attachedFile: image };
    }
    setMessages([...messages, value]);
    setNewMessage('');
    socket.emit('send-message', value);
    const resp = await sendMessagesApi(value);
    if (resp?.status == 200) {
      const val = chatUsers.map((item) => {
        if (item?._id == newChatUser?._id) {
          return {
            ...item,
            latestMessage: { ...item?.latestMessage, message: newMessage },
            createdAt: new Date().toISOString(),
          };
        } else return item;
      });
      setChatUsers([...val]);
    }
    setImage(null);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {};
      reader.readAsDataURL(file);
    }
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0]) {
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        const response = await uploadImageApi(formData);
        if (response?.type == 'success') {
          setImage({
            url: response?.data?.fileUrl,
            key: response?.data?.key,
            type: file.type.includes('pdf') ? 'pdf' : 'image',
          });
          setNewMessage(file.name);
        }
      }
    }
  };

  useEffect(() => {
    callChatUserApi();
  }, []);

  useEffect(() => {
    if (newChatUser) callGetMessagesApi();
  }, [newChatUser]);

  useEffect(() => {
    socket.on('receive-message', async (data) => {
      if (data?.from == newChatUser?.latestMessage?.from) {
        setMessages([...messages, { ...data, userId: { _id: 'new' } }]);
        await receiveMessageApi(data?.from);
      }
      const val = chatUsers.map((item) => {
        if (item?.latestMessage?.from == data?.from) {
          let newval = null;
          if (newChatUser?.latestMessage?.from == data?.from)
            newval = { ...item, latestMessage: { ...item?.latestMessage, message: data?.message }, unReadCount: 0 };
          else
            newval = {
              ...item,
              latestMessage: { ...item?.latestMessage, message: data?.message },
              unReadCount: item?.unReadCount + 1,
            };
          return newval;
        } else return item;
      });
      setChatUsers(val);
    });
    // Clean up the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, [socket]);
  
  return (
    <SpinWrapper>
      <div className="messagecontainer">
        <div className="messagecontent1">
          <div className="sidechat">
            {chatUsers?.map((m) => {
              return (
                <div
                  key={m?._id}
                  className={m?._id == newChatUser?._id ? 'sidechatuser sidechatuseractive' : 'sidechatuser'}
                  onClick={() => {
                    setNewChatUser(m);
                    if (m?.unReadCount > 0) callRecieveMessage(m?.job[0]?._id,m?.userId?._id);
                  }}
                >
                  <SideChat user={m} />
                </div>
              );
            })}
          </div>
        </div>
        <div className="messagecontent2">
          {newChatUser ? (
            <ChatInfo
              newChatUser={newChatUser}
              messages={messages}
              senderId={userId || userIdCont}
              setNewMessage={setNewMessage}
              handleSendMsg={handleSendMsg}
              message={newMessage}
              handleFileChange={handleFileChange}
              loader={loader}
            />
          ) : (
            <div className="nomessage">Open Conversation to chat</div>
          )}
        </div>
      </div>
    </SpinWrapper>
  );
};

const Messages = () => {
  return (
    <DashBoardWrapper>
      <ClientMessages />
    </DashBoardWrapper>
  );
};
export default Messages;
