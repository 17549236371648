const BreadcrumbItems = {
  NewJobs: [
    { title: "New Jobs", route: "/newjob" , tabname:"New Jobs"},
  ],
  Settings: [
    { title: "Settings", route: "/settings" },
  ],
  // Jobs: [
  //   { title: "Jobs", route: "/jobs",  breadCrumFunc:breadCrumFunc },
  // ],
};
export default BreadcrumbItems;