import React from 'react'

function SubCategoryInfo({ singleJobInfo }) {
  return (
    <>
      {singleJobInfo?.type ?
        <div className="singleinfo m-3" style={{ color: "black", fontSize: "16px", border: "1px solid #8492901A", padding: "10px", width: "90px", borderRadius: "8px", textAlign: "center" }}>
          {singleJobInfo?.type}
        </div> :
        <div className="sufraceapplied ">
          <div className="singleServiceapplied d-flex flex-column text-center  align-items-center justify-content-center ">
            <div className="img mb-2"><img src={singleJobInfo?.subCategoryId?.subCategoryImage?.url} /></div>
            <div className="serviceinfo">{singleJobInfo?.subCategoryId?.name}</div>
          </div>
        </div>
      }
    </>
  )
}

export default SubCategoryInfo