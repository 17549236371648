import { useState } from 'react';
import logo from '../asset/images/myinstallerlogo.svg';
import './footer.less'
import { Modal } from 'antd';
import PrivacyPolicy from '../pages/SignUp/PrivacyPolicy'
import TermsandConditions from '../pages/SignUp/TermsandConditions';
const Footer = () => {
  const [privacyModal,setPrivacyModal]=useState(false);
  const [termsModal,setTermsModal]=useState(false);
  return (
    <>
    <div className="footer">
      <div className="footerline">
        <div className="footerimg">
          <img src={logo} style={{ width: "150px" }} />
        </div>
        <div className="footercontent">
          <div className="content" style={{cursor:"pointer"}} onClick={()=>{setTermsModal(true)}}>T&C</div>
          <div className="content" style={{cursor:"pointer"}} onClick={()=>{setPrivacyModal(true)}}><span style={{ marginRignt: "5px" }}>|</span > Privacy&Policy</div>
          <div className="content" ><span style={{ marginRignt: "5px" }}>|</span> Disclaimer</div>
          <div className="content"><span style={{ marginRignt: "5px" }}>|</span> FAQs</div>

        </div>
      </div>
      <div className="footerline">
        <div className="w-100 copycontent text-center d-flex justify-content-center align-item-center"> Copyright © 2023 myinstallr</div>
      </div>
    </div>
    <Modal
        centered
        open={privacyModal}
        className='privacymodal'
        closable={false}
        width="100%"
        height="100vh"
        header={null}
        footer={null}
        style={{ top: '', overflow: "hidden !important" }}
      >
        <PrivacyPolicy setPrivacyModal={setPrivacyModal} />
      </Modal>
      <Modal
        centered
        open={termsModal}
        className='privacymodal'
        closable={false}
        width="100%"
        height="100vh"
        header={null}
        footer={null}
        style={{ top: '', overflow: "hidden !important" }}
      >
        <TermsandConditions setTermsModal={setTermsModal} />
      </Modal>
    </>
  )
}
export default Footer;