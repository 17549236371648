import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import { message ,Spin} from 'antd'
import messagesettings from '../../../asset/images/messagesettings.svg';
import tickicon from '../../../asset/images/Successicon.png';
import { getProfileApi, createProfileClient } from '../../../api/detailsapi';
import { setProfile } from '../../../redux/slices/login'
import EditProfileWrapper from "../../../components/wrapper/EditProfileWrapper";
import MobileVerificationModal from "./MobileverificationModal";
const EditProfile = ({loading,setLoading}) => {
  const [disabled, setDisabled] = useState();
  const [data, setData] = useState({});
  const [mobileScreen, setMobileScreen] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const handleFormChange = () => {
    const hasErrors =
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabled(hasErrors);
  };
  const callGetProfile = async () => {
    setLoading(true)
    const resp = await getProfileApi();
    if (resp?.status == 200) {
      setData({ firstName: resp?.data?.data?.firstName, lastName: resp?.data?.data?.lastName, phoneNumber: resp?.data?.data?.phoneNumber, countryCode: resp?.data?.data?.countryCode, email: resp?.data?.data?.email });
      form.setFieldValue("firstName", resp?.data?.data?.firstName);
      form.setFieldValue("lastName", resp?.data?.data?.lastName);
    }
  setLoading(false)
  }

  const onFinish = async () => {
   setLoading(true)
    const resp = await createProfileClient({ firstName: data.firstName, lastName: data.lastName });
    if (resp?.status == 200) {
      message.success("Your profile has been updated successfully!")
      dispatch(setProfile({ firstName: data.firstName, lastName: data.lastName }))
    }
    setLoading(false)
  }
  useEffect(() => {
    callGetProfile();
  }, [])

  return (
 <>
      <div className="settingcard" >
        <Spin  spinning={loading} >
        <Form
          name="name-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          onFieldsChange={handleFormChange}
          form={form}
        >
          <div className="subform">
            <div className="subformcontent" >
              <div style={{ color: "#6B6B6B", fontSize: "16px" }} className="mb-2">First Name </div>
              <Form.Item
                name="firstName"
                className="mb-3"
                rules={[
                  {
                    type: 'name',
                    message: 'Please enter a valid first name',
                  },
                  {
                    required: true,
                    message: 'Please enter your first name',
                  },
                ]}
              >
                <Input size="large" placeholder="Enter First Name" value={data.firstName} className={data?.firstName ? "input-field1" : "input-field"} onChange={(e) => setData({ ...data, firstName: e.target.value })} />
              </Form.Item>
            </div>
            <div className="subformcontent1" >
              <div style={{ color: "#6B6B6B", fontSize: "16px" }} className="mb-2">Last Name </div>
              <Form.Item
                name="lastName"
                className="mb-3"
                rules={[
                  {
                    type: 'name',
                    message: 'Please enter a valid last name',
                  },
                  {
                    required: true,
                    message: 'Please enter your last name',
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Last Name" className={data?.lastName ? "input-field1" : "input-field"} value={data.lastName} onChange={(e) => setData({ ...data, lastName: e.target.value })} />
              </Form.Item>
            </div>
          </div>
          <div className="subform">
            <EditProfileWrapper setMobileScreen={setMobileScreen} isClient={true}>
              <div className="countrycode mr-1">{data?.countryCode}</div>
              <div className="mobilenumber mr-1">{data?.phoneNumber?.toString().substring(0, 3)} {data?.phoneNumber?.toString().substring(3, 6)} {data?.phoneNumber?.toString().substring(6, 10)}</div>
              <img src={tickicon} className="tickicon" />
            </EditProfileWrapper>
            <div className="subformcontent1" >
              <div style={{ color: "#6B6B6B", fontSize: "16px" }} className="mb-2">Email Address </div>
              <div className="phonenumber">
                <img src={messagesettings} />

                <div className="mobilenumber mx-2">{data?.email}</div>
                <img src={tickicon} className="tickicon " />
              </div>
            </div>
          </div>
          <Button
            htmlType="submit"
            style={{ height: "50px", marginTop: "3%" }}
            className={disabled ? 'editsave_btndisabled' : 'editsave_btn'}
            type="primary"
            size="large"
            disabled={disabled}
          >
            save
          </Button>
        </Form>
        </Spin>
      </div>
      {mobileScreen &&
        <MobileVerificationModal mobileScreen={mobileScreen} setMobileScreen={setMobileScreen}
          phoneNum={`${data?.countryCode}${data?.phoneNumber}`} data={data} setData={setData}
         />
      }
      </>
 
  )
}

export default EditProfile;