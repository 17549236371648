import React from 'react'
import crossicon from '../asset/images/crossicon.svg';
import { Modal } from 'antd';

const PopUpModal = ({ url, setImageView, imageView }) => {
  return (
    <Modal
      centered
      className='popuppdf'
      closable={false}
      header={null}
      footer={null}
      style={{ top: '', overflow: "hidden !important" }}
      open={imageView}
    >
      <img src={crossicon} style={{ cursor: "pointer" }} onClick={() => { setImageView(false) }} />
      {
        <img src={url} style={{
          width: "98%", height: "89%", objectFit: "contain",
          top: "36px"
        }} />
      }
    </Modal>
  )
}

export default PopUpModal