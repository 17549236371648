import React, { useEffect, useState } from "react";
import { Dropdown, Space, Menu } from 'antd';
import { useDispatch } from "react-redux";
import { XAxis, YAxis, CartesianGrid, Tooltip, AreaChart, Area, ResponsiveContainer } from "recharts";

import "./style.less";
import dropdownicon from '../../../asset/images/dropdownicon.svg';
import { showLoader } from '../../../redux/slices/common'

import { getBusinessGraphDataApi } from "../../../api/reports";

function ReportComponent() {
  let [barGraphData, setBarGraphData] = useState({});
  let [position, setPosition] = useState({});
  let [currentYear1, setCurrentYear1] = useState(new Date().getFullYear())
  let [max, setMax] = useState();
  const dispatch = useDispatch();
  const currentYear = new Date().getFullYear();
  const year = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul",
    "Aug", "Sep", "Oct", "Nov", "Dec"];

  useEffect(() => {
    getGraphData()
  }, [currentYear1])

  const menu = (
    <Menu onClick={(e) => handleMenu(e)}>
      <Menu.Item key={currentYear} >
        {currentYear}
      </Menu.Item>
      <Menu.Item key={currentYear - 1}>
        {currentYear - 1}
      </Menu.Item>
      <Menu.Item key={currentYear - 2}>
        {currentYear - 2}
      </Menu.Item>
      <Menu.Item key={currentYear - 3}>
        {currentYear - 3}
      </Menu.Item>
    </Menu>
  );

  const handleMenu = (e) => {
    setCurrentYear1(e.key)
  }

  const getGraphData = async () => {
    dispatch(showLoader(true))
    const res = await getBusinessGraphDataApi(currentYear1)
    let result = res.data?.data?.reduce((a, b) => Math.max(a, b.totalEarnings), 100);
    setMax(result);
    const data1 = year.map((item, index) => {
      const year = res.data?.data[0]?._id?.year;
      const month = res.data?.data?.find((item) => item?._id?.month == index + 1);
      if (month)
        return { "month": item, "totalEarnings": month.totalEarnings, "year": year };
      else
        return { "month": item, "totalEarnings": 0, "year": year }
    })
    setBarGraphData(data1)
    dispatch(showLoader(false))
  }

  const CustomTooltip = ({ active, payload, label, ...rest }) => {
    if (active && payload?.length) {
      setPosition({
        y: 0,
        x: payload[0]?.value ? rest.coordinate?.x - 55 : rest.coordinate?.x - 50
      })
      return (
        <div className="custom-tooltip">
          <div className="custom-val">{payload[0]?.payload?.month} {payload[0]?.payload?.year} </div>
          <p className="label">${Math.abs(payload[0]?.value).toFixed(2)}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="report-wrapper">
      <div className="report-page">
        <div className="d-flex flex-wrap justify-content-between">
          <div className="content-left">
            <div className="left-header w-100 d-flex justify-content-between align-items-start">
              <div className="earning">Earning Overview</div>
              <div className="listyears" style={{
                border: "1px solid black",
                borderRadius: "10px",
                padding: "5px 15px", color: "black"
              }}>
                <Dropdown overlay={menu} trigger={['click']}>
                  <a onClick={(e) => { e.preventDefault() }}>
                    <Space style={{ color: "black" }}>
                      {currentYear1}
                      <img src={dropdownicon} />
                    </Space>
                  </a>
                </Dropdown>
              </div>
            </div>
            {barGraphData && (
              <ResponsiveContainer minWidth={450} width='100%' height={347} className='responsiveContainer'>
                <AreaChart
                  data={barGraphData}
                  margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor="#F5FFFD" />
                      <stop offset="100%" stopColor="#F5FFFD" />
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor="rgba(0, 85, 134, 0.3)" />
                      <stop offset="100%" stopColor="rgba(0, 85, 134, 0)" />
                    </linearGradient>
                  </defs>
                  <XAxis padding={{ left: 2, right: 15 }} dataKey="month" axisLine={false} tickLine={false} className="mb-3" />
                  <YAxis
                    tickLine={false}
                    dx={-11}
                    tickCount={12}
                    axisLine={false}
                    tickFormatter={item => item == 0 ? 0 : item > 999 ? `${(item / 1000).toFixed(1)}k` : item}
                    domain={[0, Math.ceil(max / 50) * 50 || 100]}
                  />
                  <CartesianGrid strokeDasharray="0" />
                  <Tooltip
                    content={CustomTooltip}
                    cursor={{ fill: "transparent" }}
                    position={position}
                    separator="false"
                  />
                  <Area
                    type="monotone"
                    dataKey="totalEarnings"
                    stroke="#19B792"
                    fillOpacity={1}
                    fill="url(#colorUv)"
                    strokeWidth={2}
                    padding={{ bottom: 10 }}
                  />
                </AreaChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const Reports = () => {
  return (
    <ReportComponent />
  )
}

export default Reports;