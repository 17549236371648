import React from 'react';
import './dashboardwrapper.less';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import MenuFooter from '../MenuFooter';

const DashBoardWrapper = ({children}) => {
  return (
    <div className="dashboard-wrapper">
      <div className="dashboard-left">
        <Sidebar />
        </div>
        <div className="dashboard-right">
          <Navbar/>
          <div className="dashboard-content">
            {children}
          </div>
          <div className="footermenu text-center"><MenuFooter/></div>
      </div>
     
      </div>
  )

}
export default DashBoardWrapper;