import instance from '../config/axios';
export const getJobsApi=async(userLocation,radius,page)=>{
  try {
    const response = await instance.get(`/job/list-for-contractor/?lat=${userLocation?.lat}&long=${userLocation?.long}&radius=${radius}&page=${page}`);
    return response;
  } catch (error) {
   return error
  }
}
export const getRatings=async(id,page)=>{
  try {
    const response = await instance.get(`/rating/user-rating/${id}?page=${page}`);
    return response;
  } catch (error) {
   return error
  }
}
export const ratingDetails=async(id)=>{
  try {
    const response = await instance.get(`/rating/rating-details/${id}`);
    return response;
  } catch (error) {
   return error
  }
}
export const totalEarningsApi=async()=>{
  try {
    const response = await instance.get(`user/total-earning`);
    return response;
  } catch (error) {
   return error
  }
}
export const transactionsApi=async(year,page)=>{
  try {
    const response = await instance.get(`user/transactions?yearFilter=${year}&page=${page}`);
    return response;
  } catch (error) {
   return error
  }
}

