import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { sessionService } from 'redux-react-session';
import LoginReducer from './slices/login';
import commonReducer from './slices/common';
import breadcumbsReducer from './slices/breadcrum';
import createInfoReducer from './slices/createinfo'
const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, LoginReducer)

const reducers = combineReducers({
  login: persistedReducer,
  common: commonReducer,
  breadCrumbs: breadcumbsReducer,
  createinfo: createInfoReducer,
});

const store = configureStore({
  reducer: reducers,
  devTools: true,
});

 const persistor = persistStore(store);
 sessionService.initSessionService(store)

 export { store, persistor };