import React, { useState, useRef } from 'react';
import Button from 'antd/es/button';
import editicon from '../../../asset/images/EditIcon.png';
import dangericon from '../../../asset/images/danger.svg';
import Form from 'antd/es/form/Form';
import Input from 'antd/es/input/Input';
import { uploadImageApi } from '../../../api/detailsapi';
import { Modal, Spin ,message} from 'antd';
import modalprofile from "../../../asset/images/modalprofile.png"
import dummyicon from '../../../asset/images/dummyicon.svg';
import crossicon from '../../../asset/images/crossicon.svg'


const Contractorprofilepic = ({ handleAddCount, save }) => {
  const [image, setImage] = useState();
  const [description, setDescription] = useState('');
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [profileImg, setProfileImg] = useState()
  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    if (!hasErrors) {
      //console.log
    }
  };
  const [form] = Form.useForm();
  const fileInputRef = useRef(null);
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
      };
      reader.readAsDataURL(file);
    }
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0]) {
        setLoadingProfile(true);
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        const response = await uploadImageApi(formData);
        if (response.type === "success") {
          setImage(response.data.fileUrl)
          setProfileImg({ url: response.data.fileUrl, key: response.data.key });
          message.success('Profile picture uploaded successfully');
          setLoadingProfile(false);

        } else {
          message.error('Profile picture upload unsuccessful!');
          setLoadingProfile(false);
        }
      }
    }
  };

  const handleClose = () => {
    setModal2Open(false);
  }

  const handleChange = (e) => {
    setDescription(e.target.value)
  }

  const handleNext = () => {
    handleAddCount(3);
    save({ profileImage: profileImg, description: description })
  }
  return (
    <>
      <div className="center-container d-flex align-items-center flex-column  profiledetails"  >
        <div className="heading">Profile Picture & Description</div>
        <div className="infoimg" style={{ position: "relative", left: "7%" }}>
          <img src={dangericon} onClick={() => setModal2Open(true)} style={{ position: 'absolute', left: '7`.93rem', cursor: "pointer" }}
          />
        </div>
        <div className="profile_pic_content">
          <div className="profilepic_box"  >
            <Form
              initialValues={{ remember: true }}
              autoComplete="off"
              layout="vertical"
              onFieldsChange={handleFormChange}
              form={form}
            >
              <Spin spinning={loadingProfile}>
                <div className="profilepic_wrapper">
                  <div className="profilepic_circle" >
                    <input
                      type="file"
                      id="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                      onChange={(val) => {
                        handleFileChange(val);
                      }}
                    />
                    {image ?
                      <img
                        src={image}
                        alt="Selected"
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                          objectFit: 'cover',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      /> : <div className="profileplus1"
                      >
                        <img src={dummyicon} style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                          objectFit: 'cover',
                          display: 'flex',
                          alignItems: 'center',
                        }} />
                      </div>}
                  </div>
                  <label htmlFor="file" className="editicon" ><img src={editicon} /></label>
                </div>
              </Spin>
            </Form>
          </div>
          <div className="desc_container">
            {' '}
            <h1>Description</h1>
            <Form.Item
              name="description"
              type="businessdescription"
              rules={[
                {
                  required: true,
                  message: 'Please enter your  description',
                },
              ]}
            >
              <Input.TextArea
                placeholder="Enter Description "
                autoSize={{ minRows: 4, maxRows: 4 }}
                className={description ? 'valid_desc_textarea' : 'desc_textarea'}
                onChange={handleChange}
                value={description}
              />
            </Form.Item>
          </div>
          <Button
            htmlType="submit"
            onClick={handleNext}
            // className="login_form_button"
            className={image && description ? 'profile-submitbtn' : 'profile-submitbtnd'}
          >
            Next
          </Button>
        </div>
      </div>
      {modal2Open && <Modal
        style={{ height: "50vh", width: "40%", position: "relative" }}
        centered
        open={modal2Open}
        closable={false}
        footer={null}
        className='profilepicmodal'
      >
        <>
          <div className="crossicon" style={{ right: "1rem", width: "30px", height: '30px', cursor: 'pointer', position: "absolute" }} onClick={() => { handleClose() }}><img style={{ width: "100%", height: "100%" }} src={crossicon} /></div>
          <div className="d-flex m-auto">
            <img src={modalprofile} className="mt-3" />
          </div>
        </>
      </Modal>}
    </>
  );
};

export default Contractorprofilepic;