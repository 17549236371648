import React, { useState, useEffect, useRef } from "react";
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import { Slider, Spin, message } from 'antd';
import messagesettings from '../../../asset/images/messagesettings.svg';
import tickicon from '../../../asset/images/Successicon.png';
import AddLocation from "../createjob/AddLocation";
import TextArea from "antd/es/input/TextArea";
import editicon from '../../../asset/images/EditIcon.png';
import Modal from "antd/es/modal/Modal";
import ServiceModal from "./ServiceModal";
import QuestionsModal from "./QuestionsModal";
import { useOutsideAlerter } from '../../../utils/outsideClick'
import { useDispatch, useSelector } from "react-redux";
import { uploadImageApi, createProfileClient, getProfileApi } from "../../../api/detailsapi";
import EditProfileWrapper from "../../../components/wrapper/EditProfileWrapper";
import MobileVerificationModal from "./MobileverificationModal";
import { setProfile } from '../../../redux/slices/login';
import infoicon from '../../../asset/images/danger.svg';
import crossicon from '../../../asset/images/crossicon.svg';
import modalprofile from '../../../asset/images/modalprofile.png';
const EditProfileContractor = ({ loading, setLoading }) => {
  const [disabled, setDisabled] = useState(true);
  const [data, setData] = useState({ firstName: '', questions: [] });
  const [address, setAddress] = useState({})
  const [form] = Form.useForm();
  const [selectedItems, setSelectedItems] = useState([]);
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [questionsModal, setQuestionsModal] = useState(false);
  const [dim, setDim] = useState(10);
  const [selectedArray, setSelectesArray] = useState([]);
  const [image, setImage] = useState({});
  const [mobileScreen, setMobileScreen] = useState(false);
  const [itemsSelected, setItemsSelected] = useState([]);
  const [modal2Open, setModal2Open] = useState(false);
  const [loader, setLoader] = useState(false)
  const wrapperRef = useRef();
  const additionalRef = useRef();
  const dispatch = useDispatch();
  const handleFormChange = () => {
    const hasErrors =
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabled(hasErrors);
  }
  const onChange = (e) => {
    setDim(e);
  }
  const callEditProfileApi = async () => {
    setLoading(true)
    const resp = await getProfileApi();
    setData(resp?.data?.data);
    setSelectesArray(resp?.data?.data?.subCategories)
    const val = resp?.data?.data?.subCategories.map((item) => { return item?._id })
    setSelectedItems(val);
    form.setFieldValue("firstName", resp?.data?.data?.firstName);
    form.setFieldValue("lastName", resp?.data?.data?.lastName);
    form.setFieldValue("description", resp?.data?.data?.description);
    setAddress({ address: resp?.data?.data?.locationDetails?.address, city: resp?.data?.data?.locationDetails?.city, province: resp?.data?.data?.locationDetails?.province, postalCode: resp?.data?.data?.locationDetails?.postalCode });
    setDim(resp?.data?.data?.currentRadius);
    setItemsSelected(resp?.data?.data?.subCategories)
    setImage({ url: resp?.data?.data?.profileImage?.url, key: resp?.data?.data?.profileImage?.key })
    setLoading(false)
  }
  const handleImg = async (e) => {
    setLoader(true);
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
      };
      reader.readAsDataURL(file);
    }
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0]) {
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        const response = await uploadImageApi(formData);
        if (response.type === "success") {
          setImage({ url: response.data.fileUrl, key: response.data.key });
        }
      }
    }
    setLoader(false);
  };
  const handleClose = () => {
    setModal2Open(false)
  }
  const handleSave = async () => {
    const val = { firstName: data?.firstName, lastName: data?.lastName, questions: data?.questions, currentRadius: dim, locationDetails: { ...address }, subCategories: selectedArray, description: data?.description, profileImage: image, location: address?.location };

    const resp = await createProfileClient(val);
    if (resp?.status == 200)
      callEditProfileApi();
    dispatch(setProfile({ firstName: data.firstName, lastName: data.lastName, profileImage: image.url, id: data._id }))
    message.success("Your profile has been updated successfully!")
  }
  const closeModal = () => {
    setQuestionsModal(false);
  }
  useEffect(() => {
    callEditProfileApi();
  }, [])
  useEffect(() => {
    if (address?.city != '' && address?.postalCode != '' && address?.province && address?.address && data.firstName != '' && data.lastName != '' && dim != 0)
      setDisabled(false);
    else
      setDisabled(true);
  }, [address, data, dim])
  useOutsideAlerter(wrapperRef, questionsModal, closeModal);
  return (
    <>
      <div className="settingcard">
        <Spin spinning={loading}>
          <Form
            name="name-form"
            initialValues={{ remember: true }}
            autoComplete="off"
            layout="vertical"
            onFieldsChange={handleFormChange}
            form={form}
          >
            <div className="file-upload">

              <div className="file-upload-box1">
                <>
                  <div className="infoicon" onClick={() => { setModal2Open(true) }}><img src={infoicon} /></div>
                  <Spin spinning={loader}>
                    <input id='file' className="btn-3"
                      accept="image/*" type="file" style={{ marginLeft: "120px", marginTop: "40px", display: "none" }}
                      onChange={(val) => { handleImg(val) }}
                    ></input>
                    <div className="profileplus">
                      <img src={image?.url} style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "50%" }} />
                    </div>
                    <label htmlFor="file" className="camicon"><img src={editicon} className="img" /></label>
                  </Spin>
                </>
              </div>
            </div>
            <div style={{ color: "#6B6B6B", fontSize: "16px" }} className="mb-3 mt-2 d-block">First name and last name </div>

            <div className="subform">
              <div className="subformcontent" >
                <Form.Item
                  name="firstName"
                  className="mb-3"
                  rules={[
                    {
                      type: 'name',
                      message: 'Please enter a valid first name',
                    },
                    {
                      required: true,
                      message: 'Please enter your first name',
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter First Name" value={data?.firstName} className={data?.firstName ? "input-green input-field" : "input-field"} onChange={(e) => setData({ ...data, firstName: e.target.value })} />
                </Form.Item>
              </div>
              <div className="subformcontent1" >
                <Form.Item
                  name="lastName"
                  className="mb-3"
                  rules={[
                    {
                      type: 'name',
                      message: 'Please enter a valid last name',
                    },
                    {
                      required: true,
                      message: 'Please enter your last name',
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter Last Name" className={data?.lastName ? "input-green input-field" : "input-field"} value={data?.lastName} onChange={(e) => setData({ ...data, lastName: e.target.value })} />
                </Form.Item>
              </div>
            </div>
            <div className="subform">
              <EditProfileWrapper setMobileScreen={setMobileScreen} isClient={false}>
                <div className="countrycode mr-1" onClick={() => { setMobileScreen(true) }}>{data?.countryCode}</div>
                <div className="mobilenumber mr-1">{data?.phoneNumber?.toString().substring(0, 3)} {data?.phoneNumber?.toString().substring(3, 6)} {data?.phoneNumber?.toString().substring(6, 10)}</div>
                <img src={tickicon} className="tickicon" />
              </EditProfileWrapper>
              <div className="subformcontent1" >
                <div style={{ color: "#6B6B6B", fontSize: "16px" }} className="mb-2">Email address </div>
                <div className="phonenumber phonenumber1">
                  <img src={messagesettings} />

                  <div className="mobilenumber mx-2" >{data?.email}</div>
                  <img src={tickicon} className="tickicon" />
                </div>
              </div>
            </div>
            <hr />
            <div className="contentheading">Your Services</div>
            <div className="subform">
              <img src={editicon} className="editicon" onClick={() => { setOpenServiceModal(true) }} />
              {itemsSelected.map((item) => {
                return (
                  <div className="sufraceapplied ">
                    <div className="singleServiceapplied d-flex flex-column text-center  align-items-center justify-content-center ">
                      <div className="img mb-2"><img src={item.subCategoryImage.url} /></div>
                      <div className="serviceinfo">{item?.name}</div>
                    </div>
                  </div>
                )
              })}

            </div>
            <div className="contentheading">Address details</div>
            <div className="subform">
              {address != null && <AddLocation setAddress={setAddress} fromTab="settings" address={address}  isEdit={true} />}
            </div>
            <div className="contentheading mb-5">Radius of travel from current location</div>
            <div className="subform">
              <Slider
                className="simple_slider"
                railStyle={{
                  borderRadius: "1.25rem",
                  background: "#CCECE4",
                  height: " 0.75rem",
                  flexShrink: 0,
                  top: "12px"
                }}
                trackStyle={{

                  height: "0.75rem",
                  borderRadius: "1.25rem",
                  background: "#0FF4BD",
                  top: "12px"
                }}
                handleStyle={{
                  background: "#FFF",
                  borderRadius: "50%",
                  border: "3px solid #0FF4BD",
                  width: " 1.1rem",
                  height: "1.1rem",
                  zIndex: "1",
                  top: "8px",

                }}
                min={0}
                max={200}
                step={1}
                value={dim}
                style={{ width: "99%", position: "absolute", marginTop: "-2rem" }}
                onChange={onChange}
                tooltip={{
                  open: false,
                }}
              />
            </div>
            <div className="distanceinfo d-flex justify-content-between">
              <div className="mindistance">0km</div>
              <div className="maxdistance">200km</div>
            </div>
            <div className="m-auto sliderinfo">{dim}km</div>
            <hr style={{ marginTop: "2rem" }} />
            <div className="contentheading">Description</div>
            <div className="subform" >
              <Form.Item
                name="description"
                className="mb-3 w-100 "
                rules={[
                  {
                    type: 'name',
                    message: 'Please enter a valid last name',
                  },
                  {
                    required: true,
                    message: 'Please enter your last name',
                  },
                ]}
              >
                <TextArea size="large" style={{ height: "100px" }} value={data?.description} ref={additionalRef} onChange={(e) => { setData({ ...data, description: e.target.value }) }} />
              </Form.Item>
            </div>
            <div className="contentheading">Questions(S)</div>
            <div className="subform w-100">
              <img src={editicon} className="editicon" onClick={() => { setQuestionsModal(true) }} />
              <div className="subforminfo w-100">
                <div className="questioninfo">
                  <div className="question">
                    Do you have 2 Million liability Insurance or do you intend to supply it prior to approval by MyInstallr?
                  </div>
                  <div className="answer">{data ? data?.questions[0]?.answer : ""}</div>
                </div>
                <div className="questioninfo">
                  <div className="question">Do you have any Recognized Certifications</div>
                  <div className="answer">{data ? data?.questions[1]?.answer : ""}</div>
                </div>
                <div className="questioninfo">
                  <div className="question">How many year’s experience do you have as an installr?</div>
                  <div className="answer">{data ? data?.questions[2]?.answer : ""}</div>
                </div>
                <div className="questioninfo">
                  <div className="question">Do you have a criminal record or any restrictions from working?</div>
                  <div className="answer">{data ? data?.questions[3]?.answer : ""}</div>
                </div>

              </div>
            </div>
          </Form>
        </Spin>
        <Button
          htmlType="submit"
          style={{ height: "50px" }}
          className={disabled ? 'editsave_btndisabled' : 'editsave_btn'}
          type="primary"
          size="large"
          disabled={disabled}
          onClick={() => { handleSave() }}
        >
          save
        </Button>
      </div>
      {openServiceModal && <Modal
        centered
        className='serviceinfo'
        closable={false}

        header={null}
        footer={null}
        style={{ top: '', overflow: "hidden !important" }}
        open={openServiceModal}
      >
        <>
          <img src={crossicon} style={{
            cursor: "pointer", position: "absolute", right: "5px",
            top: "4px",
            width: "30px"
          }} onClick={() => { setOpenServiceModal(false) }} />
          <ServiceModal setOpenServiceModal={setOpenServiceModal} setSelectesArray={setSelectesArray} selectedArray={selectedArray} setSelectedItems={setSelectedItems} selectedItems={selectedItems} setItemsSelected={setItemsSelected} />
        </> </Modal>}
      {questionsModal &&
        < Modal
          centered
          className='questionmodal'
          closable={false}

          header={null}
          footer={null}
          style={{ top: '', overflow: "hidden !important" }}
          open={questionsModal}
        >
          <div ref={wrapperRef}>
            <QuestionsModal data={data} setData={setData} setQuestionsModal={setQuestionsModal} /></div>
        </Modal>
      }
      {mobileScreen &&
        <MobileVerificationModal mobileScreen={mobileScreen} setMobileScreen={setMobileScreen}
          phoneNum={`${data?.countryCode}${data?.phoneNumber}`}
          data={data} setData={setData} />
      }
      {<Modal
        style={{ height: "50vh", width: "40%", position: "relative" }}
        centered
        open={modal2Open}
        closable={false}
        footer={null}
        className='profilepicmodal'
      >
        <>
          <div className="crossicon" style={{ right: "1rem", width: "30px", height: '30px', cursor: 'pointer', position: "absolute" }} onClick={() => { handleClose() }}><img style={{ width: "100%", height: "100%" }} src={crossicon} /></div>
          <div className="d-flex m-auto">
            <img src={modalprofile} className="mt-3" />
          </div>
        </>
      </Modal>}
    </>
  )
}

export default EditProfileContractor;