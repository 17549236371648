import { useState, useEffect } from 'react';
import { Menu, Button } from 'antd';
import Modal from 'antd/es/modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import ContactusIcon from './menusvg/ContactsusIcon';
import DashBoardIcon from './menusvg/DashBoardIcon';
import JobIcon from './menusvg/JobIcon';
import LogoutIcon from './menusvg/LogoutIcon';
import MessageIcon from './menusvg/MessageIcon';
import NewJobsIcon from './menusvg/NewJobsIcon';
import PlusIcon from './menusvg/PlusIcon';
import RatingsIcon from './menusvg/RatingsIcon';
import SettingsIcon from './menusvg/SettingsIcon';
import TotalEarningsIcon from './menusvg/TotalEarningsIcon';
import './style.less';
import logouticon from '../asset/images/logouticon.png';
import logo from '../asset/images/myinstallerlogo.svg';
import { setUnReadCount } from '../redux/slices/common';
import { setCreateObject } from '../redux/slices/createinfo';
import { setCount, setNavCount, clearProfile } from '../redux/slices/login';

import {callUnReadMessageApi} from '../api/notifications';

const Sidebar = () => {
  const [handleLogoutModal, setHandleLogoutModal] = useState(false);
  const loaction = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const screen = queryParam.get('screen');
  const unReadCount=useSelector(state=>state.common.unReadCount);
  const [countVal, setCountVal] = useState(
    loaction.pathname.includes('createjob')
      ? '1'
      : loaction.pathname.includes('jobs')
      ? '2'
      : loaction.pathname.includes('messages')
      ? '3'
      : loaction.pathname.includes('settings')
      ? '4'
      : '5'
  );
  const [contractVal, setContractVal] = useState(
    loaction.pathname.includes('/dashboard')
      ? '1'
      : loaction.pathname.includes('newjob') || screen == 1
      ? '2'
      : loaction.pathname.includes('contractorjoblist') || screen == 2
      ? '3'
      : loaction.pathname.includes('messages')
      ? '4'
      : location?.pathname?.includes('earnings')
      ? '5'
      : location?.pathname.includes('ratings')
      ? '6'
      : location.pathname.includes('settings')
      ? '7'
      : '8'
  );
  let count = useSelector((state) => state?.login?.count);
  const role = localStorage.getItem('role');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    setHandleLogoutModal(false);
    localStorage.clear();
    dispatch(clearProfile());
    dispatch(setCreateObject({}));
    if (screen == 1) dispatch(setCount(1));
    navigate('/');
  };
  const handleLogoutClose = () => {
    setHandleLogoutModal(false);
  };

  const navigateRoute = (url, tabname) => {
    navigate(url, { state: { tabname: tabname } });
  };
  const handleRefreshChange = () => {
    if (loaction.pathname.includes('createjob')) dispatch(setCount(1));
    else if (loaction.pathname.includes('jobs')) dispatch(setCount(2));
    else if (loaction.pathname.includes('messages')) dispatch(setCount(3));
    else if (loaction.pathname.includes('settings')) dispatch(setCount(4));
    else if (loaction.pathname.includes('contactus')) dispatch(setCount(5));
  };

  const handleRefreshChangeCont = () => {
    if (loaction.pathname.includes('dashboard')) dispatch(setCount(1));
    else if (loaction.pathname.includes('newjob') || (screen == 1)) dispatch(setCount(2));
    else if (loaction.pathname.includes('contractorjoblist') || screen == 2) dispatch(setCount(3));
    else if (loaction.pathname.includes('messages')) dispatch(setCount(4));
    else if (loaction.pathname.includes('earnings')) dispatch(setCount(5));
    else if (loaction.pathname.includes('ratings')) dispatch(setCount(6));
    else if (loaction.pathname.includes('settings')) dispatch(setCount(7));
    else if (loaction.pathname.includes('contactus')) dispatch(setCount(8));
  };
  const menuclient = [
    { key: '1', navigatePath: '/createjob', icon: PlusIcon, val: 1, tabname: 'Create' },
    { key: '2', navigatePath: '/jobs', icon: JobIcon, val: 2, tabname: 'Jobs' },
    { key: '3', navigatePath: '/messages', icon: MessageIcon, val: 3, tabname: 'Messages' },
    { key: '4', navigatePath: '/settings', icon: SettingsIcon, val: 4, tabname: 'Settings' },
    { key: '5', navigatePath: '/contactus', icon: ContactusIcon, val: 5, tabname: 'Contact Us' },
  ];
  const menucontractor = [
    { key: '1', navigatePath: '/dashboard', name: 'DashBoard', val: 1, icon: DashBoardIcon },
    { key: '2', navigatePath: '/newjob', name: 'New Jobs', val: 2, icon: NewJobsIcon },
    { key: '3', navigatePath: '/contractorjoblist', name: 'My Jobs', val: 3, icon: JobIcon },
    { key: '4', navigatePath: '/messages', name: 'Messages', val: 4, icon: MessageIcon },
    { key: '5', navigatePath: '/earnings', name: 'Total Earnings', val: 5, icon: TotalEarningsIcon },
    { key: '6', navigatePath: '/ratings', name: 'Ratings', val: 6, icon: RatingsIcon },
    { key: '7', navigatePath: '/settings', name: 'Settings', val: 7, icon: SettingsIcon },
    { key: '8', navigatePath: '/contactus', name: 'Contact Us', val: 8, icon: ContactusIcon },
  ];
  useEffect(() => {
    if (localStorage.getItem('role') == '0') setCountVal(count);
    else setContractVal(count);
  }, [count]);
  useEffect(() => {
    if (localStorage.getItem('role') == '0') handleRefreshChange();
    else handleRefreshChangeCont();
  });
  const callMessageUnReadCount=async()=>{
    const resp =await callUnReadMessageApi();
   dispatch(setUnReadCount(resp?.data?.data?.totalunReadCount));
  }
  useEffect(()=>{
    callMessageUnReadCount();
  },[])
  //console.log(contractVal,"....")
  return (
    <>
      <div className="sidebar">
        <div className="aipimg">
          <img src={logo} alt="myinstallerimg" style={{ width: '100%', height: '100%' }} />
        </div>
        {role == 0 ? (
          <Menu
            className="ant-menu-dark sidebarmenu"
            defaultSelectedKeys={[`${countVal}`]}
            defaultOpenKeys={[`${countVal}`]}
            theme="dark"
          >
            <>
              {menuclient.map((item) => {
                return (
                  <Menu.Item
                    className="customclass"
                    key={item.key}
                    onClick={() => {
                      navigateRoute(item.navigatePath, item.tabname);
                      dispatch(setCount(item.val));
                      dispatch(setNavCount(item.val));
                    }}
                  >
                    {countVal == item.key ? <item.icon val={true} /> : <item.icon val={false} />}
                    <span className={countVal == item.val ? 'sidemenuitem sideweight' : 'sidemenuitem sideweightwhite'}>
                      {item.tabname}
                      {item.tabname == 'Messages' && unReadCount>0
                        && (
                          <span style={{ color: 'white', backgroundColor: 'red',padding:"0px 8px",borderRadius:"50%",position:"absolute" ,right:"1rem",fontSize:"14px"}}>{unReadCount}</span>
                        )}
                    </span>
                  </Menu.Item>
                );
              })}
            
              <div
                key="6"
                className="customclass logout-btn"
                onClick={() => {
                  setHandleLogoutModal(true);
                 // dispatch(setCount(6));
                }}
              >
                <LogoutIcon value={false} />
                <span className={ 'sidemenuitem sideweightwhite'}>Logout</span>
              </div>
            </>
          </Menu>
        ) : (
          <>
            <Menu
              className="ant-menu-dark sidebarmenu"
              defaultSelectedKeys={[`${contractVal}`]}
              defaultOpenKeys={[`${contractVal}`]}
              theme="dark"
            >
              {menucontractor.map((item) => {
                return (
                  <Menu.Item
                    className="customclass"
                    key={item.key}
                    onClick={() => {
                      navigateRoute(item.navigatePath, item.name);
                      dispatch(setCount(item.val));
                    }}
                  >
                    {contractVal == item.key ? <item.icon val={true} /> : <item.icon val={false} />}
                    <span
                      className={contractVal == item.val ? 'sidemenuitem sideweight' : 'sidemenuitem sideweightwhite'}
                    >
                      {item.name}
                      {item?.name == 'Messages' && unReadCount > 0 && (
                        <span
                          style={{
                            color: 'white',
                            backgroundColor: 'red',
                            padding: '0px 5px',
                            borderRadius: '50%',
                            position: 'absolute',
                            right: '1rem',
                            fontSize:"14px"
                          }}
                        >
                          {unReadCount}
                        </span>
                      )}
                    </span>
                  </Menu.Item>
                );
              })}
              <div
                key="9"
                className="customclass logout-btn"
                onClick={() => {
                  setHandleLogoutModal(true);
                 // dispatch(setCount(9));
                }}
              >
           <LogoutIcon value="false" />
                <span className={'sidemenuitem sideweightwhite'}>Logout</span>
              </div>
            </Menu>
          </>
        )}
      </div>
      <Modal
        centered
        width={390}
        header={null}
        footer={null}
        open={handleLogoutModal}
        className="logoutmodal"
        closable={false}
      >
        <div className="crossImage">
          <img src={logouticon} className="text-center" />
        </div>
        <div className="logoutdesc">Are you sure you want to Logout?</div>
        <div
          className="confirmButtons"
          style={{ justifyContent: 'space-between', display: 'flex', height: '50px', marginTop: '10px' }}
        >
          <Button className="yesbtn" onClick={handleLogout}>
            Yes
          </Button>
          <Button className="nobtn" onClick={handleLogoutClose}>
            No
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default Sidebar;
