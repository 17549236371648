import instance from '../config/axios';
export const signupUser = async (values) => {
  try {
    const response = await instance.post(`user/signup`, values);
    return response;
  } catch (error) {
    return error;
  }
};

export const signinUser = async (values) => {
  try {
    const response = await instance.post(`user/login`, values);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const forgetPass = async (values) => {
  try {
    const response = await instance.post(`user/forgot-password`, values);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const sendOtp = async (values) => {
  try {
    const response = await instance.post(`user/send-otp`, values);
    return response;
  } catch (error) {
    return error;
  }
};

export const reSendOtp = async (values) => {
  try {
    const response = await instance.post(`user/re-send-otp`, values);
    return response;
  } catch (error) {
    return error;
  }
};
export const verifyOTP = async (values) => {
  try {
    const response = await instance.post(`user/verify-otp`, values);
    return response;
  } catch (error) {
    return error;
  }
};
export const CategoryList = async (values) => {
  try {
    const response = await instance.get(`category/list-for-contractor`, values);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const uploadImageApi = async (formData) => {
  try {
    const response = await instance.post(`file/upload`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
export const createProfileClient = async (values) => {
  try {
    const response = await instance.put(`user/create-profile`, values);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
export const categoryList = async (value) => {
  try {
    const response = await instance.get(`category/category-list-for-user?parentCategory=${value}`);
    return response;
  } catch (error) {
    //  throw error;
  }
};
export const subCategoryList = async (value) => {
  try {
    const response = await instance.get(`category/sub-category-list-for-user?categoryId=${value}`);
    return response;
  } catch (error) {
    //throw error;
  }
};

export const getProfileApi = async () => {
  try {
    const response = await instance.get(`user/profile`);
    return response;
  } catch (error) {
    //throw error;
  }
};

export const changePasswordApi = async (values) => {
  try {
    const response = await instance.put(`user/change-password`, values);
    return response;
  } catch (error) {
    //throw error;
  }
};
export const addBankDetailsApi = async (values) => {
  try {
    const response = await instance.post(`job/verify-bankdetails`, values);
    return response;
  } catch (error) {
    //throw error;
  }
};
export const contactUsApi = async (val) => {
  try {
    const response = await instance.post(`admin-user/contact-us`, val);
    return response;
  } catch (error) {
    //throw error;
  }
};
