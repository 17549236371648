import React from 'react'

function LocationInfo({ locationred, calendergreen, singleJobInfo, timecircle }) {
  return (
    <div className="locationinfo d-flex">
      <span><img src={locationred} style={{ width: "20px", height: "18px" }} /></span>
      <span>{singleJobInfo?.locationId?.address} </span>
      <span className="calenderimage"><img src={calendergreen} style={{ width: "20px", height: "18px", marginLeft: "20px", marginRight: "5px" }} /></span>
      <span>{singleJobInfo?.jobStartDate}</span><span className="clockimage"><img style={{ width: "20px", height: "18px", left: "20px", marginLeft: "15px", marginRight: "5px" }} src={timecircle} /></span>
      <span>{singleJobInfo?.jobStartTime}</span>
    </div>
  )
}

export default LocationInfo