import React, { useState, useEffect } from 'react';
import { Button, Modal, message } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import ModalSingle from '../../../components/Modal';
import DashBoardWrapper from '../../../components/wrapper/DashBoardWrapper';
import SpinWrappper from '../../../components/wrapper/SpinWrapper';
import IsLiftRequiredInfo from '../../../pages/client/Jobs/IsLiftRequiredInfo';
import LocationInfo from '../../../pages/client/Jobs/LocationInfo';
import SubCategoryInfo from '../../../pages/client/Jobs/SubCategoryInfo';
import AddExpense from './AddExpense';
import CompleteModalPopup from './CompleteModalPopup';
import LiftInfo from './LiftInfo';
import './style.less';
import calendergreen from '../../../asset/images/calendargreen.svg';
import crossicon from '../../../asset/images/crossicon.svg';
import declinere from '../../../asset/images/declinejobre.svg';
import installimg from '../../../asset/images/installimg.svg';
import removalimg from '../../../asset/images/installimg1.svg';
import liftrequiredbtn from '../../../asset/images/liftrequiredgreenbtn.svg';
import locationgreen from '../../../asset/images/locationred.svg';
import notrequiredliftbtn from '../../../asset/images/notrequiredliftbtn.svg';
import pdfimage from '../../../asset/images/pdfimage.svg';
import sitecheckimg from '../../../asset/images/sitecheckimg.svg';
import sucessfulicon from '../../../asset/images/Successful.svg';
import timecircle from '../../../asset/images/timecirclegreen.svg';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { pushBreadCrums } from '../../../redux/slices/breadcrum';
import { showLoader } from '../../../redux/slices/common';
import { setCount } from '../../../redux/slices/login';

import { cancelJobAppointMentApiContractor, jobStartApi, jobCompleteApi } from '../../../api/contractorjobs';
import { notIntrestedApi, applyJobApi, getSingleJob } from '../../../api/createjob';
import { uploadImageApi } from '../../../api/detailsapi';

const SingleJobInfo = () => {
  const [item, setItem] = useState();
  const [popupModal, setPopupModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [pdfView, setPdfView] = useState(false);
  const [url, setUrl] = useState();
  const [feedBack, setFeedBack] = useState(false);
  const [expenseInfo, setExpenseInfo] = useState({ expenses: [], completeJobAttachments: [], completeJobNotes: '' });
  const [completeModal, setCompleteModal] = useState(false);
  const [expenseModal, setExpenseModal] = useState(false);
  const [expenses, setExpenses] = useState({});
  const [showBtns, setShowBtns] = useState(false);
  const [completeJob, setCompleteJob] = useState(false);
  const [loader, setLoader] = useState(false);
  const [countNumber, setCountNumber] = useState();
  const [bankPopUp, setBankPopUp] = useState(false);
  const [cancelMessage, setCancelMessage] = useState();
  const [messageModal, setMessageModal] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParam = new URLSearchParams(location.search);
  const id = queryParam.get('id');
  const screen = queryParam.get('screen');
  const profileInfo = useSelector((state) => state.login.profileInfo);
  const callApi = async (id) => {
    dispatch(showLoader(true));
    const response = await getSingleJob(id);
    setItem(response?.data?.data);
    if (location?.state?.type == 'ACCEPTED_APPLICATION' && response?.data?.data?.jobStatus == 'Pending')
      message.error('Job Cancelled');
    dispatch(showLoader(false));
  };

  const handleYes = async () => {
    setOpenModal(false);
    try {
      const resp = await cancelJobAppointMentApiContractor(id, feedBack);
      if (resp?.status == 200) {
        message.success('Job has been cancelled sucessfully');
        if (item?.jobStatus == 'Pending') navigate('/contractorjoblist');
        else {
          if (resp?.data?.data?.cancelType == 3) {
            setMessageModal(true);
            setCancelMessage(resp?.data?.data?.message);
          } else navigate('/contractorjoblist', { state: { activeTab: 2 } });
        }
      }
    } catch (err) {
      message.error('you have cancelled your job 3 times so your job has been deleted');
    }
  };
  const handleNotIntreseted = async (id) => {
    dispatch(showLoader(true));
    const resp = await notIntrestedApi({ jobId: id });
    if (resp?.status == 200) navigate('/newjob');
  };
  const handleApplyJob = async (id) => {
    dispatch(showLoader(true));
    const resp = await applyJobApi({ jobId: id });
    if (resp?.status == 200) {
      setPopupModal(true);
    }
    dispatch(showLoader(false));
  };

  const handleFileChange = async (e, value) => {
    setLoader(true);
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {};
      reader.readAsDataURL(file);
    }
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0]) {
        let formData = new FormData();
        formData.append('file', e.target.files[0]);
        const response = await uploadImageApi(formData);
        if (response.type === 'success') {
          if (value == 1)
            setExpenseInfo({
              ...expenseInfo,
              completeJobAttachments: [{ url: response.data.fileUrl, key: response.data.key }],
            });
          else setExpenses({ ...expenses, image: { url: response.data.fileUrl, key: response.data.key } });
        }
      }
    }
    setLoader(false);
  };
  const handleStartJob = async (id) => {
    if (profileInfo?.bankDetails) {
      handleIAck();
    } else setBankPopUp(true);
  };
  const handleDeleteExpense = (id) => {
    const val = expenseInfo?.expenses.splice(id, 1);
    const val1 = { ...expenseInfo, expenses: val };
    setExpenseInfo(val1);
    setExpenses({});
  };
  const handleAddExpense = () => {
    if (countNumber >= 0) {
      const val = [...expenseInfo?.expenses];
      val[countNumber] = expenses;
      const val1 = { ...expenseInfo, expenses: val };
      setExpenseInfo(val1);
      setCountNumber();
    } else {
      const exp = expenseInfo.expenses?.length ? [...expenseInfo.expenses, expenses] : [expenses];
      const val = expenseInfo && { ...expenseInfo, expenses: exp };
      setExpenseInfo(val);
    }
    setExpenseModal(false);
    setCompleteModal(true);
    setExpenses({});
  };
  const handleClickMessage = () => {
    let job = [{ _id: '', jobStartDate: '', jobStartTime: '' }];
    let location = [];
    let userId = { _id: '' };
    userId._id = item?.createdBy?._id;
    let category = [{ _id: '', name: '' }];
    job[0]._id = item?._id;
    // job[0].locationId=singleJobInfo?.locationId;
    job[0].jobStartDate = item?.jobStartDate;
    job[0].jobStartTime = item?.jobStartTime;
    category[0]._id = item?.categoryId?._id;
    category[0].name = item?.categoryId?.name;
    location[0] = item?.locationId;
    navigate('/messages', {
      state: {
        val: {
          firstName: item?.createdBy?.fristName,
          lastName: item?.createdBy?.lastName,
          profileImage: null,
          _id: item?.createdBy?._id,
          job: job,
          category: category,
          location: location,
          userId: userId,
        },
      },
    });
    dispatch(setCount(4));
  };
  const handleIAck = async () => {
    setBankPopUp(false);
    dispatch(showLoader(true));
    const resp = await jobStartApi(id);
    if (resp?.status === 200) {
      setPopupModal(true);
    }
    dispatch(showLoader(false));
  };
  const callAddExpenseApi = async () => {
    const resp = await jobCompleteApi(id, expenseInfo);
    setCompleteModal(false);
    if (resp?.status == 200) {
      callApi(id);
      setShowBtns(true);
      setCompleteJob(true);
      message.success('Job has been marked completed successfully!');
    }
  };
  useEffect(() => {
    callApi(id);
  }, [id]);

  useEffect(() => {
    if (screen == 1) {
      dispatch(
        pushBreadCrums(
          [
            {
              title: 'New Jobs',
              route: '/newjob',
              tabname: 'Jobs',
              activeTab:
                item?.jobStatus == 'Pending'
                  ? 1
                  : item?.jobStatus == 'UpComing' || item?.jobStatus == 'OnGoing'
                  ? 2
                  : 3,
            },
          ].concat({
            title: `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}`,
            route: '',
          })
        )
      );
    } else
      dispatch(
        pushBreadCrums(
          [
            {
              title: 'Jobs',
              route: '/contractorjoblist',
              tabname: 'Jobs',
              activeTab:
                item?.jobStatus == 'Pending'
                  ? 1
                  : item?.jobStatus == 'UpComing' || item?.jobStatus == 'OnGoing'
                  ? 2
                  : 3,
            },
          ].concat({
            title: `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}`,
            route: '',
          })
        )
      );
  }, [item?.createdBy?.firstName]);
  return (
    <DashBoardWrapper>
      <SpinWrappper>
        <div className="d-flex justify-content-between">
          <div className="">
            <BreadCrumbs />
          </div>
          {screen != 1 && item?.jobStatus && (
            <div
              className={
                item?.jobStatus == 'UpComing'
                  ? 'statusup statusinfo'
                  : item?.jobStatus == 'OnGoing'
                  ? 'statuson statusinfo'
                  : item?.jobStatus == 'Completed'
                  ? 'statuspast statusinfo'
                  : item?.jobStatus == 'Pending' && location?.state?.type == 'REJECTED_APPLICATION'
                  ? 'staturejected statusinfo'
                  : item?.jobStatus == 'Pending'
                  ? 'statuspending statusinfo'
                  : 'statuspayment statusinfo'
              }
            >
              {item?.jobStatus == 'UpComing'
                ? 'Upcoming'
                : item?.jobStatus == 'OnGoing'
                ? 'Ongoing'
                : item?.jobStatus == 'Completed'
                ? 'Completed'
                : item?.jobStatus == 'Pending' && location?.state?.type == 'REJECTED_APPLICATION'
                ? 'REJECTED'
                : item?.jobStatus == 'Pending'
                ? 'Pending'
                : item?.jobStatus == 'Cancelled'
                ? 'Cancelled'
                : 'Payment Pending'}
            </div>
          )}
        </div>
        <div className="singlejobinfo">
          <div className="jobinfodetails">
            <div className="row1 d-flex justify-content-between">
              <div className="para1">
                <div className="rowfirst">
                  {item?.createdBy?.firstName} {item?.createdBy?.lastName}
                </div>
                <div className="rowsecond">{item?.locationId?.name}</div>
              </div>
              <div className="para2">
                <div className="ammount">${Math.abs(item?.contractorApproxCost).toFixed(2)}</div>
                <div className="approxcost">Approx Cost</div>
              </div>
            </div>
            <hr />
            <LocationInfo
              locationred={locationgreen}
              singleJobInfo={item}
              timecircle={timecircle}
              calendergreen={calendergreen}
            />
          </div>
          <div className="typeofjob">
            <div className="typeofjobname">Type of Job</div>
            <div className="typeofjobimg">
              <img
                src={
                  item?.categoryId?.parentCategory[0] == 'RM' ? installimg : item?.categoryId?.parentCategory[0] == 'IN' ? removalimg : sitecheckimg
                }
              />
              <div className="jobname">
                {item?.categoryId?.parentCategory[0] == 'RM' ? 'Removal' : item?.categoryId?.parentCategory[0] == 'IN' ? 'Install' : 'Site Check'}
              </div>
            </div>
          </div>
          <div className="my-3 serviceheading">Services</div>
          <div className="services">
            <div className="singleService">
              <div className="img">
                <img src={item?.categoryId?.categoryImage?.url} />
              </div>
              <div className="serviceinfo">{item?.categoryId?.name}</div>
            </div>
          </div>
          <div className="serviceheading">Surface Applied To</div>
          <SubCategoryInfo singleJobInfo={item} />
          {item?.categoryId?.parentCategory[0] != 'SC' && (
            <>
              <div className="serviceheading">Surface Details</div>
              <div className="surfacedetails">
                <div className="surfaceiteminfo">
                  <div className="surfaceitemname">Width</div>
                  <div className="surfaceitemvalue">
                    {item?.width}
                    <span className="surfaceitemnamespan">(Feet)</span>
                  </div>
                </div>
                <hr />
                <div className="surfaceiteminfo">
                  <div className="surfaceitemname">Height</div>
                  <div className="surfaceitemvalue">
                    {item?.height}
                    <span className="surfaceitemnamespan">(Feet)</span>
                  </div>
                </div>
                <hr />
                <div className="surfaceiteminfo">
                  <div className="surfaceitemname">Quantity</div>
                  <div className="surfaceitemvalue">{item?.quantity}</div>
                </div>
              </div>
            </>
          )}
          <div className="liftbox">
                <div className="serviceheading">Lift Required</div>
                <IsLiftRequiredInfo
                  singleJobInfo={item}
                  liftrequiredbtn={liftrequiredbtn}
                  notrequiredliftbtn={notrequiredliftbtn}
                />
              </div>
          {item?.isLiftRequired && (
            <div className="liftinfo">
              <LiftInfo liftSize={item?.liftSize} />
            </div>
          )}
          {item?.attachedFiles?.length > 0 && <div className="serviceheading">Document/Images</div>}

          <div className="doucmentimages mb-2">
            <div className="images">
              {item?.attachedFiles?.map((item1) => {
                return item1?.type?.includes('image') ? (
                  <div
                    className="image m-1"
                    onClick={() => {
                      setUrl(item1?.url);
                      setPdfView(true);
                    }}
                  >
                    <img src={item1.url} />
                  </div>
                ) : (
                  <div
                    className="image m-1"
                    onClick={() => {
                      setUrl(item1?.url);
                      setPdfView(true);
                    }}
                  >
                    <img src={pdfimage} />
                  </div>
                );
              })}
            </div>
          </div>
          {item?.additionalNotes && (
            <>
              <div className="my-3 serviceheading">Addditional Note</div>
              <div className="additionalnote mb-5">{item?.additionalNotes}</div>
            </>
          )}
          {item?.expenses.length > 0 && (
            <div className="expenseslist mb-3">
              <div className="expenses my-3 serviceheading">Expenses</div>
              {item?.expenses?.map((item) => {
                return (
                  <div
                    className="expensedata d-flex justify-content-between align-content-center"
                    key={item?.image?.url}
                  >
                    <div className="expensel d-flex ">
                      <div className="expenseimg mr-3">
                        <img src={item?.image?.url} />
                      </div>
                      <div className="d-flex flex-column ">
                        <div className="expensename ">{item?.name}</div>
                        <div className="expenseprice">${Math.abs(item?.price).toFixed(2)}</div>
                      </div>
                    </div>
                    <div
                      className={
                        item?.status == 'Pending'
                          ? 'pendingexp d-flex align-items-center'
                          : item?.status == 'Approved'
                          ? 'paidexpense d-flex align-items-center'
                          : 'rejectedexpense d-flex align-items-center'
                      }
                    >
                      {item?.status}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {screen == 1 && !showBtns ? (
          <div className="locationbtns">
            <Button
              className="notbtn"
              style={{ width: '195px' }}
              onClick={() => {
                handleNotIntreseted(item?._id);
              }}
            >
              Not intrested
            </Button>
            <Button
              className="applybtn"
              style={{ width: '195px' }}
              onClick={() => {
                handleApplyJob(item?._id);
              }}
            >
              Apply
            </Button>
          </div>
        ) : !completeJob ? (
          item?.jobStatus == 'Pending' ? (
            <div className="locationbtns">
              <Button
                className="notbtn"
                style={{ width: '195px' }}
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                Cancel Request
              </Button>
              <Button className="appiedbtn" disabled={true}>
                Applied
              </Button>
            </div>
          ) : item?.jobStatus == 'UpComing' ? (
            <div className="locationbtns">
              <Button
                className="notbtn"
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                Cancel
              </Button>
              <Button
                className="messagebtn"
                onClick={() => {
                  handleClickMessage();
                }}
              >
                Message
              </Button>
              {moment(new Date()) >= moment(item?.jobStartDate) ? (
                <Button
                  className="applybtn"
                  onClick={() => {
                    handleStartJob(item?._id);
                  }}
                >
                  Start
                </Button>
              ) : (
                <Button className="applybtnd applybtn">Start</Button>
              )}
            </div>
          ) : item?.jobStatus == 'OnGoing' ? (
            <div className="locationbtns">
              <Button
                className="messagebtn"
                onClick={() => {
                  handleClickMessage();
                }}
              >
                Message
              </Button>
              <Button
                className="applybtn"
                onClick={() => {
                  setCompleteModal(true);
                }}
              >
                Complete
              </Button>
            </div>
          ) : item?.jobStatus == 'PendingPayment' ? (
            <div className="locationbtns">
              <Button
                className="messagebtn"
                onClick={() => {
                  handleClickMessage();
                }}
              >
                Message
              </Button>
            </div>
          ) : (
            ''
          )
        ) : (
          item?.jobStatus != 'Completed' && (
            <div className="locationbtns">
              <Button
                className="messagebtn"
                onClick={() => {
                  handleClickMessage();
                }}
              >
                Message
              </Button>
            </div>
          )
        )}
        {popupModal && (
          <Modal
            centered
            className="addlocationmodal"
            closable={false}
            header={null}
            footer={null}
            style={{ top: '', overflow: 'hidden !important' }}
            open={popupModal}
          >
            <div className="sucesspopup  d-flex flex-column justify-content-center m-auto">
              <div
                className="crossicon"
                style={{ right: '0rem', cursor: 'pointer' }}
                onClick={() => {
                  screen == 2 ? window.location.reload() : navigate('/newjob');
                }}
              >
                <img src={crossicon} style={{ width: '30px', height: '30px' }} />
              </div>
              <div className="costimg m-3">
                <img src={sucessfulicon} />
              </div>
              <div className="sucessheading m-3">
                {screen == 2 ? 'Job Started Successfully!' : 'Applied Successfully!'}
              </div>
              <div className="content m-3">
                {screen == 2
                  ? 'Stay in touch with the client during job. Give them updates.'
                  : 'Welcome to MyInstallr, you will see job postings as they happen.Remember, the quicker you respond the more likely you are to be awarded jobs by the clients!'}
              </div>
              <Button
                className="termscontentbtn m-3"
                onClick={() => {
                  screen == 2 ? navigate('/contractorjoblist', { state: { activeTab: 2 } }) : navigate('/newjob');
                  setPopupModal(false);
                }}
              >
                Done
              </Button>
            </div>
          </Modal>
        )}

        {pdfView && (
          <Modal
            centered
            className="popuppdf"
            closable={false}
            header={null}
            footer={null}
            style={{ top: '', overflow: 'hidden !important' }}
            open={pdfView}
          >
            <img
              src={crossicon}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setPdfView(false);
              }}
            />
            {url?.includes('pdf') ? (
              <iframe src={url} style={{ width: '100%', height: '100%' }}></iframe>
            ) : (
              <img
                src={url}
                style={{
                  width: '98%',
                  height: '89%',
                  objectFit: 'contain',
                  top: '36px',
                }}
              />
            )}
          </Modal>
        )}
        {bankPopUp && (
          <Modal
            centered
            closable={false}
            header={null}
            footer={null}
            style={{ top: '', overflow: 'hidden !important' }}
            open={bankPopUp}
          >
            <div style={{ fontSize: '18px', fontWeight: '500', color: 'black' }}>
              Please enter your banking details before you start a job for successful transactions into your linked bank
              account.
            </div>
            <Button
              className="m-auto d-flex text-center ackbtn"
              style={{
                backgroundColor: '#0FF4BD',
                borderColor: '#0FF4BD',
                color: 'black',
                height: '42px',
                width: '156px',
                fontSize: '18px',
                fontWeight: '500',
              }}
              onClick={() => {
                handleIAck();
              }}
            >
              I Acknowledge
            </Button>
          </Modal>
        )}
        {messageModal && (
          <Modal
            centered
            //className='popuppdf'
            closable={false}
            header={null}
            footer={null}
            style={{ top: '', overflow: 'hidden !important' }}
            open={messageModal}
          >
            <div style={{ fontSize: '16px' }}>{cancelMessage}</div>
            <Button
              className="m-auto d-flex text-center ackbtn"
              style={{
                backgroundColor: '#0FF4BD',
                borderColor: '#0FF4BD',
                color: 'black',
                height: '42px',
                width: '156px',
                fontSize: '18px',
                fontWeight: '500',
              }}
              onClick={() => {
                navigate('/contractorjoblist', { state: { activeTab: 2 } });
              }}
            >
              I Acknowledge
            </Button>
          </Modal>
        )}
        {completeModal && (
          <CompleteModalPopup
            handleFileChange={handleFileChange}
            expenseInfo={expenseInfo}
            setCompleteModal={setCompleteModal}
            callAddExpenseApi={callAddExpenseApi}
            setExpenseModal={setExpenseModal}
            completeModal={completeModal}
            setExpenseInfo={setExpenseInfo}
            setExpenses={setExpenses}
            setCountNumber={setCountNumber}
            loader={loader}
            handleDeleteExpense={handleDeleteExpense}
          />
        )}
        {expenseModal && (
          <AddExpense
            expenseModal={expenseModal}
            handleFileChange={handleFileChange}
            setExpenseModal={setExpenseModal}
            expenses={expenses}
            setExpenses={setExpenses}
            handleAddExpense={handleAddExpense}
            feedBack={feedBack}
            setCompleteModal={setCompleteModal}
            loader={loader}
          />
        )}
        {openModal && (
          <ModalSingle
            content="Are you sure you want to cancel job request?"
            setOpenModal={setOpenModal}
            openModal={openModal}
            feedBack={feedBack}
            handleYes={handleYes}
            image={declinere}
            setFeedBack={setFeedBack}
            hasInput="pending"
          />
        )}
      </SpinWrappper>
    </DashBoardWrapper>
  );
};

export default SingleJobInfo;
