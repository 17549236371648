import React, { useEffect } from "react";
import {
  Route,
  Routes, useNavigate
} from "react-router-dom";
import routes from "./routes/routes";
import useNetworkStatus from "./useNetworkStatus";
import { message } from "antd";
import handleRouting from "./common/helper/handleRouting";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

const App = () => {
  const isOnline = useNetworkStatus();
  const token = localStorage.getItem('token');
  const profileInfo = useSelector(state => state.login.profileInfo);
  const navigate = useNavigate();
  useEffect(() => {
    if (token)
   if (
     !(
       location.pathname.includes('messages') ||
       location.pathname.includes('settings') ||
       location.pathname.includes('contactus') ||
       location.pathname.includes('myjobs') ||
       location.pathname.includes('createjob') ||
       location.pathname.includes('jobs') ||
       location.pathname.includes('dashboard') ||
       location.pathname.includes('newjob') ||
       location.pathname.includes('contractorjoblist') ||
       location.pathname.includes('earnings') ||
        location.pathname.includes('ratings') ||
       location.pathname.includes('jobs')
     )
   )
     handleRouting(navigate, profileInfo, message);
    }  , [token]);

  return (
    <>
      {isOnline ? null : (
        <div className='text-center' style={{ height: "40px", width: "100%", background: "black", color: "#fff", fontWeight: "600", position: "absolute", zIndex: "99" }}>
          <p className='pt-2'> You are offline! please check your connection. </p>
        </div>
      )}
      <div className="d-flex">
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element()} />
          ))}
        </Routes>
      </div>
    </>
  );
}

export default App;
