import React,{ useEffect, useState } from 'react';
import { Spin, Modal,Input } from 'antd';

import TimeInfo from './TimeInfo';
import crossicon from '../../../asset/images/crossicon.svg';
import dummyprofileicon from '../../../asset/images/dummyicon.svg';
import uploadfileimg from '../../../asset/images/fileuploadimg.svg';
import calendergray from '../../../asset/images/graycalender.svg';
import locationgray from '../../../asset/images/graylocation.svg';
import timecirclegray from '../../../asset/images/graytimecircle.svg'
import pdfimage from '../../../asset/images/pdfimage.svg';
import sendbtnimg from '../../../asset/images/sendbtnimg.svg';

const ChatInfo = ({ newChatUser, messages, senderId, setNewMessage, handleSendMsg, message, handleFileChange, loader }) => {
  const [image, setImage] = useState();
  const [imageView, setImageView] = useState(false);
  const [timeoutVal,setTimeoutVal]=useState(2000)
  const handleViewImage = (url) => {
    setImage(url)
    setImageView(true);
  }

  useEffect(() => {
    if (messages?.length) {
      setTimeout(() => {
        setTimeoutVal(100);
        const messagechat = document.getElementsByClassName("messagechat");
        messagechat[0]?.scroll({ top: 9999 })
      }, timeoutVal);
    }
  }, [messages])
  return (
    <Spin spinning={loader} style={{ maxHeight: "100%", height: "100%", background: "#fff", opacity: "1" }}>
      <div className="messageinfo">
        <div className="messageheading">
          <div className="messageflex">
          <div className="messageimg">
            {newChatUser?.userId?.profileImage?.url && <img src={newChatUser?.userId?.profileImage?.url ? newChatUser?.userId?.profileImage?.url : dummyprofileicon} />}
          </div>
          <div className="messagename">
            <div className="messagename1">{newChatUser?.userId?.firstName} {newChatUser?.userId?.lastName}</div>
            <div className="messagename2">{newChatUser?.category[0]?.name}</div>
            </div>
          </div>
          <div className="messagedateadd">
            <img src={calendergray} style={{margin:"0px 5px"}}/> {newChatUser?.job[0]?.jobStartDate} <img src={timecirclegray}  style={{margin:"0px 5px"}}/>{newChatUser.job[0].jobStartTime}
          </div>
          <div className="messagedateadd">
            <img   style={{margin:"0px 5px"}}src={locationgray}/>{newChatUser?.location[0]?.address}
          </div>
        </div>
        
        <div className="messagechat" >
          {messages?.map((m, index) => {
            return (
              <>
                {m?.userId?._id != senderId || m?.userId?._id == "new" ?
                  <div className="messageinfocontent" key={index} >
                    <div className="messagecontent">
                      <div className="userimg">
                        {m?.userId?.profileImage?.url && localStorage.getItem('role') == "0" && <img src={m?.userId?.profileImage?.url ? m?.userId?.profileImage?.url : dummyprofileicon} />}
                      </div>
                      <div className="userinfo">
                        <div className={m?.attachedFile?.url ? "usercontent3 usercontent" : "usercontent"}>
                          {m?.message.includes("png") || m?.message.includes("jpeg") || m?.message.includes("jpg") ? <img style={{ width: "200px", borderRadius: "10px" }} src={m?.attachedFile?.url} onClick={() => { handleViewImage(m?.attachedFile?.url) }} /> : m?.message?.includes("pdf") ? <span onClick={() => { window.open(m?.attachedFile?.url, '_blank', 'noopener,noreferrer'); }} style={{ color: "black", cursor: "pointer" }}> <img src={pdfimage} style={{ backgroundColor: "#fff" }} /><span className="ml-1">{m?.message}</span></span> : m?.message}
                        </div>
                        <div className="usercontent2">
                          <div className="username">{m?.userId?.firstName} {m?.userId?.lastName}</div>
                          <div className="usertime"><TimeInfo timeval={m?.createdAt} /></div>
                        </div>
                      </div>
                    </div>
                  </div> :
                  <div className="messageinfocontent" key={index} style={{ display: "flex", flexDirection: "row-reverse" }} >
                    <div className="messagecontent">
                      <div className="userinfo">
                        <div className={m?.attachedFile?.url ? "usercontent3 usercontent1" : "usercontent1"}>
                          {m?.message.includes("png") || m?.message.includes("jpeg") || m?.message.includes("jpg") ? <img style={{ width: "200px", borderRadius: "10px", backgroundColor: "#fff" }} src={m?.attachedFile?.url} onClick={() => { handleViewImage(m?.attachedFile?.url) }} /> : m?.message?.includes("pdf") ?
                            <span style={{ color: "black", cursor: "pointer" }} onClick={() => { window.open(m?.attachedFile?.url, '_blank', 'noopener,noreferrer'); }}> <img src={pdfimage} /><span className="ml-1">{m?.message}</span></span> : m?.message}
                        </div>
                        <div className="usertimename">
                          <div className="usertime"><TimeInfo timeval={m?.createdAt} /></div>
                          <div className="user">You</div>
                        </div>
                      </div>
                    </div>
                  </div>}
              </>
            )
          })
          }
        </div>
        {!newChatUser?.disableChat ? <div className="inputtag">
          <Input placeholder='Type Message' value={message}
            onChange={(e) => { setNewMessage(e.target.value) }} suffix={
              <>
                <input type="file" id="file" className="fileinput" onChange={(e) => { handleFileChange(e) }} />
                <label htmlFor="file" id="file">
                  <img src={uploadfileimg} style={{ width: "45px", height: "45px", cursor: "pointer" }} />
                </label>
                <img src={sendbtnimg} style={{ cursor: "pointer", width: "45px", height: "40px", marginTop: "-2px" }}
                  onClick={() => { if (message.trim().length > 0) handleSendMsg() }} /></>}
            onKeyDown={(e) => {
              if (e.key === "Enter" && message.trim()) {
                handleSendMsg(e);
              }
            }}
          />
        </div> : <div className="inputtag m-auto" style={{ top: "30px", position: "relative" }}>Write a message is no longer available for this chat</div>}
      </div>
      {imageView && <Modal
        centered
        className='popuppdf'
        closable={false}

        header={null}
        footer={null}
        style={{ top: '', overflow: "hidden !important" }}
        open={imageView}
      >
        <img src={crossicon} style={{ cursor: "pointer" }} onClick={() => { setImageView(false) }} />
        {
          <img src={image} style={{
            width: "98%", height: "89%", objectFit: "contain",
            top: "40px"
          }} />

        }
      </Modal>}
    </Spin>
  )
}
export default ChatInfo;